import React, { useState } from 'react';
import { WebsiteWrapper, Box } from '@parsleyhealth/cilantro-ui';
import BlogMainNav from './nav/blog-main-nav';
import SearchIcon from '../../assets/icon-search.svg';
import BlogMainMobileNav from './nav/blog-main-mobile-nav';
import { useCurrentPath } from '../../hooks/use-current-path';
import { Toggler } from '../../hooks/use-mobile-menu';
import BlogSearch from './nav/blog-search';

const BlogHeader = () => {
  const [isSearch, setIsSearch] = useState(false);
  const currentPath = useCurrentPath();
  const onToggleIsSearch: () => void = () => {
    if (currentPath.includes('blog/search')) {
      return null;
    }
    setIsSearch(prevValue => !prevValue);
  };

  return (
    <WebsiteWrapper>
      <BlogMainNav>
        <Box onClick={onToggleIsSearch} cursor="pointer">
          <SearchIcon />
        </Box>
      </BlogMainNav>
      <Toggler.Provider>
        <BlogMainMobileNav>
          <Box onClick={onToggleIsSearch} cursor="pointer">
            <SearchIcon />
          </Box>
        </BlogMainMobileNav>
      </Toggler.Provider>
      <BlogSearch isSearch={isSearch} onToggleIsSearch={onToggleIsSearch} />
    </WebsiteWrapper>
  );
};

export default BlogHeader;
