import React from 'react';
import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const PlusMinusIcon: React.FC<IconProps> = ({
  toggleIcon,
  ...props
}) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      {toggleIcon ? (
        <path
          d="M7.00391 18L29.0039 18"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      ) : (
        <svg>
          <path
            d="M7 18L29 18"
            stroke="currentColor"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M18 29L18 7"
            stroke="currentColor"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </IconSvg>
  );
};
