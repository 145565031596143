import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const MinusIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M7.00391 18L29.0039 18"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </IconSvg>
  );
};
