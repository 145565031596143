import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './collapsible-data-list.module.css';
import { Text } from '../..';
import { SlideDown } from '../../atoms/slide-down/slide-down';
export type DataListItem = {
  key: string;
  title: string;
  description: ReactNode;
};

export type CollapsibleDataListProps = {
  items?: DataListItem[];
  activeItem?: number;
  onItemClick?: (i: number) => void;
};

/**
 * Please add here documentation for your component and how to use it
 */
export const CollapsibleDataList: FC<CollapsibleDataListProps> = ({
  activeItem = 0,
  onItemClick,
  items = []
}) => {
  return (
    <dl className={clsx('my-lg')}>
      {items.map(({ title, description, key }, i) => {
        const isActive = activeItem === i;
        const textColor: string = isActive
          ? 'text-dark-green'
          : 'text-light-text';
        return (
          <div
            className={clsx(
              styles['container'],
              'mb-sm',
              isActive && styles.active
            )}
            key={`${key}-${i}`}
            onClick={() => onItemClick(i)}
          >
            <Text
              className={clsx(styles.dt, textColor)}
              variant="h6a"
              htmlTag="dt"
            >
              {title}
            </Text>
            <SlideDown show={isActive} duration={0.4}>
              <Text
                className={clsx(
                  isActive && styles.active,
                  styles.dd,
                  'mt-xxs',
                  textColor
                )}
                variant="p2a"
                htmlTag="dd"
              >
                {description}
              </Text>
            </SlideDown>
          </div>
        );
      })}
    </dl>
  );
};
