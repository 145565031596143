import React from 'react';

export const ConnectionPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.56741 32.0001C4.56741 23.1454 11.7455 15.9673 20.6001 15.9673C24.8573 15.9673 28.7269 17.6266 31.5975 20.3336C28.6342 23.3312 26.8047 27.452 26.8047 32.0001C26.8047 36.5481 28.6342 40.6689 31.5975 43.6665C28.7269 46.3736 24.8573 48.0328 20.6001 48.0328C11.7455 48.0328 4.56741 40.8547 4.56741 32.0001ZM32 44.0602C29.027 46.8715 25.0149 48.5953 20.6001 48.5953C11.4348 48.5953 4.00485 41.1654 4.00485 32.0001C4.00485 22.8347 11.4348 15.4048 20.6001 15.4048C25.0149 15.4048 29.027 17.1286 32 19.9399C34.9731 17.1286 38.9852 15.4048 43.3999 15.4048C52.5653 15.4048 59.9952 22.8347 59.9952 32.0001C59.9952 41.1654 52.5653 48.5953 43.3999 48.5953C38.9852 48.5953 34.9731 46.8715 32 44.0602ZM32.4025 20.3336C35.2732 17.6266 39.1428 15.9673 43.3999 15.9673C52.2546 15.9673 59.4327 23.1454 59.4327 32.0001C59.4327 40.8547 52.2546 48.0328 43.3999 48.0328C39.1428 48.0328 35.2732 46.3736 32.4025 43.6665C35.3658 40.6689 37.1954 36.5481 37.1954 32.0001C37.1954 27.452 35.3658 23.3312 32.4025 20.3336ZM32 20.7266C32.6923 21.4267 33.3206 22.1901 33.8754 23.0075L27.3983 33.0064C27.3777 32.6736 27.3672 32.338 27.3672 32.0001C27.3672 27.6049 29.1358 23.6228 32 20.7266ZM27.4803 33.9145L34.2114 23.5236C35.3912 25.4141 36.1881 27.5682 36.4936 29.8774L36.4773 29.8669L29.6989 40.3309C28.5336 38.4186 27.7574 36.2431 27.4803 33.9145ZM30.031 40.853C30.6084 41.7232 31.2685 42.5338 32 43.2735C34.8643 40.3773 36.6329 36.3952 36.6329 32.0001C36.6329 31.575 36.6163 31.1539 36.5838 30.7372L30.031 40.853Z"
      fill="currentColor"
    />
  );
};
