import React from 'react';
import clsx from 'clsx';

import styles from './highlighted-text.module.css';
import './highlighted-text.scss';

const styleVariants = {
  underline1: 'textHighlightedUnderline1',
  underline2: 'textHighlightedUnderline2',
  underline3: 'textHighlightedUnderline3',
  circle1: 'textHighlightedCircle1',
  circle2: 'textHighlightedCircle2'
};

export interface HighlightedTextProps
  extends React.HTMLProps<HTMLParagraphElement | HTMLHeadingElement> {
  children: React.ReactNode;
  variant: keyof typeof styleVariants;
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({
  children,
  variant,
  className
}) => {
  const variantName = styleVariants[variant];

  return (
    <span className={clsx(styles.underline, styles[variantName], className)}>
      {children}
    </span>
  );
};
