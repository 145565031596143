import React, { FC } from 'react';
import styles from './nav-circle.module.css';
import clsx from 'clsx';

interface NavCircleProps {
  size: 'sm' | 'md';
  active?: boolean;
}

export const NavCircle: FC<NavCircleProps> = ({ active = false, size }) => {
  return (
    <div
      className={clsx(styles.circle, styles[size], active && styles.visible)}
    />
  );
};
