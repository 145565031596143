import React, { useState } from 'react';
import { WebsiteWrapper, Box } from '@parsleyhealth/cilantro-ui';

import SearchIcon from '../../assets/icon-search.svg';
import { useCurrentPath } from '../../hooks/use-current-path';
import { Toggler } from '../../hooks/use-mobile-menu';
import BlogMainMobileNav from '../Blog/nav/blog-main-mobile-nav';
import BlogSearch from '../Blog/nav/blog-search';
import HiltonMainNav from './nav/hilton-main-nav';

const HiltonHeader = () => {
  const [isSearch, setIsSearch] = useState(false);
  const currentPath = useCurrentPath();
  const onToggleIsSearch: () => void = () => {
    if (currentPath.includes('hilton/education/search')) {
      return null;
    }
    setIsSearch(prevValue => !prevValue);
  };

  return (
    <WebsiteWrapper>
      <HiltonMainNav>
        <Box onClick={onToggleIsSearch} cursor="pointer">
          <SearchIcon />
        </Box>
      </HiltonMainNav>
      <Toggler.Provider>
        <BlogMainMobileNav>
          <Box onClick={onToggleIsSearch} cursor="pointer">
            <SearchIcon />
          </Box>
        </BlogMainMobileNav>
      </Toggler.Provider>
      <BlogSearch isSearch={isSearch} onToggleIsSearch={onToggleIsSearch} />
    </WebsiteWrapper>
  );
};

export default HiltonHeader;
