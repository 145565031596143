/* eslint-disable prettier/prettier */
import React, { FC, useEffect } from 'react';
import './statistic.css';
import { Text, TextProps } from '../../atoms/text/text';
import { ImageType } from '../../atoms/image/types';
import { TTextColor } from '../../types';
import clsx from 'clsx';
import { AnimatedCounter } from '../../atoms/animated-conunter/animated-conunter';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type StatisticType = {
  value: string;
  description?: string;
};

interface StatisticProps extends React.HTMLAttributes<HTMLDivElement> {
  data: StatisticType;
  bgClassName?: string;
  type?: string;
  color?: TTextColor;
  textVariant?: TextProps['variant'];
}

/**
 * Please add here documentation for your component and how to use it
 */
export const Statistic: FC<StatisticProps> = ({
  data,
  className = 'bg-primary',
  type,
  color = 'black',
  textVariant = 'statLarge',
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const { value, description } = data;
  const rgex = /\d+/g;
  const matchesValue = value.match(rgex);
  const textValue = value.replace(rgex, '');
  const finalValue = parseInt(matchesValue[0]);
  const initialValue = finalValue - finalValue * 0.21;
  const SVGId = `statistic-clip-${type}`;
  const imageType = type as ImageType;
  const isCircle = imageType === ImageType.CIRCLE;
  const circleClass = isCircle ? 'circle-stat' : '';
  const path =
    imageType === ImageType.ROUND
      ? 'M1,0.501 C1,0.775,0.842,1,0.502,0.998 C0.162,0.97,0.043,0.788,0.004,0.501 C-0.035,0.214,0.207,-0.034,0.502,0.004 C0.772,0.039,1,0.226,1,0.501'
      : 'M0.999,0.43 C0.986,0.782,0.912,1,0.485,0.988 C-0.019,0.903,-0.011,0.676,0.005,0.26 C0.015,0.013,0.22,0.042,0.523,0.007 C0.899,-0.038,1,0.141,0.999,0.43';
  const variants = {
    visible: {
      opacity: 1,
      scale: [0.85, 1, 1, 1],
    },
    hidden: { opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <div className={clsx('stastistic-container', type && `aspect-${type}`)}>
      {!isCircle && (
        <svg
          className={'svg'}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <clipPath id={SVGId} clipPathUnits="objectBoundingBox">
              <path d={path}></path>
            </clipPath>
          </defs>
        </svg>
      )}
      {className !== 'bg-transparent' ? (
        <motion.div
          initial="hidden"
          animate="visible"
          variants={variants}
          ref={ref}
        >
          <div className={clsx(`stastistic-container aspect-${type}`)}>
            {!isCircle && (
              <svg
                className="svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <defs>
                  <clipPath id={SVGId} clipPathUnits="objectBoundingBox">
                    <path d={path}></path>
                  </clipPath>
                </defs>
              </svg>
            )}
            <div
              className={clsx(
                `statistic-bg ${className} ${SVGId}-class ${circleClass}`
              )}
            ></div>

            <div className="aspect-ratio-wrapper">
              <div className="stastistic-content">
                <Text color={color} variant="statLarge">
                  <AnimatedCounter from={initialValue} to={finalValue} />
                  {textValue}
                </Text>

                <Text color={color} variant="h6a">
                  {description}
                </Text>
              </div>
            </div>
          </div>
        </motion.div>
      ) : (
        <div className={`stastistic-content`}>
          <Text color={color} variant={textVariant}>
            {value}
          </Text>
          {description && (
            <Text color={color} variant="h6a">
              {description}
            </Text>
          )}
        </div>
      )}
    </div>
  );
};
