import React from 'react';

export const PlanPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6043 18.8381L22.6703 15.543L22.1937 15.2441L20.1868 18.4449C19.2147 17.619 17.9556 17.1207 16.5801 17.1207C13.5021 17.1207 11.0069 19.6159 11.0069 22.694C11.0069 25.772 13.5021 28.2673 16.5801 28.2673C19.6582 28.2673 22.1534 25.772 22.1534 22.694C22.1534 21.1978 21.5638 19.8392 20.6043 18.8381ZM19.8845 18.9271L16.8886 23.7052L14.2708 21.4235L13.9012 21.8476L16.7672 24.3456L17.0154 24.5619L17.1903 24.283L20.295 19.3313C21.1003 20.2204 21.5909 21.3999 21.5909 22.694C21.5909 25.4613 19.3475 27.7047 16.5801 27.7047C13.8128 27.7047 11.5694 25.4613 11.5694 22.694C11.5694 19.9266 13.8128 17.6833 16.5801 17.6833C17.8461 17.6833 19.0024 18.1527 19.8845 18.9271ZM16.5801 38.3015C13.8128 38.3015 11.5694 40.5449 11.5694 43.3123C11.5694 46.0796 13.8128 48.323 16.5801 48.323C19.3475 48.323 21.5908 46.0796 21.5908 43.3123C21.5908 42.0182 21.1003 40.8387 20.295 39.9496L17.1903 44.9014L17.0154 45.1802L16.7672 44.9641L13.9012 42.4681L14.2707 42.0438L16.8885 44.3237L19.8845 39.5454C19.0024 38.771 17.8461 38.3015 16.5801 38.3015ZM20.1868 39.0632C19.2148 38.2373 17.9556 37.739 16.5801 37.739C13.5021 37.739 11.0068 40.2342 11.0068 43.3123C11.0068 46.3903 13.5021 48.8855 16.5801 48.8855C19.6582 48.8855 22.1534 46.3903 22.1534 43.3123C22.1534 41.8161 21.5638 40.4576 20.6043 39.4564L22.6703 36.1614L22.1937 35.8625L20.1868 39.0632ZM52.7125 22.975H25.3965V22.4125H52.7125V22.975ZM25.3965 43.5933H52.7125V43.0307H25.3965V43.5933Z"
      fill="currentColor"
    />
  );
};
