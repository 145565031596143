import React from 'react';

import clsx from 'clsx';
import { ArrowIconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

import './arrow-icon.css';

export const ArrowIcon: React.FC<ArrowIconProps> = ({
  direction = 'right',
  circled = false,
  outlined = false,
  className,
  ...props
}) => {
  const classes = clsx(className, {
    'arrow-icon-circled': circled,
    'arrow-icon-outlined': outlined
  });

  let d: string;

  switch (direction) {
    case 'down':
      d = 'M18 28L8 17.8681M18 28L28 17.8681M18 28L18 8';
      break;
    case 'up':
      d =
        'M17.9961 8L27.9961 18.1319M17.9961 8L7.99609 18.1319M17.9961 8L17.9961 28';
      break;
    case 'left':
      d = 'M8.00781 18L18.1397 8M8.00781 18L18.1397 28M8.00781 18L28.0078 18';
      break;
    default:
      d = 'M28.0039 18L17.872 28M28.0039 18L17.872 8M28.0039 18H8.00391';
  }

  return (
    <IconSvg viewBox="0 0 37 37" fill="none" className={classes} {...props}>
      <path d={d} stroke="currentColor" />
    </IconSvg>
  );
};
