import { useState } from 'react';

export const useExpandFaq = (items: number, initialExpanded = 0) => {
  const [expandedFaq, setExpandedFaq] = useState(
    initialExpanded < items && initialExpanded > 0 ? initialExpanded - 1 : 0
  );

  const UNSELECTED_INDEX = -1;

  if (items <= 0) {
    throw new Error('The number of items should be greater than 0');
  }

  const expandItem = (targetItem: number): void => {
    if (targetItem === expandedFaq) {
      setExpandedFaq(UNSELECTED_INDEX);
    } else if (targetItem >= 0 && targetItem < items) {
      setExpandedFaq(targetItem);
    }
  };

  const currentExpandedFaq = expandedFaq;

  return {
    currentExpandedFaq,
    expandItem
  };
};

export default useExpandFaq;
