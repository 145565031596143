import { TextAlignmentOption } from 'src/redesign/atoms/text-lockup/text-lockup';
import { SanityTextLockup } from '../../graphql-types';
import { useSanityTextVariant } from './use-sanity-text-variant';
import { useSanityButtonLink } from './use-sanity-button-link';

export const useSanityTextLockup = (textLockup: SanityTextLockup) => {
  const eyebrow = useSanityTextVariant(textLockup.eyebrow, 'p3b');
  const heading = useSanityTextVariant(textLockup.heading, 'h2a');
  const description = useSanityTextVariant(textLockup.description, 'p2a');
  const primaryCta = useSanityButtonLink(textLockup.primaryCta);
  const secondaryCta = useSanityButtonLink(textLockup.secondaryCta);
  const textLockupProps = {
    alignment: [
      textLockup.alignmentOnMobile,
      textLockup.alignmentOnDesktop
    ] as TextAlignmentOption[]
  };
  return {
    eyebrow,
    heading,
    description,
    primaryCta,
    secondaryCta,
    textLockupProps
  };
};
