import React, { FC } from 'react';
import { Row, Column } from '../../atoms/grid';
import { Text } from '../..';
import { TextLockup } from '../../atoms/text-lockup';
import {
  LocationCard,
  TLocationCard
} from '../../molecules/location-card/locations';
import useResponsiveProps from '../../../hooks/use-responsive-props';

import './locations.scss';

export type LocationProps = {
  headline: string;
  supportingCopy: string;
  left: TLocationCard;
  right: TLocationCard;
};

export const Locations: FC<LocationProps> = ({
  headline,
  supportingCopy,
  left,
  right
}) => {
  const { getPropV2 } = useResponsiveProps();
  return (
    <div className="locations-container">
      <TextLockup alignment="center" className="mb-sm">
        <Text variant="h2a" className="text-lockup-headline" color="dark-green">
          {headline}
        </Text>
        <div className="scp-area">
          <Text className="mb-lg scp-text" color="dark-green" variant="p2a">
            {supportingCopy}
          </Text>
        </div>
      </TextLockup>
      <Row className="text-center" cols={[21, 10]}>
        <Column offset={2}>
          <LocationCard location={left} />
        </Column>
        <Column offset={[2, 1]} className={getPropV2(['lg', 'none'], 'mt')}>
          <LocationCard location={right} />
        </Column>
      </Row>
    </div>
  );
};
