import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import useNavigation from './../../../hooks/use-navigation';
import '../../HeaderNew/main-nav.css';
import LogoNav from './blog-nav-logo';
import useTheme from '../../../hooks/use-navigation-theme';
import { NavItem } from '@parsleyhealth/cilantro-ui';
import NavItemWrapper from '../../HeaderNew/nav-item-wrapper';
import { NavItemProps } from '../../HeaderNew/nav-types';

const BlogMainNav = ({ children }) => {
  const { blogCategoryNav } = useNavigation();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('solidNavDarkText');
  }, [setTheme]);

  return (
    <div className="nav-container">
      <div className="main-navbar-wrapper">
        <LogoNav />
        <div className="blog-nav-wrapper">
          {blogCategoryNav?.map((item: NavItemProps) => (
            <NavItemWrapper key={item._key}>
              <Link to={item.path} activeClassName="active">
                <NavItem variant="darkTheme">{item.title}</NavItem>
              </Link>
            </NavItemWrapper>
          ))}
        </div>
        {children}
      </div>
    </div>
  );
};

export default BlogMainNav;
