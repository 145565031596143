import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

import { CentersPath } from './paths/centers-path';
import { CertifiedPath } from './paths/certified-path';
import { ConnectionPath } from './paths/connection-path';
import { CookPath } from './paths/cook-path';
import { DoctorPath } from './paths/doctor-path';
import { ExercisePath } from './paths/exercise-path';
import { ExpertPath } from './paths/expert-path';
import { FunctionalPath } from './paths/functional-path';
import { GoalPath } from './paths/goal-path';
import { InsurancePath } from './paths/insurance-path';
import { InvestigatePath } from './paths/investigate-path';
import { ListenPath } from './paths/listen-path';
import { LocationsPath } from './paths/locations-path';
import { MovementPath } from './paths/movement-path';
import { NutritionPath } from './paths/nutrition-path';
import { OnlinePath } from './paths/online-path';
import { PersonalPath } from './paths/personal-path';
import { PlanPath } from './paths/plan-path';
import { ReadPath } from './paths/read-path';
import { ResultsPath } from './paths/results-path';
import { SchedulePath } from './paths/schedule-path';
import { SecurePath } from './paths/secure-path';
import { SleepPath } from './paths/sleep-path';
import { StressPath } from './paths/stress-path';
import { SupplementsPath } from './paths/supplements-path';
import { TeamCollabPath } from './paths/team-collab-path';
import { TestingPath } from './paths/testing-path';
import { WatchPath } from './paths/watch-path';

export type IllustrativeIconNames =
  | 'centers'
  | 'certified'
  | 'connection'
  | 'cook'
  | 'doctor'
  | 'exercise'
  | 'expert'
  | 'functional'
  | 'goal'
  | 'insurance'
  | 'investigate'
  | 'listen'
  | 'locations'
  | 'movement'
  | 'nutrition'
  | 'online'
  | 'personal'
  | 'plan'
  | 'read'
  | 'results'
  | 'schedule'
  | 'secure'
  | 'sleep'
  | 'stress'
  | 'supplements'
  | 'teamCollab'
  | 'testing'
  | 'watch';

interface IllustrativeIconProps extends IconProps {
  name: IllustrativeIconNames;
}

export const IllustrativeIcon: React.FC<IllustrativeIconProps> = ({
  color = 'utilityBlack',
  iconWidth = 64,
  name,
  ...props
}) => {
  let Path: React.FC;

  switch (name) {
    case 'centers':
      Path = CentersPath;
      break;
    case 'certified':
      Path = CertifiedPath;
      break;
    case 'connection':
      Path = ConnectionPath;
      break;
    case 'cook':
      Path = CookPath;
      break;
    case 'doctor':
      Path = DoctorPath;
      break;
    case 'exercise':
      Path = ExercisePath;
      break;
    case 'expert':
      Path = ExpertPath;
      break;
    case 'functional':
      Path = FunctionalPath;
      break;
    case 'goal':
      Path = GoalPath;
      break;
    case 'insurance':
      Path = InsurancePath;
      break;
    case 'investigate':
      Path = InvestigatePath;
      break;
    case 'listen':
      Path = ListenPath;
      break;
    case 'locations':
      Path = LocationsPath;
      break;
    case 'movement':
      Path = MovementPath;
      break;
    case 'nutrition':
      Path = NutritionPath;
      break;
    case 'online':
      Path = OnlinePath;
      break;
    case 'personal':
      Path = PersonalPath;
      break;
    case 'plan':
      Path = PlanPath;
      break;
    case 'read':
      Path = ReadPath;
      break;
    case 'results':
      Path = ResultsPath;
      break;
    case 'schedule':
      Path = SchedulePath;
      break;
    case 'secure':
      Path = SecurePath;
      break;
    case 'sleep':
      Path = SleepPath;
      break;
    case 'stress':
      Path = StressPath;
      break;
    case 'supplements':
      Path = SupplementsPath;
      break;
    case 'teamCollab':
      Path = TeamCollabPath;
      break;
    case 'testing':
      Path = TestingPath;
      break;
    case 'watch':
    default:
      Path = WatchPath;
  }
  return (
    <IconSvg
      viewBox="0 0 64 64"
      fill="none"
      color={color}
      iconWidth={iconWidth}
      {...props}
    >
      <Path />
    </IconSvg>
  );
};
