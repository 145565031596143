import React, {
  FC,
  useState,
  useEffect,
  HTMLProps,
  useMemo,
  useRef
} from 'react';
import clsx from 'clsx';
import styles from './conditions-module.module.css';
import { useInView } from 'react-intersection-observer';

import useDOMRect from '../../../hooks/use-dom-rect';
import { useScrollPosition } from '../../../hooks/use-scroll-position';
import { Row, Column } from '../../atoms/grid';
import { Image } from '../../atoms/image/image';
import { ImageProps, TButtonSize } from '../../types';
import { ImageType } from '../../atoms/image/types';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button';
import { TextList } from '../../molecules/text-list/text-list';
import { ContinuedTextList } from '../../molecules/continued-text-list/continued-text-list';
import { useCarouselStep } from '../../../hooks/use-carousel-step';
import { CarouselStepIndicator, Link } from '../..';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { useWindowSize } from '@parsleyhealth/cilantro-ui';
import './conditions-module.scss';

export interface Condition {
  background: string;
  type: string;
  image: ImageProps;
  conditionsList: string[];
  cta: { label: string; destination: string; ctaTrackingLabel: string };
  name: string;
}

export type FooterLink = { label: string; destination: string };

export interface ConditionsModuleProps {
  heading: string;
  imageHeading: string;
  conditions: Condition[];
  footerLink?: FooterLink;
  keyline?: boolean;
  animate?: boolean;
  reanimate?: boolean;
}

interface ConditionSlideProps extends HTMLProps<HTMLDivElement> {
  condition: Condition;
  animation: boolean;
  imageHeading: string;
  cta: { label: string; destination: string; ctaTrackingLabel: string };
  buttonSize: TButtonSize;
  heading?: boolean;
  reanimate?: boolean;
}

export const ConditionSlide = ({
  condition,
  animation,
  imageHeading,
  cta,
  buttonSize,
  heading = false,
  reanimate = false
}: ConditionSlideProps) => {
  const [ref, inView] = useInView();
  const [viewCount, setViewCount] = useState(1);
  const [shouldAnimate, setShouldAnimate] = useState(true);

  useEffect(() => {
    if (inView) {
      if (viewCount === 2) setShouldAnimate(false);
      setViewCount(viewCount => viewCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const shouldReanimate = animation
    ? reanimate
      ? true
      : shouldAnimate
    : false;
  // reanimate
  // !reanimate && shouldAnimate

  return (
    <article className="flex flex-col justify-between min-w-full" ref={ref}>
      <Image
        blob
        sizes={[300]}
        type={ImageType.RECTANGLE_XL}
        className={clsx(styles.image, shouldReanimate && 'fade-in-image')}
        {...condition.image}
      />
      {heading && (
        <Text variant="h6a" color="white" className="text-center mb-xs-md">
          {condition.name}
        </Text>
      )}
      <div
        className={clsx(
          animation && styles.rightPanelContentAnimation,
          styles.rightPanelContent
        )}
      >
        {imageHeading && (
          <Text variant="p3b" color="white" className={styles.imageHeading}>
            {imageHeading}
          </Text>
        )}
        <div className={styles.conditionsTextListWrapper}>
          <ContinuedTextList
            color="white"
            items={condition.conditionsList}
            centered
            className={styles.conditionsList}
            variant="p3a"
          />
        </div>
        <Button
          variant="outlined"
          color="white"
          destination={cta.destination}
          className={styles.button}
          size={buttonSize}
          trackingLabel={cta.ctaTrackingLabel}
        >
          {cta.label}
        </Button>
      </div>
    </article>
  );
};

export const ConditionsModule: FC<ConditionsModuleProps> = ({
  heading,
  conditions = [],
  imageHeading,
  footerLink,
  keyline = false,
  reanimate = false,
  animate
}) => {
  const { currentStep, goToStep } = useCarouselStep(conditions.length);
  const [ref, inView] = useInView();
  const [animation, setAnimation] = useState(inView);
  const windowSize = useWindowSize();
  const displayCarousel = windowSize.width < 1280;
  const displayAnimation = !displayCarousel && animate;
  const carouselArrowSpacing = windowSize.width < 370 ? 'xtiny' : 'md';
  const activeCondition = conditions[currentStep];
  const containerRef = useRef(null);
  const conditionSlideRef = useRef(null);
  const position = useScrollPosition();
  const containerRect = useDOMRect(containerRef.current);
  const conditionSlideRect = useDOMRect(conditionSlideRef.current);

  const isHidden = useMemo(() => {
    return position.y + 180 <= containerRect.top;
  }, [position, containerRect]);

  const responsive = {
    all: {
      breakpoint: { max: 10000000, min: 0 },
      items: 1
    }
  };

  useEffect(() => {
    const animateTime = animation
      ? setTimeout(() => {
          animation && setAnimation(false);
        }, 1000)
      : undefined;
    return () => {
      animateTime && clearTimeout(animateTime);
    };
  }, [animation]);

  useEffect(() => {
    setAnimation(displayAnimation ? !isHidden : inView);
  }, [displayAnimation, isHidden, inView]);

  useEffect(() => {
    displayAnimation &&
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1000);
  }, [displayAnimation]);

  const handleChangeStep = (nextStep: number) => {
    if (currentStep !== nextStep) {
      goToStep(nextStep);
      setAnimation(true);
    }
  };

  if (displayCarousel) {
    return (
      <div className="overflow-hidden">
        <Text
          variant="h2a"
          color="dark-green"
          className={clsx('pt-xl px-sm pb-md text-center relative', {
            keyline
          })}
        >
          {heading}
        </Text>
        <div
          className={`bg-${activeCondition.background} py-lg h-min`}
          ref={ref}
        >
          <ParsleyCarousel
            responsive={responsive}
            infinite={false}
            arrowSize="40"
            arrowSpacing={carouselArrowSpacing}
            leftArrowClass={styles.carouselArrow}
            rightArrowClass={styles.carouselArrow}
            beforeChange={handleChangeStep}
          >
            {conditions.map((slide, i) => (
              <ConditionSlide
                key={`conditions-module-${i}-${slide.name}`}
                condition={slide}
                animation={animation}
                imageHeading={imageHeading}
                cta={slide.cta}
                buttonSize="small"
                reanimate={reanimate}
                heading
              />
            ))}
          </ParsleyCarousel>
          <CarouselStepIndicator
            currentStep={currentStep}
            totalSteps={conditions.length}
            primaryColor="white"
            accentColor="white"
            className={styles.steps}
            primaryStepClassName="opacity-30"
          />
          {footerLink && (
            <div className="w-full text-center">
              <Link destination={footerLink.destination} color="white">
                {footerLink.label}
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <Row className="overflow-hidden" cols={[24, 12]} gap={0} gridLength={24}>
      <Column>
        <div className={clsx(styles.leftPanel)}>
          <Text
            variant="h2a"
            color="dark-green"
            className={clsx('mb-lg relative', {
              keyline
            })}
          >
            {heading}
          </Text>
          <TextList
            textList={conditions.map(c => c.name)}
            active={currentStep}
            onItemClick={handleChangeStep}
            animateList={inView}
          />
        </div>
      </Column>
      <Column
        className={clsx(
          styles.rightPanelWrapper,
          `bg-${activeCondition.background}`
        )}
      >
        {displayAnimation ? (
          <div ref={containerRef} style={{ height: conditionSlideRect.height }}>
            <div ref={ref}>
              <div
                className={clsx(styles.rightPanel, {
                  [styles.conditionSlideHidden]: isHidden
                })}
                ref={conditionSlideRef}
              >
                <ConditionSlide
                  condition={activeCondition}
                  animation={animation}
                  imageHeading={imageHeading}
                  cta={activeCondition.cta}
                  buttonSize="large"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={clsx(styles.rightPanel)} ref={ref}>
            <ConditionSlide
              condition={activeCondition}
              animation={animation}
              imageHeading={imageHeading}
              cta={activeCondition.cta}
              buttonSize="large"
            />
          </div>
        )}
      </Column>
    </Row>
  );
};
