import React, { useEffect, useCallback } from 'react';
import LogoNav from './blog-nav-logo';
import MainMobileNav from './blog-main-mobile';
import { Hamburger } from '@parsleyhealth/cilantro-ui';
import { Toggler } from '../../../hooks/use-mobile-menu';
import '../../HeaderNew/default-nav-mobile.css';

const BlogMainMobileNav = ({ children }) => {
  const { toggle, expanded } = Toggler.useContainer();

  function avoidScrolling() {
    document.body.classList.add('noscroll');
    document.querySelector('html').classList.add('noscroll');
  }

  function enableScrolling() {
    document.body.classList.remove('noscroll');
    document.querySelector('html').classList.remove('noscroll');
  }

  const setStatus = useCallback(() => {
    toggle();
    if (!expanded) {
      avoidScrolling();
    } else {
      enableScrolling();
    }
  }, [toggle, expanded]);

  useEffect(() => {
    if (!expanded) {
      enableScrolling();
    }
    // only the first time should enable the scroll
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nav-container-mobile">
      <Hamburger
        handler={() => setStatus()}
        expanded={expanded}
        colorTheme={'#414143'}
      />
      <LogoNav />
      {children}
      <MainMobileNav />
    </div>
  );
};

export default BlogMainMobileNav;
