import React, { useState } from 'react';
import { NavigationThemeKey } from '@parsleyhealth/cilantro-ui';

interface ContextProps {
  theme: NavigationThemeKey;
  setTheme?: (arg: NavigationThemeKey) => void;
  children?: any;
}
const cookieEnabled =
  typeof window !== 'undefined' && window.navigator.cookieEnabled;
const initialValue = cookieEnabled
  ? null
  : ('solidNavDarkText' as NavigationThemeKey);
export const NavigationThemeContext = React.createContext<
  Partial<ContextProps>
>({});

export const NavigationThemeProvider = props => {
  const [theme, setTheme] = useState(initialValue);
  return (
    <NavigationThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </NavigationThemeContext.Provider>
  );
};

export const NavigationThemeConsumer = NavigationThemeContext.Consumer;
