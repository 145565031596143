import React from 'react';
import LogoFile from '../../assets/logo-insights.svg';

const LogoNav: React.FC = () => {
  return (
    <div className="insights-nav-logo-wrapper">
      <LogoFile role="img" aria-label="Parsley Health Insights Logo" />
    </div>
  );
};

export default LogoNav;
