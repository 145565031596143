import React, { FC } from 'react';
import { Row, Column } from '../../atoms/grid';
import { GridOrientation, TTextColor } from '../../types';
import styles from './half-and-half-stats.module.css';
import clsx from 'clsx';
import useResponsiveProps from '../../../hooks/use-responsive-props';

export type TStatsPosition = 'left' | 'right';
export interface HalfAndHalfStatsProps {
  stats: JSX.Element;
  imgBgColor?: TTextColor;
  bgColor?: TTextColor;
  fullImage?: boolean;
  mobileHidden?: boolean;
  reversed?: GridOrientation;
  contentPosition?: 'center' | 'top';
  children?: any;
}

export const HalfAndHalfStats: FC<HalfAndHalfStatsProps> = ({
  children,
  fullImage,
  imgBgColor = '',
  bgColor = '',
  mobileHidden = false,
  reversed = true,
  contentPosition = 'center',
  stats
}) => {
  const contentPositionClass =
    contentPosition === 'center' ? 'flex-center' : 'justify-center';
  const { getPropV2 } = useResponsiveProps();

  const statReversedOnDesktop =
    typeof reversed === 'boolean' ? reversed : reversed[1];

  return (
    <Row
      cols={[23, 11]}
      reversed={reversed}
      className={clsx(getPropV2(['xl', 'xxl'], 'py'))}
    >
      <Column
        offset={[1, statReversedOnDesktop ? 2 : 3]}
        className={clsx(
          mobileHidden && styles.mobileHidden,
          imgBgColor || bgColor ? `bg-${imgBgColor || bgColor}` : '',
          imgBgColor && 'pt-xl pb-xl',
          styles.stats
        )}
      >
        <div
          className={clsx(
            fullImage ? 'w-full h-full' : 'w-pct-17/20 self-align'
          )}
        >
          {stats}
        </div>
      </Column>
      <Column
        offset={[1, statReversedOnDesktop ? 1 : 0]}
        gap={[1, 1]}
        className={clsx(
          bgColor && `bg-${bgColor}`,
          imgBgColor && 'pt-sm pb-sm'
        )}
      >
        <div
          className={clsx(
            'h-full pt-sm pb-sm',
            styles.leftColumn,
            contentPositionClass
          )}
        >
          {children}
        </div>
      </Column>
    </Row>
  );
};
