import React from 'react';

export const SchedulePath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0283 7.94824V13.0028H41.25V7.94824H41.8125V13.0028H52.6476H52.9289V13.2841V50.5701V50.8513H52.6476H11.3496H11.0683V50.5701V13.2841V13.0028H11.3496H23.4658V7.94824H24.0283ZM11.6309 13.5653V19.9408H52.3663V13.5653H11.6309ZM11.6309 50.2888V20.5033H52.3663V50.2888H11.6309ZM31.971 40.5636L39.631 28.3456L39.1544 28.0468L31.6693 39.9858L26.0715 35.1081L25.7019 35.5322L31.5479 40.6262L31.7962 40.8425L31.971 40.5636Z"
      fill="currentColor"
    />
  );
};
