import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const VogueIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="-8 0 130 30" fill="none" {...props}>
      <path
        d="M113.497 18.18C113.446 19.2174 113.363 20.2642 113.189 21.291C112.87 23.1267 111.952 24.8279 110.634 26.1339C109.089 27.6684 106.916 28.6221 104.731 28.6221H100.519V14.6015C101.023 14.56 101.518 14.5286 102.023 14.5286C105.226 14.5286 106.431 17.4741 106.874 20.2215H107.132V8.72177H106.874C106.72 11.7504 105.803 14.1976 102.27 14.1976C101.682 14.1976 101.095 14.1561 100.519 14.0945V0.984849H103.33C105.936 0.984849 108.471 1.19246 110.335 3.31826C112.178 5.42329 112.694 8.19268 112.993 10.8891H113.189V0.694824H87.2408V0.984849H90.3921V22.7637C90.3921 26.1761 87.6007 28.4988 84.2838 28.4988C81.2043 28.4988 77.6818 26.4032 77.6818 22.9613V0.985478H80.3495V0.695453H70.2969V0.984849H73.0464V21.9534C73.0464 27.16 78.033 29.3689 82.5553 29.3689C86.5515 29.3689 90.8158 27.367 90.8158 22.8468V0.984849H95.8018V28.6221H93.4428V28.9128H113.725V18.18H113.497Z"
        fill="currentColor"
      />
      <path
        d="M33.5504 0.0820312C29.9654 0.0820312 26.7416 2.08389 24.5988 4.88348C22.4254 7.73528 21.375 11.3345 21.375 14.9117C21.375 18.6348 22.5185 22.3995 24.8669 25.3129C26.9278 27.8759 30.1103 29.752 33.4379 29.752C36.9916 29.752 40.1954 27.718 42.3169 24.9398C44.4803 22.088 45.542 18.4888 45.542 14.9224C45.542 11.1986 44.3778 7.42387 42.0395 4.50978C40.0086 1.98072 36.8467 0.0820312 33.5504 0.0820312ZM33.4579 29.3267C27.9157 29.3267 26.4635 19.0387 26.4635 14.9117C26.4635 10.784 27.9057 0.506688 33.4579 0.506688C38.9894 0.506688 40.4517 10.7941 40.4517 14.9117C40.4517 19.0293 38.9894 29.3261 33.4579 29.3267Z"
        fill="currentColor"
      />
      <path
        d="M24.2795 0.98422V0.694824H18.8717V0.98422H21.3538L15.0399 21.6011L7.56117 0.98422H10.0232V0.694824H0V0.98422H2.63766L12.9902 29.4821L21.8893 0.98422H24.2795Z"
        fill="currentColor"
      />
      <path
        d="M62.0956 17.4638H64.6702V25.1183C64.6702 25.626 64.6496 26.123 64.3815 26.569C63.3411 28.2808 61.2602 29.2767 59.3036 29.2767C56.8409 29.2767 55.5124 28.1053 54.5957 25.7197C53.2878 22.318 52.9991 18.5748 52.9991 14.9448C52.9991 10.8901 53.5346 0.259827 59.4267 0.259827C61.9919 0.259827 63.9591 2.51963 65.1951 4.53218C66.2349 6.25346 66.8323 8.15151 67.2547 10.1012H67.4816V0.196915H67.2566C66.5667 0.71531 65.7631 1.30605 64.8664 1.30605C64.1459 1.30605 63.4148 1.06825 62.7449 0.819115L62.1569 0.590744C61.3545 0.269264 60.4997 0 59.6342 0C57.4196 0 55.3393 0.984573 53.619 2.32334C49.7872 5.29908 47.9844 10.3912 47.9844 15.1517C47.9844 21.6638 51.8781 29.5139 59.1912 29.5139C60.7878 29.5139 62.1688 29.0484 63.611 28.4048L64.2496 28.0525C64.9589 27.6586 65.7525 27.368 66.5667 27.368C67.689 27.368 68.5651 27.9801 69.1219 28.9131H69.3163V17.4638H71.5209V17.1637H62.0956V17.4638Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};
