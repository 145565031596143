import React from 'react';
import clsx from 'clsx';
import styles from './option.module.css';
import { Text } from '../../index';
import { TTextVariants } from '../text/text';

export type TOption<Value> = {
  value: Value;
  label: string;
  isActive?: boolean;
};

export type OptionProps<Value> = {
  className?: string;
  option: TOption<Value>;
  onSelectOption: () => void;
  textClassName?: string;
  textVariant?: TTextVariants;
};

export const Option = <T extends unknown>({
  className,
  onSelectOption,
  option,
  textClassName,
  textVariant = 'p1c'
}: OptionProps<T>) => {
  const isDisabled = option.value === null;
  return (
    <div
      className={clsx('flex flex-row pointer align-center mb-xxs', className)}
      onClick={isDisabled ? null : onSelectOption}
    >
      <div>
        <div
          className={clsx(
            styles.roundedCheck,
            option.isActive && styles.checkSelected,
            isDisabled && styles.disabled
          )}
        />
      </div>
      <Text
        variant={textVariant}
        className={clsx(textClassName, isDisabled && styles.disabledLabel)}
      >
        {option.label}
        {isDisabled && (
          <span className={clsx(isDisabled && styles.unavailable)}>
            (Unavailable)
          </span>
        )}
      </Text>
    </div>
  );
};
