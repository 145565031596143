import React, { ReactNode } from 'react';
import { Text } from '../redesign/index';
import { TTextVariants, TextProps } from '../redesign/types';
import {
  commonTypesToSerialize,
  marks,
  marksNoStylesVariant,
  listItem,
  list
} from '../redesign/molecules/block-content/block-content-serializers';

interface BlockProps {
  children: ReactNode;
  node: {
    style: string;
    content: string;
    label: string;
  };
}

export const createSimpleSerializer = (
  variant: TTextVariants,
  textProps?: Partial<Omit<TextProps, 'ref'>>
) => {
  const marksStyling = variant === 'pNoStyles' ? marksNoStylesVariant : marks;
  return {
    listItem,
    list,
    marksStyling,
    types: {
      block({ children, node }: BlockProps) {
        switch (node.style) {
          // I'll leave this switch open
          // for extension if more cases
          // are needed
          default:
            return (
              <Text
                variant={variant}
                className={variant === 'pNoStyles' ? '' : 'mb-md'}
                {...textProps}
              >
                {children}
              </Text>
            );
        }
      },
      ...commonTypesToSerialize
    }
  };
};
