import React from 'react';
import clsx from 'clsx';
import './main-nav.css';

interface MainNavProps {
  children?: any;
  className?: string;
}

const MainNav = ({ children, className }: MainNavProps) => {
  return (
    <div className={clsx('main-nav-container', className)}>
      <div className="main-nav-wrapper">{children}</div>
    </div>
  );
};

export default MainNav;
