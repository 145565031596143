import React, { FC } from 'react';
import clsx from 'clsx';
import { Text, Image, Link } from '../..';
import { ImageType } from '../../atoms/image/types';
import { Button } from '../../atoms/button';

import { breakpoint } from '../../types/index';
import useWindowSize from '../../../hooks/use-window-size';
import styles from './standard-carousel-slide.module.css';
import { SanityStandardCarouselSlider } from 'graphql-types';

interface StandardCarouselSlideProps extends SanityStandardCarouselSlider {
  isCarouselMoving?: boolean;
}

export const StandardCarouselSlide: FC<StandardCarouselSlideProps> = ({
  image,
  title,
  description,
  cta,
  isCarouselMoving
}) => {
  const windowSize = useWindowSize();

  const isDevice = windowSize.width <= breakpoint;

  const onClickLink = (e: any) => {
    if (isCarouselMoving) {
      e.preventDefault();
    }
  };

  return (
    <Link
      className={clsx(styles.slideContainer, 'flex-center', 'flex-col')}
      destination={cta.destination}
      ignoreStyles
      onClick={onClickLink}
    >
      <Image
        blob
        className={styles.slideImage}
        type={ImageType.RECTANGLE_XL}
        {...image}
      />
      <Text className={clsx(styles.slideTitle, 'text-primary')} variant="h6a">
        {title}
      </Text>
      <Text
        className={clsx(styles.slideCopy, 'text-utility-black')}
        variant="p3a"
      >
        {description}
      </Text>
      {cta ? (
        <Button
          className={styles.linkButton}
          variant={isDevice ? 'outlined' : 'filled'}
          color={isDevice ? 'darkGreen' : 'white'}
          size="small"
          noninteractive
          trackingLabel={cta._key}
        >
          {cta.text}
        </Button>
      ) : null}
    </Link>
  );
};
