import React from 'react';

export const InvestigatePath: React.FC = () => {
  return (
    <>
      <path
        d="M26.1262 20.5632C27.3366 22.5999 29.0359 24.3026 31.0702 25.5172C29.038 26.7165 27.3394 28.4068 26.1301 30.4332C24.9191 28.3969 23.22 26.6943 21.1861 25.4792C23.2175 24.2787 24.9158 22.5886 26.1262 20.5632ZM26.1262 18.5012L25.5061 19.6312C24.2915 21.8416 22.4671 23.6563 20.2501 24.8592L19.1161 25.4732L20.2501 26.0952C22.4674 27.3147 24.2908 29.1415 25.5061 31.3612L26.1262 32.4952L26.7461 31.3652C27.9594 29.1544 29.7834 27.3395 32.0001 26.1372L33.1341 25.5232L32.0001 24.8992C29.7827 23.6814 27.9596 21.855 26.7461 19.6352L26.1262 18.5012Z"
        fill="currentColor"
      />
      <path
        d="M33.5 35.347C33.7561 35.347 34.0063 35.423 34.2192 35.5653C34.432 35.7075 34.5978 35.9098 34.6957 36.1463C34.7936 36.3829 34.8191 36.6432 34.769 36.8942C34.7189 37.1453 34.5955 37.3759 34.4143 37.5568C34.2332 37.7376 34.0024 37.8607 33.7513 37.9104C33.5001 37.9601 33.2399 37.9342 33.0035 37.836C32.767 37.7377 32.5651 37.5716 32.4231 37.3585C32.2812 37.1454 32.2056 36.8951 32.206 36.639C32.2066 36.2962 32.3431 35.9676 32.5857 35.7253C32.8284 35.4831 33.1572 35.347 33.5 35.347ZM33.5 34.347C33.0465 34.3482 32.6035 34.4838 32.227 34.7367C31.8505 34.9896 31.5574 35.3484 31.3848 35.7678C31.2121 36.1872 31.1676 36.6483 31.257 37.093C31.3464 37.5376 31.5655 37.9458 31.8868 38.266C32.208 38.5861 32.617 38.8038 33.0619 38.8916C33.5069 38.9794 33.9679 38.9334 34.3867 38.7593C34.8055 38.5852 35.1632 38.2908 35.4148 37.9134C35.6664 37.5361 35.8004 37.0926 35.8 36.639C35.7995 36.031 35.5576 35.448 35.1274 35.0182C34.6973 34.5885 34.1141 34.347 33.506 34.347H33.5Z"
        fill="currentColor"
      />
      <path
        d="M36.7716 24.2844C36.8535 24.2844 36.9335 24.3086 37.0016 24.3541C37.0697 24.3996 37.1228 24.4643 37.1541 24.5399C37.1854 24.6156 37.1936 24.6988 37.1777 24.7791C37.1617 24.8594 37.1223 24.9332 37.0644 24.9911C37.0065 25.049 36.9327 25.0884 36.8524 25.1044C36.7721 25.1204 36.6888 25.1122 36.6132 25.0808C36.5375 25.0495 36.4729 24.9965 36.4274 24.9284C36.3819 24.8603 36.3576 24.7802 36.3576 24.6984C36.3581 24.5887 36.4019 24.4837 36.4795 24.4062C36.557 24.3287 36.662 24.2849 36.7716 24.2844ZM36.7716 23.2844C36.492 23.2848 36.2189 23.368 35.9866 23.5236C35.7543 23.6792 35.5734 23.9002 35.4667 24.1586C35.36 24.417 35.3322 24.7013 35.387 24.9754C35.4418 25.2496 35.5766 25.5014 35.7745 25.6989C35.9723 25.8965 36.2243 26.0309 36.4985 26.0853C36.7727 26.1397 37.0569 26.1116 37.3152 26.0045C37.5735 25.8975 37.7942 25.7162 37.9495 25.4837C38.1047 25.2512 38.1876 24.9779 38.1876 24.6984C38.1871 24.3232 38.0377 23.9635 37.7722 23.6984C37.5067 23.4333 37.1468 23.2844 36.7716 23.2844Z"
        fill="currentColor"
      />
      <path
        d="M21.1862 34.0627C21.2681 34.0627 21.3481 34.087 21.4162 34.1325C21.4843 34.178 21.5373 34.2427 21.5687 34.3183C21.6 34.394 21.6082 34.4772 21.5922 34.5575C21.5762 34.6378 21.5368 34.7116 21.4789 34.7695C21.421 34.8274 21.3472 34.8668 21.2669 34.8828C21.1866 34.8988 21.1034 34.8906 21.0277 34.8592C20.9521 34.8279 20.8874 34.7748 20.8419 34.7068C20.7965 34.6387 20.7722 34.5586 20.7722 34.4767C20.7727 34.3671 20.8165 34.2621 20.894 34.1846C20.9715 34.1071 21.0765 34.0633 21.1862 34.0627ZM21.1862 33.0627C20.9066 33.0631 20.6334 33.1464 20.4011 33.302C20.1689 33.4576 19.9879 33.6786 19.8812 33.937C19.7745 34.1954 19.7468 34.4797 19.8016 34.7538C19.8564 35.028 19.9912 35.2798 20.189 35.4773C20.3869 35.6749 20.6388 35.8093 20.913 35.8637C21.1873 35.9181 21.4715 35.89 21.7297 35.7829C21.988 35.6758 22.2087 35.4946 22.364 35.2621C22.5193 35.0296 22.6022 34.7563 22.6022 34.4767C22.6022 34.2909 22.5655 34.1069 22.4943 33.9352C22.4232 33.7635 22.3188 33.6075 22.1873 33.4762C22.0558 33.3449 21.8997 33.2408 21.7279 33.1698C21.5561 33.0989 21.372 33.0625 21.1862 33.0627Z"
        fill="currentColor"
      />
      <path
        d="M28.7539 44.4631C37.3939 44.4631 44.3979 37.459 44.3979 28.8191C44.3979 20.1792 37.3939 13.1751 28.7539 13.1751C20.114 13.1751 13.1099 20.1792 13.1099 28.8191C13.1099 37.459 20.114 44.4631 28.7539 44.4631Z"
        stroke="currentColor"
        strokeWidth="0.562556"
        strokeMiterlimit="10"
      />
      <path
        d="M39.9304 39.7632L50.8904 50.8272"
        stroke="currentColor"
        strokeWidth="0.562556"
        strokeMiterlimit="10"
      />
    </>
  );
};
