import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const PinterestIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M18 6C11.3719 6 6 11.3719 6 18C6 23.0859 9.16406 27.4266 13.6266 29.175C13.5234 28.2234 13.425 26.7703 13.6688 25.7344C13.8891 24.7969 15.075 19.7719 15.075 19.7719C15.075 19.7719 14.7141 19.0547 14.7141 17.9906C14.7141 16.3219 15.6797 15.075 16.8844 15.075C17.9062 15.075 18.4031 15.8438 18.4031 16.7672C18.4031 17.7984 17.7469 19.3359 17.4094 20.7609C17.1281 21.9562 18.0094 22.9313 19.1859 22.9313C21.3187 22.9313 22.9594 20.6812 22.9594 17.4375C22.9594 14.5641 20.8969 12.5531 17.9484 12.5531C14.5359 12.5531 12.5297 15.1125 12.5297 17.7609C12.5297 18.7922 12.9281 19.8984 13.425 20.4984C13.5234 20.6156 13.5375 20.7234 13.5094 20.8406C13.4203 21.2203 13.2141 22.0359 13.1766 22.2C13.125 22.4203 13.0031 22.4672 12.7734 22.3594C11.2734 21.6609 10.3359 19.4719 10.3359 17.7094C10.3359 13.9219 13.0875 10.4484 18.2625 10.4484C22.425 10.4484 25.6594 13.4156 25.6594 17.3813C25.6594 21.5156 23.0531 24.8438 19.4344 24.8438C18.2203 24.8438 17.0766 24.2109 16.6828 23.4656C16.6828 23.4656 16.0828 25.7578 15.9375 26.3203C15.6656 27.3609 14.9344 28.6688 14.4469 29.4656C15.5719 29.8125 16.7625 30 18 30C24.6281 30 30 24.6281 30 18C30 11.3719 24.6281 6 18 6Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};
