import React, { FC } from 'react';
import './blog-post-inline-banner.scss';
import { Text, Link } from '../..';
import { Button } from '../../atoms/button';

export type BlogPostInlineBannerProps = {
  title: string;
  cta: {
    text: string;
    destination: string;
    ctaTrackingLabel: string;
  };
};

export const BlogPostInlineBanner: FC<BlogPostInlineBannerProps> = ({
  title,
  cta
}) => {
  return (
    <div className="blog-post-inline-banner text-center my-xxl">
      <Text variant="h7b" color="sage">
        {title}
      </Text>
      <Button
        variant="outlined"
        size="small"
        trackingLabel={cta.ctaTrackingLabel}
      >
        <Link destination={cta.destination} ignoreStyles>
          {cta.text}
        </Link>
      </Button>
    </div>
  );
};
