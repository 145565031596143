import React, { FC } from 'react';
import clsx from 'clsx';

import { Text } from '../..';
import { ArrowIcon } from '../icons';
import styles from './text-list.module.css';

export interface TextListProps
  extends React.HTMLProps<HTMLParagraphElement | HTMLHeadingElement> {
  active: number;
  textList: string[];
  onItemClick: (i: number) => void;
  animateList?: boolean;
}

export const TextList: FC<TextListProps> = ({
  active,
  textList,
  onItemClick,
  className,
  animateList = false
}) => {
  return (
    <ul className={clsx(styles.list, 'flex', 'flex-col', 'w-full')}>
      {textList.map((text, i) => {
        const isActive = active === i;
        return (
          <li
            key={`${text}-${i}`}
            className={clsx(
              styles.listItem,
              animateList && styles.animateItem,
              'flex',
              'w-full'
            )}
            style={{
              animationDelay: `${i * 170}ms`
            }}
          >
            {isActive && (
              <ArrowIcon
                iconWidth="28px"
                direction="right"
                color="darkGreen"
                className={styles.listIcon}
              />
            )}
            <Text
              variant={isActive ? 'h6b' : 'h6a'}
              color="primary"
              className={clsx(
                styles.listItemText,
                isActive && styles.animateText,
                className
              )}
              onClick={() => onItemClick(i)}
            >
              {text}
            </Text>
          </li>
        );
      })}
    </ul>
  );
};
