import React, { FC, useEffect, HTMLAttributes } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { TCurve, curves } from '../../types/animations';

export interface FadeInProps extends HTMLAttributes<HTMLDivElement> {
  duration?: number;
  curve?: TCurve;
  threshold?: number;
  element?: 'div' | 'section';
}

/**
 * @param curve to use a custom curve of animation
 * @param duration to make the animation go faster or slower
 * @param threshold Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
 */
export const FadeIn: FC<FadeInProps> = ({
  children,
  duration = 1,
  curve = 'b',
  threshold = 0.2,
  className = '',
  element = 'div'
}) => {
  const { ref, inView } = useInView({ threshold });
  const controls = useAnimation();
  const Motion = motion[element];
  useEffect(() => {
    if (inView) {
      controls.start({
        y: 0,
        opacity: 1,
        transition: { duration: duration, times: curves[curve] }
      });
    }
  }, [controls, duration, inView, curve]);

  return (
    <Motion
      ref={ref}
      animate={controls}
      initial={{ y: 10, opacity: 0 }}
      className={className}
    >
      {children}
    </Motion>
  );
};
