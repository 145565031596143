import { GridLength, ColumnUnit } from '../redesign/types';

interface Col {
  cols?: ColumnUnit;
  gap?: ColumnUnit;
  offset?: ColumnUnit;
}

interface Configuration {
  row: {
    gridLength: GridLength;
  };
  contentCol: Col;
  imageCol: Col;
}

export const useHeroGridConfig = (configuration: string): Configuration => {
  let config;

  switch (configuration) {
    case 'condition':
      config = {
        row: {
          gridLength: 25 as GridLength
        },
        contentCol: {
          cols: [23, 10],
          offset: [1, 2]
        },
        imageCol: {
          cols: [23, 10],
          offset: [1, 2]
        }
      };
      break;

    case 'insights':
      config = {
        row: {
          gridLength: 25 as GridLength
        },
        contentCol: {
          cols: [23, 10],
          offset: [1, 2]
        },
        imageCol: {
          cols: [25, 10],
          offset: [0, 2]
        }
      };
      break;

    case 'fullImage':
      config = {
        row: {
          gridLength: 24 as GridLength
        },
        contentCol: {
          cols: [22, 14],
          offset: [1, 5]
        },
        imageCol: {
          cols: [24, 22]
        }
      };
      break;

    default:
      config = {
        row: {
          gridLength: 24 as GridLength
        },
        contentCol: {
          cols: [22, 10],
          gap: [0, 1],
          offset: [1, 1]
        },
        imageCol: {
          cols: [24, 12]
        }
      };
  }

  return config;
};
