import React, { FC } from 'react';
import BaseBlockContent from '@sanity/block-content-to-react';

import { blockContentSerializers } from './block-content-serializers';

export type BlockContentProps = {
  blocks: Record<string, any>;
  serializer?: any;
};

export const BlockContent: FC<BlockContentProps> = ({
  blocks,
  serializer,
  ...rest
}) => {
  if (!blocks) {
    return null;
  }
  return (
    <BaseBlockContent
      blocks={blocks}
      serializers={serializer ?? blockContentSerializers(rest)}
    />
  );
};
