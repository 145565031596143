import React, { FC, useCallback, useState, useEffect } from 'react';
import { InputPill } from '../../index';
import { TTextColor } from '../../../redesign/types';
import './newsletter-sign-up-form.scss';
import { FooterHeading } from '../newsletter-sign-up-form/footer-heading';
import { Option, Dropdown, Button } from '../../';
import { useOnToggleScrolling } from '../../../hooks/use-on-toggle-scrolling';
import { useSelectOptions } from '../../../hooks/use-select-options';
import FooterContent from '../../content/footer/footer.json';
import { Text } from '../../';
import { useGclid } from '../../../hooks/use-gclid';
import { usePatternValidation } from '../../../hooks/use-pattern-validation';
import { track } from '@parsleyhealth/cilantro-track';
import useDeviceDetect from '../../../hooks/use-is-device-detect';

export type NewsletterSignUpFormProps = {
  handleSubmit?: (value: string, setMessage: (message: string) => void) => void;
  label?: string;
  labelColor?: TTextColor;
  withInterests?: boolean;
  pattern?: RegExp;
  ctaTrackingLabel: string;
  formTrackingLabel: string;
};

export const NewsletterSignUpForm: FC<NewsletterSignUpFormProps> = props => {
  const { isMobile } = useDeviceDetect();
  const {
    label = 'Sign up for our newsletter',
    labelColor = 'white',
    withInterests = false,
    pattern,
    handleSubmit,
    ctaTrackingLabel,
    formTrackingLabel
  } = props;

  const signUpEventProperties = {
    action: 'submitted',
    label: formTrackingLabel
  };

  const gclid = useGclid();
  const { validate } = usePatternValidation();
  const [isOpen, onToggleOpen] = useOnToggleScrolling(false);
  const [_options, _selectedOptions, onSelectOption] = useSelectOptions(
    FooterContent.interests,
    [{ value: '', label: '' }],
    true
  );

  const [value, setValue] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState(false);

  const validation = useCallback(() => {
    if (pattern) {
      return validate(pattern, value);
    }
    return true;
  }, [pattern, validate, value]);

  const submitToAnalytics = useCallback(() => {
    const submitToHabeneroClient = (email: string) => {
      const interestsData = withInterests
        ? { health_interest: [..._selectedOptions].pop().value }
        : {};
      window.heap.identify(email);
      window.heap.addUserProperties({
        gclid,
        footer_newsletter_form: 'true',
        ...interestsData
      });
      track('Newsletter Sign Up', {
        ...signUpEventProperties,
        ...interestsData,
        footer_newsletter_form: 'true',
        email: email
      });
    };
    submitToHabeneroClient(value);
    setSuccessMessage('Success');
  }, [_selectedOptions, gclid, signUpEventProperties, value, withInterests]);

  const submit: React.FormEventHandler<HTMLFormElement> = useCallback(() => {
    const isValid = validation();
    if (!isValid) {
      setError(true);
      return;
    }
    submitToAnalytics();
  }, [submitToAnalytics, validation]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccessMessage('');
      setValue('');
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [successMessage]);

  const handleSignUpClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit
      ? handleSubmit(value, (success: string) => setSuccessMessage(success))
      : submit(e);
  };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target
  }) => {
    const { value } = target;
    setValue(value);
  };

  return (
    <form
      className="newsletter-signup-placeholder"
      onSubmit={e => handleSignUpClick(e)}
    >
      {label && (
        <div className="label">
          <Text variant="p2b" color={labelColor}>
            {label}
          </Text>
        </div>
      )}
      {withInterests ? (
        <div className="footer-dropdown-heading">
          <Dropdown
            isOpen={isOpen}
            onToggleOpen={onToggleOpen}
            className="w-full"
            heading={
              <FooterHeading
                text={
                  _selectedOptions && _selectedOptions[0]?.label === ''
                    ? 'I’m Interested in...'
                    : _selectedOptions[0]?.label
                }
                isOpen={isOpen}
              />
            }
            optionsContainerClass="bg-white"
          >
            {isMobile && (
              <Text
                color="dark-green"
                variant="p1a"
                className="dropdown-content-headline"
              >
                I’m Interested in...
              </Text>
            )}
            {_options?.map((o, i: number) => (
              <Option
                option={o}
                key={`option-${o.label}-${i}`}
                onSelectOption={() => onSelectOption(o)}
                textVariant="u1a"
              />
            ))}
            <Button
              color="sage"
              variant="filled"
              size="small"
              onClick={() => onToggleOpen()}
              className="w-full dropdown-cta"
              trackingLabel={ctaTrackingLabel}
            >
              Apply
            </Button>
          </Dropdown>
        </div>
      ) : null}
      <InputPill
        inputName="email"
        CTA="Sign Up"
        placeholder="Enter your email"
        buttonClassName="newsletter-signup-button"
        inputClasName="newsletter-signup-input"
        buttonType="submit"
        onChangeCB={handleInputChange}
        value={value}
        successMessage={successMessage}
        error={error}
      />
    </form>
  );
};

/**
 * @TODO: We need to validate what newsLetterInterest to use, or how handle all the types
 */
