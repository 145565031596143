import track from '@parsleyhealth/cilantro-track';
import { useLocation } from '@reach/router';
import clsx from 'clsx';
import React, { FC } from 'react';
import textStyles from '../../atoms/text/text.module.css';
import { TButtonColor } from '../../types';
import { Link } from '../link';
import styles from './button.module.css';

const textVariants = {
  p3a: 'textP3a',
  u1a: 'textUtility1a'
};

export type ButtonVariant = 'filled' | 'outlined';

export type TButtonSize = 'large' | 'small';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: TButtonColor;
  size?: TButtonSize;
  variant?: ButtonVariant;
  type?: 'button' | 'submit' | 'reset';
  label?: string;
  destination?: string;
  noninteractive?: boolean;
  /**
   * Compoment name who is using the button, this will be used in the analitics tracking
   */
  trackAsCTA?: boolean;
  trackingCategory?: string;
  trackingLabel: string;
  offset?: number;
}

export const Button: FC<ButtonProps> = ({
  id,
  children,
  className,
  size = 'large',
  onClick,
  variant = 'filled',
  color,
  type = 'button',
  label,
  destination,
  noninteractive = false,
  trackAsCTA = false,
  trackingCategory,
  trackingLabel,
  offset,
  ...restButtonProps
}) => {
  const { pathname: currentPath } = useLocation();
  const defaultColor = variant === 'outlined' ? 'current' : 'darkGreen';
  const classNames = clsx(
    styles.btn,
    styles[variant],
    styles[size],
    styles[color ? color : defaultColor],
    textStyles[size === 'large' ? textVariants.p3a : textVariants.u1a],
    className
  );

  const trackEvent = () => {
    track('Clicked CTA', {
      buttonText: `${children}`,
      category: trackingCategory || 'Clicked CTA',
      destination: destination ?? 'no-redirect',
      label: trackingLabel,
      path: currentPath
    });
  };

  const handleClick: React.MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  > = e => {
    trackEvent();
    onClick && onClick(e as React.MouseEvent<HTMLButtonElement>);
  };

  const handleInternalLinkClick = () => {
    trackEvent();
    onClick && onClick(null);
  };

  if (noninteractive) {
    return <div className={classNames}>{children}</div>;
  }

  const regex = /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

  if (destination) {
    const isLinkedToId = destination.includes('#');
    const isInternalLink = !regex.test(destination);
    if (isInternalLink && isLinkedToId) {
      // if destination is not an external link and contains a # symbol, use a regular <a> tag instead of link
      return (
        <a
          id={id}
          aria-label={label}
          className={classNames}
          href={destination}
          onClick={handleInternalLinkClick}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        id={id}
        aria-label={label}
        className={classNames}
        destination={destination}
        ignoreStyles
        onClick={isInternalLink ? handleInternalLinkClick : handleClick}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      id={id}
      onClick={trackAsCTA ? handleClick : onClick}
      className={classNames}
      type={type}
      aria-label={label}
      {...restButtonProps}
    >
      {children}
    </button>
  );
};
