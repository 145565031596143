import React, { FC } from 'react';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';
import { Text } from '../..';
import { Link } from '../../atoms/link';
import { ImageProps } from '../../types';
import './locations.scss';

export interface TLocationCard {
  name: string;
  address: string;
  openingHours: string;
  emailAddress: string;
  image: ImageProps;
  destination?: string;
}

export type LocationProps = {
  location: TLocationCard;
};

const LocationImageCard = ({ image }: Pick<TLocationCard, 'image'>) => {
  return (
    <div className="location-img">
      <Image {...image} type={ImageType.SQUARE} roundBorder />
    </div>
  );
};

export const LocationCard: FC<LocationProps> = ({ location }) => {
  const { image, name, address, emailAddress, destination } = location;
  return (
    <>
      {destination ? (
        <Link destination={destination} ignoreStyles>
          <LocationImageCard image={image} />
        </Link>
      ) : (
        <LocationImageCard image={image} />
      )}
      <Text className="mb-xxs" variant="h6a">
        {name}
      </Text>
      <Text className="mb-xxs" variant="p3a">
        {address}
      </Text>
      <Link
        variant="p3a"
        color="sage"
        destination={'mailto:' + emailAddress}
        underline
      >
        {emailAddress}
      </Link>
    </>
  );
};
