import React from 'react';

export const breakpoint = 758;

export const allIconColorOptions = {
  darkGreen: 'color-dark-green',
  clover: 'color-clover',
  white: 'color-white',
  utilityBlack: 'color-utility-black',
  gray: 'color-light-text',
  sage: 'color-sage',
  mint: 'color-mint',
  current: 'color-current'
};

export interface IconProps extends React.SVGProps<SVGSVGElement> {
  iconWidth?: number | string;
  color?: keyof typeof allIconColorOptions;
  circled?: boolean;
  outlined?: boolean;
  toggleIcon?: boolean;
}

export interface ArrowIconProps extends IconProps {
  direction?: 'up' | 'down' | 'left' | 'right';
}

export interface ContentLinkType {
  copy: string;
  destination: string;
}

export type TBitIcons = 'nutrition' | 'movement' | 'supplements';
