import React from 'react';

export const PersonalPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2786 31.8012L13.0797 32.0001L13.2786 32.199L31.7978 50.7181L31.9967 50.917L32.1956 50.7181L50.7147 32.199L50.9136 32.0001L50.7147 31.8012L32.1956 13.2821L31.9967 13.0832L31.7978 13.2821L13.2786 31.8012ZM31.9967 50.1214L13.8753 32.0001L31.9967 13.8788L50.118 32.0001L31.9967 50.1214ZM23.6234 31.8022L23.4245 32.0011L23.6234 32.2L31.8046 40.3812L32.0035 40.5801L32.2024 40.3812L40.3836 32.2L40.5825 32.0011L40.3836 31.8022L32.2024 23.621L32.0035 23.4221L31.8046 23.621L23.6234 31.8022ZM32.0035 39.7845L24.22 32.0011L32.0035 24.2176L39.7869 32.0011L32.0035 39.7845ZM50.0151 14.383L43.5212 20.877L43.1234 20.4792L49.6174 13.9852L50.0151 14.383ZM43.1234 43.5234L49.6174 50.0154L50.0151 49.6175L43.5211 43.1255L43.1234 43.5234ZM13.9857 49.6172L20.4797 43.1232L20.8775 43.521L14.3835 50.015L13.9857 49.6172ZM20.8775 20.4792L14.3835 13.9852L13.9857 14.383L20.4797 20.877L20.8775 20.4792Z"
      fill="currentColor"
    />
  );
};
