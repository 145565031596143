import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './body-container.module.css';

interface BodyContainerProps {
  children?: any;
  bordered?: boolean;
  isInsights?: boolean;
  isB2B?: boolean;
}

export const BodyContainer: FC<BodyContainerProps> = ({
  children,
  bordered = true,
  isInsights = false,
  isB2B = false
}) => {
  return (
    <div
      id="__body-container"
      className={clsx(
        styles.container,
        isInsights ? styles.insightsMarginBottom : styles.defaultMarginBottom,
        isB2B && styles.b2bMarginBottom,
        bordered && styles.bordered
      )}
    >
      {children}
    </div>
  );
};
