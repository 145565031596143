//@ts-nocheck

import React, { FC, useRef, ReactNode, useEffect, ReactElement } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '../../molecules/icons';
import useOnClickOutside from '../../../hooks/use-on-click-outside';
import useDeviceDetect from '../../../hooks/use-is-device-detect';
import './dropdown.scss';
import usePortal from 'react-useportal';
import { ConditionalWrapper } from '../conditional-wrapper';

export interface DropdownProps {
  heading?: ReactNode;
  className?: string;
  isOpen?: boolean;
  onToggleOpen?: any;
  optionsContainerClass?: string;
  activeMouseHoverIteraction?: boolean;
  closeIconContainerClass?: string;
  children?: any;
}

export const Dropdown: FC<DropdownProps> = ({
  children,
  className,
  heading,
  isOpen,
  onToggleOpen,
  optionsContainerClass = 'bg-ivory',
  activeMouseHoverIteraction = false,
  closeIconContainerClass = ''
}) => {
  const { ref: portalRef, openPortal, closePortal, Portal } = usePortal();
  const dropdownRef = useRef(null);
  const { isMobile } = useDeviceDetect();
  const getContentDisplay = (isNeeded: boolean) => {
    if (!isNeeded) return '';
    return isOpen ? 'dropdown-content-open' : 'dropdown-content-closed';
  };

  useEffect(() => {
    if (!isMobile) return;
    if (isOpen) openPortal();
    else closePortal();
  }, [isOpen, closePortal, openPortal, isMobile]);

  useOnClickOutside(dropdownRef, () => {
    if (!isOpen) return;
    onToggleOpen();
  });

  const iteraction = activeMouseHoverIteraction
    ? {
        onMouseEnter: () => !isOpen && onToggleOpen(),
        onMonMouseLeaveou: () => isOpen && onToggleOpen()
      }
    : {};

  return (
    <div
      ref={isMobile ? portalRef : dropdownRef}
      className={clsx('dropdown-container', className)}
    >
      <div onClick={onToggleOpen} {...iteraction}>
        {heading}
      </div>
      <div
        className={clsx(
          'dropdown-wrapper-content',
          getContentDisplay(!isMobile)
        )}
      >
        <AnimatePresence>
          {isOpen && (
            <ConditionalWrapper
              condition={isMobile}
              wrapper={(children: ReactElement) => <Portal>{children}</Portal>}
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                layout
                className={clsx(
                  'dropdown-content',
                  getContentDisplay(isMobile),
                  isMobile && 'mobile-dropdown-content',
                  optionsContainerClass
                )}
              >
                <motion.div
                  layout="position"
                  className="flex flex-col max-h-full overflow-hidden"
                >
                  <div
                    className={clsx(
                      'rounded-full p-xtiny bg-dark-green mb-sm self-end',
                      'dropdown-container-close',
                      closeIconContainerClass
                    )}
                    onClick={onToggleOpen}
                  >
                    <CloseIcon color="white" iconWidth="32" />
                  </div>
                  {children}
                </motion.div>
              </motion.div>
            </ConditionalWrapper>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
