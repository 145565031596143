//@ts-nocheck
import React, { FC } from 'react';
import './mask.css';
import { useMask } from '../../../hooks/use-mask';
import { AnimatePresence, motion } from 'framer-motion';

/**
 * Mask Wrapper
 */
export const Mask: FC = () => {
  const { showMask, setMask } = useMask();
  return (
    <AnimatePresence>
      {showMask && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.15
            }
          }}
          exit={{ opacity: 0 }}
        >
          <div className="mask-wrapper" onClick={() => setMask(false)}></div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
