import React, { FC } from 'react';
import clsx from 'clsx';

import { FadeIn } from '../fade-in/fade-in';
import { Spacing } from '../../types';
import useResponsiveProps from '../../../hooks/use-responsive-props';

import './module.scss';

const themes = {
  darkGreen: 'bg-dark-green text-white',
  transparent: 'text-dark-green'
};
export type ModuleTheme = keyof typeof themes;

export interface ModuleProps extends React.HTMLProps<HTMLElement> {
  marginTop?: Spacing;
  marginBottom?: Spacing;
  centered?: boolean;
  theme?: ModuleTheme;
  animate?: boolean;
}

export const Module: FC<ModuleProps> = ({
  marginTop,
  marginBottom,
  children,
  centered,
  theme = 'transparent',
  className = '',
  animate = false,
  ...props
}) => {
  const { getPropV2 } = useResponsiveProps();
  const classNames = clsx('module w-full', className, themes[theme], {
    centered,
    [`${getPropV2(marginTop, 'mt')}`]: marginTop,
    [`${getPropV2(marginBottom, 'mb')}`]: marginBottom
  });

  if (animate) {
    return (
      <FadeIn className={classNames} element="section" {...props}>
        {children}
      </FadeIn>
    );
  }
  return (
    <section className={classNames} {...props}>
      {children}
    </section>
  );
};
