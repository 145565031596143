import React, { FC } from 'react';
import './image-placeholder.css';

export const ImagePlaceholder: FC<{ svgRefId: string }> = ({ svgRefId }) => {
  return (
    <div
      className="image-placeholder-outer"
      style={{
        clipPath: `url(#${svgRefId})`
      }}
    >
      <div className="image-placeholder-container">
        <div className="image-placeholder" />
      </div>
    </div>
  );
};
