import React from 'react';

export const ExpertPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9922 32.0001C12.9922 21.5029 21.5018 12.9933 31.9989 12.9933C42.4961 12.9933 51.0057 21.5029 51.0057 32.0001C51.0057 42.4972 42.4961 51.0068 31.9989 51.0068C21.5018 51.0068 12.9922 42.4972 12.9922 32.0001ZM31.9989 12.4308C21.1911 12.4308 12.4297 21.1923 12.4297 32.0001C12.4297 42.8079 21.1911 51.5693 31.9989 51.5693C42.8068 51.5693 51.5682 42.8079 51.5682 32.0001C51.5682 21.1923 42.8068 12.4308 31.9989 12.4308ZM31.4916 37.1753L37.7916 27.1233L37.315 26.8245L31.1899 36.5975L26.6301 32.6238L26.2605 33.048L31.0685 37.238L31.3168 37.4543L31.4916 37.1753Z"
      fill="currentColor"
    />
  );
};
