import React from 'react';

export const SupplementsPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9765 9.95588C26.0407 9.05664 28.3775 9.01412 30.4731 9.83767L30.5791 9.56802L30.4731 9.83768C32.5276 10.6449 34.1869 12.2206 35.0991 14.2307C35.9814 16.175 36.0976 18.3777 35.4297 20.4001C34.043 20.313 32.6154 20.5448 31.254 21.1379C28.5581 22.3123 26.7226 24.6415 26.0742 27.2935L16.0385 23.3496L19.4657 14.6328L19.4657 14.6328C20.2897 12.5374 21.9122 10.8551 23.9765 9.95588ZM35.6114 13.9982C36.5345 16.0323 36.6711 18.3318 36.0032 20.4546C39.0635 20.844 41.8471 22.7886 43.1687 25.8224L50.7236 43.1643C52.7205 47.7481 50.6238 53.0828 46.0404 55.0795C41.457 57.0762 36.1226 54.9789 34.1256 50.395L28.9384 38.488L28.8738 38.6527L28.8737 38.6529C27.9947 40.8867 26.2646 42.68 24.0636 43.6384C21.8627 44.5968 19.3712 44.6419 17.1371 43.7637C16.0305 43.3291 15.0203 42.6807 14.1643 41.8556C13.3083 41.0305 12.6233 40.0449 12.1483 38.955C11.6733 37.865 11.4177 36.6922 11.396 35.5035C11.3744 34.3148 11.5872 33.1334 12.0222 32.027L12.284 32.1299L12.0222 32.027L18.9422 14.427L18.9422 14.4269C19.8208 12.1927 21.5508 10.3989 23.7518 9.44013C25.9527 8.48132 28.4444 8.43598 30.6788 9.31409C32.8695 10.1747 34.6388 11.8549 35.6114 13.9982ZM26.5708 33.0532L28.6205 37.7582L28.3502 38.4469L28.3502 38.447C27.5258 40.542 25.9031 42.2238 23.839 43.1227C21.7749 44.0215 19.4382 44.0638 17.3429 43.2401L17.3428 43.2401C16.3049 42.8325 15.3575 42.2244 14.5547 41.4506C13.7519 40.6768 13.1094 39.7524 12.664 38.7302C12.2185 37.708 11.9788 36.6081 11.9585 35.4933C11.9382 34.3784 12.1378 33.2705 12.5457 32.2328L15.8326 23.8731L25.9563 27.8515C25.6526 29.5496 25.8301 31.353 26.5708 33.0532ZM31.4787 21.6537C27.1801 23.5263 25.2137 28.5294 27.0865 32.8285L30.8295 41.4203L46.3952 34.6373L42.653 26.0471C40.7801 21.748 35.7772 19.781 31.4787 21.6537ZM46.6199 35.1531L31.0542 41.9361L34.6414 50.1703C36.5142 54.4694 41.5172 56.4364 45.8157 54.5638C50.1142 52.6911 52.0807 47.688 50.2078 43.3889L46.6199 35.1531Z"
      fill="currentColor"
    />
  );
};
