import React, { FC, useEffect } from 'react';
import { Text } from '../../atoms/text/text';
import { Column, Row } from '../../atoms/grid';
import { PlusIcon, MinusIcon } from '../../molecules/icons';
import { useExpandFaq } from '../../../hooks/use-multiple-expand-faq';
import styles from './faq.module.css';
import { SlideDown } from '../../atoms/slide-down/slide-down';
import useResponsiveProps from '../../../hooks/use-responsive-props';

const FAQ_HASH_PREFIX = 'faq-question';

type FaqItem = {
  id: string;
  question: string;
  answer: React.ReactNode;
};

export type FaqProps = {
  faqs: FaqItem[];
  initialExpanded?: number;
  heading?: string;
  expandWithQueryHash?: boolean;
};

const createFaqHash = (id: string | number) => {
  return `${FAQ_HASH_PREFIX}-${id}`;
};

export const Faq: FC<FaqProps> = props => {
  const {
    faqs,
    initialExpanded = 0,
    heading,
    expandWithQueryHash = true
  } = props;
  const { currentExpandedFaq, expandItem } = useExpandFaq(
    faqs.length,
    initialExpanded
  );
  const { getPropV2 } = useResponsiveProps();

  const onClickQuestion = (index: number) => () => {
    expandItem(index);
  };

  useEffect(() => {
    const validateHash = () => {
      const hash = window.location.hash;
      if (hash?.includes(FAQ_HASH_PREFIX)) {
        const id = hash.replace('#', '');
        const index = faqs.findIndex(faq => id === createFaqHash(faq.id));
        if (index >= 0 && !currentExpandedFaq.includes(index)) {
          expandItem(index);
        }
      }
    };
    if (expandWithQueryHash) {
      window.addEventListener('hashchange', validateHash);
    }
    return () => {
      if (expandWithQueryHash) {
        window.removeEventListener('hashchange', validateHash);
      }
    };
  }, [expandWithQueryHash, currentExpandedFaq, expandItem, faqs]);

  return (
    <div className={getPropV2(['xl', 'big'], 'py')}>
      {heading && (
        <Text variant="p2b" color="dark-green" className={styles.heading}>
          {heading}
        </Text>
      )}
      {faqs.map((faq, index) => {
        const { answer, question, id } = faq;
        const isCurrent = currentExpandedFaq.includes(index);
        const isString = typeof answer === 'string';

        return (
          <div
            key={`${id}-${index}`}
            id={createFaqHash(id)}
            className="enable-scroll-margin-top"
          >
            <Row
              offset={1}
              cols={23}
              bordered="mobileHidesY"
              lastRow={index === faqs.length - 1 ? true : false}
            >
              <Column className={styles.container}>
                <div
                  className={styles.textContainer}
                  onClick={onClickQuestion(index)}
                >
                  <Text variant="h5a">{question}</Text>
                  {isCurrent ? (
                    <MinusIcon className={styles.icon} iconWidth={36} />
                  ) : (
                    <PlusIcon className={styles.icon} iconWidth={36} />
                  )}
                </div>
                <SlideDown show={isCurrent} duration={0.35}>
                  <Text
                    htmlTag={isString ? 'p' : 'div'}
                    className="mt-xs mb-lg pr-lg pr-none-sm"
                    variant="p2a"
                  >
                    {answer}
                  </Text>
                </SlideDown>
              </Column>
            </Row>
          </div>
        );
      })}
    </div>
  );
};

export default Faq;
