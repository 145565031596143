import React from 'react';

export const OnlinePath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.084 12.1707C19.4879 13.5694 12.0977 21.7683 11.9609 31.7198H21.3515C21.3895 26.1841 22.5492 21.1661 24.418 17.5032C25.6735 15.0422 27.2692 13.1594 29.084 12.1707ZM29.0838 51.8309C19.488 50.4322 12.098 42.2336 11.9609 32.2824H21.3515C21.3896 37.8179 22.5493 42.8357 24.418 46.4985C25.6735 48.9593 27.2691 50.8422 29.0838 51.8309ZM24.9191 46.2428C23.0982 42.6737 21.9522 37.7469 21.9141 32.2824H31.7187V52.0338C29.1234 51.89 26.7144 49.7617 24.9191 46.2428ZM32.2813 52.0338C34.8764 51.8899 37.2854 49.7615 39.0806 46.2428C40.9015 42.6737 42.0475 37.7469 42.0856 32.2824H32.2813V52.0338ZM32.2813 31.7198H42.0856C42.0475 26.255 40.9016 21.3281 39.0806 17.7588C37.2854 14.2401 34.8764 12.1118 32.2813 11.9679V31.7198ZM31.7187 11.9679C29.1234 12.1116 26.7144 14.24 24.9191 17.7588C23.0981 21.3281 21.9521 26.255 21.9141 31.7198H31.7187V11.9679ZM39.5817 46.4985C41.4504 42.8357 42.6101 37.8179 42.6481 32.2824H52.0385C51.9015 42.2335 44.5116 50.432 34.9159 51.8309C36.7306 50.8421 38.3262 48.9593 39.5817 46.4985ZM42.6481 31.7198H52.0385C51.9018 21.7684 44.5117 13.5695 34.9158 12.1707C36.7306 13.1594 38.3262 15.0423 39.5817 17.5032C41.4504 21.1661 42.6101 26.1841 42.6481 31.7198ZM32.0218 52.6041C43.3905 52.5922 52.603 43.3723 52.603 32.0008C52.603 20.6219 43.3786 11.3975 31.9997 11.3975C20.6209 11.3975 11.3965 20.6219 11.3965 32.0008C11.3965 43.3724 20.6091 52.5923 31.978 52.6041L31.9998 52.6041L32.0218 52.6041Z"
      fill="currentColor"
    />
  );
};
