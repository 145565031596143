import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const CheckIcon: React.FC<IconProps> = ({
  strokeWidth = 3,
  ...props
}) => {
  return (
    <IconSvg viewBox="0 0 22 20" fill="none" {...props}>
      <path
        d="M1 10.3292L9.38352 17L20.3684 1"
        stroke="#6FB39F"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
    </IconSvg>
  );
};
