import React from 'react';
import { Pagination } from '@parsleyhealth/cilantro-ui';
import { connectPagination } from 'react-instantsearch-dom';

type TProps = {
  currentRefinement: number;
  nbPages: number;
  refine: (page: number | string) => void;
};

const PaginationComplete = ({ currentRefinement, nbPages, refine }: TProps) => (
  <Pagination
    currentPage={currentRefinement}
    numberOfPages={nbPages}
    onClick={refine}
  />
);

const CustomPagination = connectPagination(PaginationComplete);

export default CustomPagination;
