import React, { FC } from 'react';
import clsx from 'clsx';

import { Link, LinkProps } from '../link/link';
import { Text } from '../text/text';
import { TColor } from '../colors/colors';
import styles from './tag.module.css';

export interface TagProps
  extends Omit<LinkProps, 'variant' | 'children' | 'destination'> {
  variant?: TColor;
  destination?: string;
  children?: any;
}

export const Tag: FC<TagProps> = ({
  variant = 'mint',
  children,
  className,
  destination,
  ...props
}) => {
  const bgColorClassName = `bg-${variant}`;
  const classNames = clsx(
    styles.tag,
    bgColorClassName,
    'flex-center',
    'text-capitalize',
    className
  );

  if (!destination) {
    return (
      <Text className={classNames} color="dark-green" variant="u1a">
        {children}
      </Text>
    );
  }

  return (
    <Link
      {...props}
      destination={destination}
      className={classNames}
      underline={false}
      color="darkGreen"
      variant="u1a"
    >
      {children}
    </Link>
  );
};
