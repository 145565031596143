import React, { useEffect, FC } from 'react';
import styles from './side-menu.module.css';
import clsx from 'clsx';
import { CloseIcon } from '../../../atoms/icons/close-icon/close-icon';
import { NavSection } from '../nav-section/nav-section';
import { SideItem } from '../side-item/side-item';
import { useMask } from '../../../../hooks/use-mask';
import useNavigation from '../../../../hooks/use-navigation';
import { SanityNavigationItem } from 'graphql-types';

interface SideMenuProps {
  routeSelected: SanityNavigationItem;
  handleCloseSideMenu: () => void;
  handleOpenSideMenu: (route: SanityNavigationItem) => void;
}

export const SideMenu: FC<SideMenuProps> = props => {
  const { routeSelected, handleCloseSideMenu, handleOpenSideMenu } = props;
  const { showMask } = useMask();
  const { mainPrimaryNav } = useNavigation();

  useEffect(() => {
    if (!showMask) {
      handleCloseSideMenu();
    }
  }, [showMask, handleCloseSideMenu]);

  return (
    <aside className={clsx(styles.sidemenu, routeSelected && styles.visible)}>
      <div className="flex justify-end">
        <button onClick={handleCloseSideMenu} className="m-xxs">
          <CloseIcon iconWidth="36" color="darkGreen" />
        </button>
      </div>
      <NavSection
        routes={mainPrimaryNav}
        justify="start"
        handleOpenSideMenu={handleOpenSideMenu}
        handleCloseSideMenu={handleCloseSideMenu}
        routeOpened={routeSelected?.title}
      />
      {routeSelected?.childrenItems && (
        <div
          className="mt-xl flex flex-col gap-sm"
          onClick={handleCloseSideMenu}
        >
          {routeSelected.childrenItems.map((route, index) => (
            <SideItem
              key={route.title}
              route={route}
              itemNumber={index}
              onNavigate={handleCloseSideMenu}
            />
          ))}
        </div>
      )}
    </aside>
  );
};
