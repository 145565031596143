import { useState, useEffect } from 'react';
import { TOption } from '../redesign/types';

export const useSelectOptions = <T extends { value?: any }>(
  options: T[],
  initialOptions?: T[],
  singleSelectMode = false
): [T[], T[], Function, Function] => {
  const [_options, setOptions] = useState(
    options.map(o => {
      const initialOption =
        initialOptions && initialOptions.length
          ? initialOptions.find(option => option.value === o.value)
          : undefined;
      return {
        ...o,
        isActive: !!initialOption
      };
    })
  );
  const [selectedOptions, setSelectedOptions] = useState<T[]>([]);
  const [isInitialOptionApplied, setIsInititalOptionApplied] = useState(false);
  const onSelectOption = (option: {
    isActive?: boolean;
    value: any;
    label?: string;
  }) => {
    const newOptions = _options.map(o => {
      const isSelectedOption = o.value === option.value;
      if (isSelectedOption && singleSelectMode && o.isActive) return o;
      if (isSelectedOption) return { ...o, isActive: !o.isActive };
      if (singleSelectMode) return { ...o, isActive: false };
      return o;
    });
    setOptions(newOptions);
    setSelectedOptions(newOptions.filter(o => o.isActive));
  };

  const clearOptions = () => {
    const newOptions = _options.map(option => {
      return { ...option, isActive: false };
    });

    setOptions(newOptions);
    setSelectedOptions([]);
  };

  useEffect(() => {
    if (!isInitialOptionApplied && initialOptions) {
      setSelectedOptions(initialOptions);
      setIsInititalOptionApplied(true);
    }
  }, [setSelectedOptions, initialOptions, isInitialOptionApplied]);

  return [_options, selectedOptions, onSelectOption, clearOptions];
};

export const optionsToString = (options: TOption<string>[]) => {
  return options.reduce((acc: string, option, index: number) => {
    const { label } = option;
    if (index === 0) {
      return label;
    } else if (index === options.length - 1) {
      return `${acc} & ${label}`;
    }
    return `${acc}, ${label}`;
  }, '');
};
