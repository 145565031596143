import React from 'react';
import { navigate } from 'gatsby';
import { Highlight, Index, connectHits } from 'react-instantsearch-dom';
import { getBlogPostUrl } from '../../../lib/blog-utils';
import { useCurrentPath } from '../../../hooks/use-current-path';
import { useHiltonPosts } from '../../../hooks/use-hilton-posts';
import { SanityHiltonPost } from 'graphql-types';
import './search.css';

type TResult = {
  title: string;
  objectId: string;
  slug: { current: string };
};
const Hits = ({ hits }: { hits: TResult[] }) => {
  const hiltonPath = 'hilton/education';
  const currentPath = useCurrentPath();
  const { hiltonPosts } = useHiltonPosts();
  const isHiltonPath = currentPath.includes(hiltonPath);

  // Filter hits to be only hiltonPosts if the url path /hilton/education/
  if (isHiltonPath) {
    const hiltonSlugStrings = hiltonPosts.map(({ slug }: SanityHiltonPost) => {
      return slug.current;
    });

    hits = hits.filter(hit => {
      return hiltonSlugStrings.find(
        (slug: string) => slug === hit.slug.current
      );
    });
  }

  return (
    <div className="container-search-result">
      {hits.map((hit: TResult) => (
        <button
          className="button-container-result"
          onClick={() =>
            navigate(
              getBlogPostUrl(
                hit.slug.current,
                isHiltonPath ? hiltonPath : 'blog'
              )
            )
          }
        >
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </button>
      ))}
    </div>
  );
};

const CustomHits = connectHits(Hits);

const HitsInIndex = ({ index }: { index: string }) => (
  <Index indexName={index}>
    <CustomHits />
  </Index>
);

const BlogSearchResult = ({ index }: { index: string }) => (
  <HitsInIndex index={index} />
);

export default BlogSearchResult;
