import React, { ReactNode } from 'react';

import { Text } from '../../';

export const BlockContentParagraphStyle = (
  pStyle: string,
  children: ReactNode
) => {
  switch (pStyle) {
    case 'pl':
      return <Text variant="p3a">{children}</Text>;
    case 'ps':
      return <Text variant="p3a">{children}</Text>;
    case 'pxs':
      return <Text variant="p3a">{children}</Text>;
    case 'pSerif':
      return <Text variant="p3a">{children}</Text>;
    case 'pSerifl':
      return <Text variant="p3a">{children}</Text>;
    case 'pSerifXL':
      return <Text variant="p3a">{children}</Text>;
    case 'h2':
      return (
        <Text variant="h2a" {...pStyle}>
          {children}
        </Text>
      );
    default:
      return (
        <Text variant="p3a" className="mb-lg" {...pStyle}>
          {children}
        </Text>
      );
  }
};
