import React, { FC } from 'react';
import clsx from 'clsx';
import useResponsiveProps from '../../../hooks/use-responsive-props';
import { useBorder } from '../../../hooks/use-border';
import { Column } from './';
import {
  TBorderOptions,
  ColumnUnit,
  GridLength,
  GridOrientation
} from '../../types';

import './grid.scss';

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Default amount of space children Columns
   * are going to span. Can be overwritten by
   * the Column's cols prop
   */
  cols?: ColumnUnit;
  /**
   * Default amount of space will be between
   * each Column. Can be overwritten by the
   * Column's gap prop.
   */
  gap?: ColumnUnit;
  /**
   * Amount of columns the grid will have.
   */
  gridLength?: GridLength;
  /**
   * Activates a visual grid to accurately
   * meassure the size of the Columns inside
   * the Row. This is a dev-only prop.
   */
  useDevGrid?: boolean;
  /**
   * This prop is DEPRECATED use border prop instead
   * Adds structured variant to the Row and
   * it's children Columns by adding the default
   * border style to them.
   * none (default) = no borders
   * always = shows borders on all sides in both mobile and desktop views
   * desktopOnlyY = only shows y axis borders in desktop view, not mobile
   * onlyX = only shows x axis borders, not y axis
   */
  bordered?: TBorderOptions;
  /**
   * Adds border style to the row
   * The parameters are sent by an array of 2 strings
   * The first string corresponds to the borders in mobile
   * The second string corresponds to the borders in desktop
   * example: border={["top bottom", "top right"]}
   * Here, the row will have a border top, bottom in mobile
   * in desktop will have a border top, right.
   */
  borders?: string | string[];
  /**
   * Adds further border styles to the children
   * Columns if the bordered prop is set to true.
   * The styles applied are set to make the Columns
   * have the right borders if the Columns stack.
   */
  borderedStack?: boolean;
  /**
   * Reverses the layout
   */
  reversed?: GridOrientation;
  /**
   * Defaults to true. Set to false when there are
   * stacking rows to eliminate extra borders
   */
  lastRow?: boolean;
  /**
   * Optional css class for the container element
   */
  containerClassName?: string;
  /**
   * The offset that columns will have
   */
  offset?: ColumnUnit;
}

export const Row: FC<RowProps> = ({
  children,
  cols = 12,
  gap = 0,
  gridLength = 25,
  useDevGrid,
  bordered = 'none',
  borders,
  borderedStack = true,
  className,
  reversed,
  lastRow = true,
  containerClassName,
  offset,
  ...props
}) => {
  const { getPropV2 } = useResponsiveProps();
  const { getBorderClasses } = useBorder();

  const rowClassnames = clsx(className, {
    'bordered-stack': borderedStack,
    [`${getPropV2(cols, 'col')}`]: cols,
    [`${getPropV2(gap, 'gap')}`]: gap,
    [`${getPropV2(offset, 'offset')}`]: offset,
    [`${getPropV2(reversed, 'row-reverse')}`]: reversed,
    [`grid-${gridLength}`]: gridLength !== 25,
    bordered: bordered !== 'none',
    row: true,
    'last-row': lastRow,
    'ony-x-border': bordered === 'onlyX',
    'mobile-unbordered': bordered === 'mobileHidesY'
  });

  const containerClassnames = clsx('row-container', containerClassName, {
    bordered: bordered !== 'none',
    [`${getBorderClasses(borders)}`]: borders
  });

  return (
    <div className={containerClassnames} {...props}>
      <div className={rowClassnames}>{children}</div>
      {useDevGrid && (
        <div className="reference-grid row">
          {[...Array(gridLength)].map((_, i) => {
            return (
              <Column cols={1}>
                <div className="reference-grid-column">{i + 1}</div>
              </Column>
            );
          })}
        </div>
      )}
    </div>
  );
};
