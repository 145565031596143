import React, { FC } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Text } from '../..';
import { CaretIcon } from '../../molecules/icons';
import styles from './heading.module.css';

export type HeadingProps = {
  text?: string;
  isOpen?: boolean;
};

export const Heading: FC<HeadingProps> = ({ text = '', isOpen = false }) => (
  <motion.div
    layout
    className={clsx(
      styles.containerHeading,
      isOpen
        ? `${styles.headingOpen}`
        : `${styles.outlined} ${styles.borderBottom}`
    )}
  >
    <motion.div layout="position" className="flex-center flex-grow">
      <Text
        variant="nav"
        color={isOpen ? 'dark-green' : 'current'}
        className="self-center"
      >
        {text}
      </Text>
    </motion.div>
    <CaretIcon
      direction={'down'}
      color={isOpen ? 'darkGreen' : 'current'}
      iconWidth="40"
      className={clsx(isOpen ? styles.iconOpen : styles.iconClosed, 'self-end')}
    />
  </motion.div>
);
