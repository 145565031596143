import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

export const DigiohScript: React.FC = () => {
  const [hasMounted, setHasMounted] = useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (hasMounted) {
    return null;
  }

  return (
    <Helmet>
      <script
        async
        type="text/javascript"
        src="https://www.lightboxcdn.com/vendor/a7833734-696e-48ee-bfd2-47b57d60cc67/lightbox_inline.js"
      ></script>
    </Helmet>
  );
};
