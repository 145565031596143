import React, { FC } from 'react';
import clsx from 'clsx';
import { Text } from '../..';
import { CaretIcon } from '../../molecules/icons';
import './footer-heading.scss';

export type FooterHeadingProps = {
  text?: string;
  isOpen?: boolean;
};

export const FooterHeading: FC<FooterHeadingProps> = ({
  text,
  isOpen = false
}) => (
  <div
    className={clsx('bg-white footer-heading-container', {
      'footer-border-bottom': !isOpen
    })}
  >
    <div className="ml-sm">
      <Text variant="u1a" color="dark-green" className="self-center">
        {text}
      </Text>
    </div>
    <CaretIcon
      direction={'down'}
      color="darkGreen"
      iconWidth="40"
      className={clsx(
        isOpen ? 'footer-icon-open' : 'footer-icon-closed',
        'self-end'
      )}
    />
  </div>
);
