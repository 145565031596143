import React from 'react';

export const ReadPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.4246 21.9726C39.8888 22.1843 35.5728 26.8436 35.7845 32.3793C35.9962 37.9151 40.6554 42.2311 46.1912 42.0194C51.727 41.8077 56.043 37.1485 55.8313 31.6127C55.6196 26.0769 50.9604 21.7609 45.4246 21.9726ZM35.2859 30.7565C35.88 25.6587 40.1145 21.6127 45.4031 21.4105C51.006 21.1962 55.7587 25.3739 56.3414 30.8683H58.7286V31.4308H56.3861C56.389 31.4842 56.3914 31.5376 56.3934 31.5912C56.617 37.4374 52.0589 42.358 46.2127 42.5816C40.3665 42.8051 35.4459 38.2471 35.2224 32.4008C35.2099 32.0745 35.2123 31.7511 35.229 31.4312C34.3001 30.6331 33.1165 30.1898 31.8889 30.1834C30.6648 30.177 29.4798 30.6054 28.5439 31.3895C28.5477 31.4574 28.551 31.5254 28.5536 31.5935C28.7772 37.4398 24.2191 42.3603 18.3729 42.5839C12.5266 42.8074 7.60608 38.2494 7.38252 32.4031C7.37 32.0759 7.37247 31.7516 7.3893 31.4308H5.27246V30.8683H7.43383C7.97969 25.719 12.2368 21.6165 17.5633 21.4128C23.1155 21.2005 27.8329 25.301 28.4847 30.7219C29.4697 30.0031 30.663 29.6144 31.8918 29.6209C33.1201 29.6273 34.3087 30.0281 35.2859 30.7565ZM17.5847 21.9749C12.049 22.1866 7.73297 26.8459 7.94466 32.3816C8.15636 37.9174 12.8156 42.2334 18.3514 42.0217C23.8871 41.81 28.2031 37.1508 27.9915 31.615C27.7798 26.0793 23.1205 21.7632 17.5847 21.9749Z"
      fill="currentColor"
    />
  );
};
