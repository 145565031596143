import React, { FC } from 'react';
import { TColor } from '../colors/colors';
import './image-color-mask.css';

export interface ColorMaskProps {
  svgRefId: string;
  color: TColor;
  removeOnHover?: boolean;
}

export const ColorMask: FC<ColorMaskProps> = ({
  svgRefId,
  color = 'dark-green',
  removeOnHover
}) => {
  return (
    <div
      className={`image-color-mask bg-${color} ${
        removeOnHover ? 'image-color-mask-remove-on-hover' : ''
      }`}
      style={{
        clipPath: `url(#${svgRefId})`
      }}
    />
  );
};
