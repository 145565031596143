import React, { FC } from 'react';
import {
  ICarouselSlide,
  PersonalizedPlans,
  SlideAccordionItemProps
} from '@ph-bit/marketing.common.groups.personalized-plans';

export interface CardCarouselAndSlideAccordionProps {
  headline?: string;
  subheader?: string;
  initialActiveIndex?: number;
  cardCarouselItems?: ICarouselSlide[];
  slideAccordionItems?: SlideAccordionItemProps[];
  primaryButtonText?: string;
  destination?: string;
}

export const CardCarouselAndSlideAccordion: FC<CardCarouselAndSlideAccordionProps> = ({
  headline,
  subheader,
  initialActiveIndex,
  cardCarouselItems,
  slideAccordionItems,
  primaryButtonText
}) => {
  const isSSR = typeof window === 'undefined';

  return (
    <>
      {!isSSR && (
        <PersonalizedPlans
          headline={headline}
          subheader={subheader}
          initialActiveIndex={initialActiveIndex}
          carouselItems={cardCarouselItems}
          accordionItems={slideAccordionItems}
          primaryButtonText={primaryButtonText}
        />
      )}
    </>
  );
};
