import React, { FC, ReactNode } from 'react';

import { Link, Text, BlogPostQuote, BlogPostInlineBanner } from '../../';
import { BlockContentImage } from './block-content-image';
import { BlockContentParagraphStyle } from './block-content-paragraph';

export interface MarkItemProps {
  children?: any;
  mark: {
    href: string;
    description: string;
    id: string;
    blank: string;
  };
}

interface BlockProps {
  children: ReactNode;
  node: {
    style: string;
    content: string;
    label: string;
  };
}

const anchorTarget: FC<MarkItemProps> = ({ mark, children }) => {
  return <span id={mark.id}>{children}</span>;
};

const link: FC<MarkItemProps> = ({ mark, children }) => {
  return (
    <Link
      destination={mark.href}
      variant="p3a"
      aria-label={mark.description || `Link with reference to "${children}"`}
    >
      {children}
    </Link>
  );
};

const anchorLink = link;

const strikethrough = ({ children }) => {
  return (
    <Text variant="p3a" htmlTag="del">
      {children}
    </Text>
  );
};

const strikethroughNoStylesVariant = ({ children }) => {
  return (
    <Text variant="pNoStyles" htmlTag="del">
      {children}
    </Text>
  );
};

const strong = ({ children }) => {
  return (
    <Text variant="p3a" htmlTag="strong">
      {children}
    </Text>
  );
};

const strongNoStylesVariant = ({ children }) => {
  return (
    <Text variant="pNoStyles" htmlTag="strong">
      {children}
    </Text>
  );
};

const italic = ({ children }) => {
  return (
    <Text variant="p3a" htmlTag="em">
      {children}
    </Text>
  );
};

const italicNoStylesVariant = ({ children }) => {
  return (
    <Text variant="pNoStyles" htmlTag="em">
      {children}
    </Text>
  );
};

export const list = ({ children }) => {
  return (
    <ul style={{ listStyleType: 'disc' }} className="pl-sm">
      {children}
    </ul>
  );
};

export const listItem = ({ children }) => {
  return (
    <Text variant="p3a" className="mt-sm">
      <li>{children}</li>
    </Text>
  );
};

export const clover = ({ children }) => {
  return (
    <span className="text-module--text-utility3b--2Gkx9 text-clover">
      {children}
    </span>
  );
};

const ScCtaCard = (props: Record<string, any>) => {
  const { title, buttons = [] } = props.node;
  //TODO: Handle CTAs with two buttons
  return <BlogPostInlineBanner title={title} cta={buttons[0]} />;
};

export const marks = {
  anchorTarget,
  anchorLink,
  link,
  strikethrough,
  strong,
  italic,
  clover
};

export const marksNoStylesVariant = {
  anchorTarget,
  anchorLink,
  link,
  strikethroughNoStylesVariant,
  strongNoStylesVariant,
  italicNoStylesVariant,
  clover
};

export const commonTypesToSerialize = {
  basicImage(props: any) {
    return <BlockContentImage {...props} />;
  },
  scCtaCard(props: Record<string, any>) {
    return <ScCtaCard {...props} />;
  },
  // scOptinMonster: This kind of shortcode doesn't need to be displayed
  // in the UI, we only have it to save the history of migration in sanity
  scOptinMonster(): null {
    return null;
  },
  undefined(): null {
    return null;
  },
  // Add this moment we dont have support for figure in the block content editor
  figure(): null {
    return null;
  },
  // Add this moment we dont have support for slideshow in the block content editor
  slideshow(): null {
    return null;
  }
};

export const blockContentSerializers = (styleProps: Record<string, any>) => {
  return {
    listItem,
    list,
    marks,
    types: {
      block({ children, node }: BlockProps) {
        switch (node.style) {
          case 'h1':
            return (
              <Text variant="h1a" {...styleProps}>
                {children}
              </Text>
            );
          case 'h2':
            return (
              <Text variant="p1a" className="mb-sm text-center" {...styleProps}>
                {children}
              </Text>
            );
          case 'h3':
            return (
              <Text variant="h8b" className="mb-tiny pb-xtiny" {...styleProps}>
                {children}
              </Text>
            );
          case 'h4':
            return (
              <Text variant="h4a" {...styleProps}>
                {children}
              </Text>
            );
          case 'blockquote': {
            const [quote, , author] = React.Children.toArray(children);
            return quote ? (
              <BlogPostQuote quote={quote} author={author} />
            ) : null;
          }

          case 'extra-space-below': {
            return (
              <Text variant="p1a" {...styleProps}>
                {children}
              </Text>
            );
          }

          default:
            return BlockContentParagraphStyle(styleProps.pStyle, children);
        }
      },
      ...commonTypesToSerialize
    }
  };
};
