import React, { FC } from 'react';
import clsx from 'clsx';
import { StarIcon } from '../icons';
import styles from './rating.module.css';

export type RatingProps = {
  iconSize: number;
  className?: string;
  rating?: number;
};

/**
 * Please add here documentation for your component and how to use it
 */
export const Rating: FC<RatingProps> = ({
  iconSize,
  className,
  rating = 0
}): JSX.Element => {
  if (!rating) {
    return null;
  }
  return (
    <div className={clsx(styles.container, className)}>
      {Array.from({ length: rating }, (_, k) => (
        <span key={k}>
          <StarIcon iconWidth={iconSize} />
        </span>
      ))}
    </div>
  );
};
