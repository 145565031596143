import React from 'react';

export const MovementPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8956 18.1483L22.9316 17.1483L16.9316 16.9303L17.1496 22.9323L18.1496 22.8963L17.9848 18.3838L27.9044 28.3034L28.3022 27.9056L18.3799 17.9833L22.8956 18.1483ZM45.8514 22.8966L46.8514 22.9326L47.0694 16.9326L41.0674 17.1506L41.1034 18.1506L45.6164 17.9858L35.6966 27.9056L36.0944 28.3034L46.0164 18.3814L45.8514 22.8966ZM17.9848 45.6174L18.1496 41.1041L17.1496 41.0681L16.9316 47.0701L22.9316 46.8521L22.8956 45.8501L18.3809 46.0169L28.3022 36.0956L27.9044 35.6978L17.9848 45.6174ZM45.6159 46.0149L41.1034 45.8501L41.0674 46.8501L47.0694 47.0681L46.8514 41.0681L45.8494 41.1021L46.0162 45.6197L36.0944 35.6978L35.6966 36.0956L45.6159 46.0149Z"
      fill="currentColor"
    />
  );
};
