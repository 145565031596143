import React, { FC, ReactNode } from 'react';
import './blog-post-quote.scss';
import { Text } from '../..';

export type BlogPostQuoteProps = {
  quote: ReactNode;
  author: ReactNode;
};

export const BlogPostQuote: FC<BlogPostQuoteProps> = ({ quote, author }) => {
  return (
    <div className="blog-post-quote text-center my-xxl">
      <Text variant="h7a" color="sage" className="quote">
        {quote}
      </Text>
      <Text variant="u1a" color="sage" className="author block">
        {author}
      </Text>
    </div>
  );
};
