import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import LogoFile from '../../../assets/logo-blog-horizontal.svg';
import { theme } from '@parsleyhealth/cilantro-ui';

const LogoWrapper = styled(({ alt, ...rest }) => <div {...rest} />)<{
  alt?: string;
}>`
  width: 230px;
  height: 25px;
  display: flex;
  align-items: center;
  path {
    fill: #414143;
  }
  @media screen and (min-width: 300px) {
    width: 150px;
    height: 20px;
  }
  @media screen and (min-width: ${theme.breakpoints[0]}) {
    width: 160px;
    height: 24px;
  }
  @media screen and (min-width: ${theme.breakpoints[2]}) {
    width: 190px;
    height: 24px;
  }
`;

const LogoNav: React.FC = () => {
  return (
    <Link to="/blog">
      <LogoWrapper>
        <LogoFile role="img" aria-label="Parsley Health Blog Logo" />
      </LogoWrapper>
    </Link>
  );
};

export default LogoNav;
