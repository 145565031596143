import React, { FC } from 'react';
import styles from './nav-section.module.css';
import clsx from 'clsx';

import { NavItem } from '../nav-item/nav-item';
import { SanityNavigationItem } from 'graphql-types';

interface NavItemProps {
  routes: SanityNavigationItem[];
  justify: 'start' | 'end';
  handleOpenSideMenu: (route: SanityNavigationItem) => void;
  handleCloseSideMenu: () => void;
  routeOpened?: string;
  children?: any;
}

export const NavSection: FC<NavItemProps> = props => {
  const {
    routes,
    justify = 'start',
    handleOpenSideMenu,
    handleCloseSideMenu,
    routeOpened,
    children
  } = props;
  return (
    <nav
      className={clsx(
        styles.section,
        'flex align-center gap-xs',
        `justify-${justify}`
      )}
    >
      {routes?.map(route => (
        <NavItem
          key={route.title}
          route={route}
          onComplexRouteClick={handleOpenSideMenu}
          onNavigate={handleCloseSideMenu}
          opened={routeOpened === route.title}
        />
      ))}
      {children}
    </nav>
  );
};
