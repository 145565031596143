import { useState, useCallback } from 'react';
import { useScrollController } from './use-scroll-controller';
import useDeviceDetect from './use-is-device-detect';

export const useOnToggleScrolling = (
  initialState = false
): [boolean, () => void] => {
  const [isOpen, setIsOpen] = useState(initialState);
  const { avoidScrolling, enableScrolling } = useScrollController();
  const { isMobile } = useDeviceDetect();

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    if (!isMobile) return;
    if (isOpen) {
      avoidScrolling();
    } else {
      enableScrolling();
    }
  }, [isOpen, avoidScrolling, enableScrolling, setIsOpen, isMobile]);

  return [isOpen, handleToggle];
};
