import React from 'react';

export const SecurePath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.7977 9.94389C26.7078 8.03378 29.2984 6.96069 31.9997 6.96069C34.701 6.96069 37.2917 8.03378 39.2018 9.94389C41.1119 11.854 42.185 14.4447 42.185 17.146V23.6887H46.5954C48.3159 23.6887 49.7106 25.0835 49.7106 26.804V52.316C49.7106 54.0365 48.3159 55.4313 46.5954 55.4313H17.4034C15.6828 55.4313 14.2881 54.0365 14.2881 52.316V26.804C14.2881 25.0835 15.6828 23.6887 17.4034 23.6887H21.8145V17.146C21.8145 14.4447 22.8875 11.854 24.7977 9.94389ZM41.6225 17.146V23.6887H22.377V17.146C22.377 14.5939 23.3908 12.1463 25.1954 10.3417C27 8.53707 29.4476 7.52325 31.9997 7.52325C34.5518 7.52325 36.9994 8.53707 38.804 10.3417C40.6086 12.1463 41.6225 14.5939 41.6225 17.146ZM17.4034 24.2513C15.9935 24.2513 14.8506 25.3942 14.8506 26.804V52.316C14.8506 53.7258 15.9935 54.8687 17.4034 54.8687H46.5954C48.0052 54.8687 49.1481 53.7258 49.1481 52.316V26.804C49.1481 25.3942 48.0052 24.2513 46.5954 24.2513H17.4034ZM31.9998 32.6895C31.1098 32.6895 30.2412 32.9622 29.511 33.471C28.7808 33.9798 28.2241 34.7001 27.9159 35.535C27.6077 36.3699 27.5627 37.2792 27.7871 38.1404C27.9969 38.9456 28.4327 39.6725 29.0413 40.2363L27.6541 46.0849L27.572 46.4311H27.9278H36.0718H36.4276L36.3454 46.0849L34.9582 40.2363C35.5668 39.6725 36.0026 38.9456 36.2124 38.1404C36.4368 37.2792 36.3918 36.3699 36.0836 35.535C35.7754 34.7001 35.2187 33.9798 34.4885 33.471C33.7583 32.9622 32.8897 32.6895 31.9998 32.6895ZM29.6274 40.2028L29.3538 40.1379L29.5365 39.9241C28.9475 39.4205 28.5269 38.7485 28.3315 37.9986C28.1361 37.2487 28.1753 36.4569 28.4436 35.7299C28.712 35.0029 29.1968 34.3756 29.8326 33.9326C30.4685 33.4895 31.2248 33.252 31.9998 33.252C32.7747 33.252 33.5311 33.4895 34.1669 33.9326C34.8027 34.3756 35.2875 35.0029 35.5559 35.7299C35.8243 36.4569 35.8634 37.2487 35.668 37.9986C35.4726 38.7485 35.052 39.4205 34.463 39.9241L34.3324 40.0357L34.3721 40.2028L35.716 45.8686H28.2836L29.6274 40.2028Z"
      fill="currentColor"
    />
  );
};
