import { useState } from 'react';
import { createContainer } from 'unstated-next';

export function useMobileMenu(initialState = false) {
  const [expanded, setExpanded] = useState(initialState);
  const toggle = () => setExpanded(!expanded);
  return { expanded, toggle };
}

export const Toggler = createContainer(useMobileMenu);
