import React, { useEffect } from 'react';
import useNavigation from './../../../hooks/use-navigation';
import '../../HeaderNew/main-nav.css';
import useTheme from '../../../hooks/use-navigation-theme';
import { NavItemProps } from '../../HeaderNew/nav-types';
import HiltonLogo from '../../../assets/hilton-logo.svg';
import LogoFile from '../../../assets/logo-horizontal.svg';
import { HiltonNavItem } from './hilton-item-nav';
import './hilton-nav.css';

const HiltonMainNav = ({ children }) => {
  const { hiltonHeaderNav } = useNavigation();
  const { setTheme } = useTheme();

  useEffect(() => {
    setTheme('solidNavDarkText');
  }, [setTheme]);

  return (
    <div className="nav-container">
      <div className="main-navbar-wrapper">
        <div className="flex">
          <a
            href="/hilton/education/"
            className="main-nav-logo-wrapper"
            style={{ width: '160px' }}
          >
            <LogoFile
              role="img"
              style={{ width: '124px', height: '16px', marginTop: '6px' }}
            />
          </a>
          <div className="ml-4"></div>
          <HiltonLogo />
        </div>
        <div className="hilton-nav-wrapper">
          {hiltonHeaderNav?.map((item: NavItemProps) => (
            <HiltonNavItem route={item} />
          ))}
          {children}
        </div>
      </div>
    </div>
  );
};

export default HiltonMainNav;
