import cookie from 'js-cookie';
import { track } from '@parsleyhealth/cilantro-track';

const isMobile = () => {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
};

export class HelpScout {
  constructor(defaultOpenInMobile = false) {
    this.hubspotFormEndpoint =
      'https://api.hsforms.com/submissions/v3/integration/submit';
    this.helpscoutBeaconURL = 'https://beacon-v2.helpscout.net';
    this.onReady = null;
    this.beaconId = null;
    this.hubspotFormURL = null;
    this.config = {};
    this.defaultOpenInMobile = defaultOpenInMobile;
  }

  init({
    beaconId,
    hubspotPortalID,
    hubspotHelpscoutFormID,
    onReady,
    config = {}
  }) {
    this.beaconId = beaconId;
    this.config = config;
    this.hubspotFormURL = `${this.hubspotFormEndpoint}/${hubspotPortalID}/${hubspotHelpscoutFormID}`;
    this.onReady = onReady;
    this.onDocumentReady();
  }

  onDocumentReady() {
    let beacon = window.Beacon || (() => undefined);

    beacon = (method, options, data) => {
      window.Beacon.readyQueue.push({
        data,
        method,
        options
      });
    };

    window.Beacon = beacon;

    beacon.readyQueue = [];

    if (document.readyState === 'complete') {
      return this.addScript();
    }

    if (window.attachEvent) {
      window.attachEvent('onload', () => this.addScript());
    } else {
      window.addEventListener('load', () => this.addScript(), false);
    }
  }

  addScript() {
    const element = document.getElementsByTagName('script')[0];
    const tag = document.createElement('script');

    tag.type = 'text/javascript';
    tag.async = true;
    tag.src = this.helpscoutBeaconURL;
    tag.onload = () => window.setTimeout(() => this.initBeacon(), 0);
    element.parentNode.insertBefore(tag, element);
  }

  initBeacon() {
    // const referrer = document.referrer;
    window.Beacon('init', this.beaconId);
    window.Beacon('config', this.config);
    window.Beacon('once', 'open', this.checkForLead.bind(this));
    window.Beacon('on', 'chat-started', () => {
      track('Chat Started');
    });
    window.Beacon('once', 'ready', () => this.onReady());
    window.Beacon('on', 'open', () => {
      track('Chat Opened');
    });
    window.Beacon('on', 'close', () => {
      track('Chat Closed');
    });
    if (this.defaultOpenInMobile && isMobile()) {
      this.open();
    }
  }

  checkForLead() {
    let found = false;

    const beaconState = window.beaconStore.getState();

    if (beaconState.user.email) {
      // If there is already an identified user, assume already submitted lead
      return;
    }

    window.beaconStore.subscribe(() => {
      try {
        // Return early if lead was already found (form submitted)
        if (found) {
          return;
        }

        const beaconState = window.beaconStore.getState();

        // We're looking for an email primarily
        if (beaconState.user.email) {
          found = true;
          this.submitLead(beaconState.user);
        }
      } catch (err) {
        console.error('Beacon scubscribe error', err);
      }
    });
  }

  async submitLead(beaconUser) {
    // Submit a HubSpot form to capture this lead...
    try {
      const hutk = cookie.get('hubspotutk');

      let { email, name } = beaconUser;

      email = email || '';
      name = name || '';

      const firstName = name.split(' ')[0] || '';
      const lastName = name.split(' ')[1] || '';

      const data = {
        context: {
          hutk,
          pageName: document.title,
          pageUri: window.location.href
        },
        fields: [
          { name: 'email', value: email },
          { name: 'firstname', value: firstName },
          { name: 'lastname', value: lastName }
        ]
      };

      await fetch(this.hubspotFormURL, {
        body: JSON.stringify(data),
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      return true;
    } catch (err) {
      console.error('Submit lead error', err);
    }
  }

  open() {
    window.Beacon('open');
  }

  close() {
    window.Beacon('close');
  }
}
