import React from 'react';
import clsx from 'clsx';

import { IconProps, allIconColorOptions } from '../../../types/index';

export const IconSvg: React.FC<IconProps> = ({
  children,
  iconWidth = '20px',
  color = 'darkGreen',
  strokeWidth = 1.5,
  className,
  ...props
}) => {
  return (
    <svg
      width="1em"
      height="1em"
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        fontSize: String(iconWidth).includes('px')
          ? iconWidth
          : `${iconWidth}px`
      }}
      className={clsx(`${allIconColorOptions[color]}`, className)}
      {...props}
    >
      {children}
    </svg>
  );
};
