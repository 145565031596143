//@ts-nocheck
import React, { FC } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export type SlideDownProps = {
  duration?: number;
  show: boolean;
  children?: any;
};

/**
 * Duration prop is to make the slidedown go faster or slower
 * Show prop will show the animation b
 */
export const SlideDown: FC<SlideDownProps> = ({
  children,
  show,
  duration = 0.34
}) => {
  return (
    <AnimatePresence>
      <>
        {show && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{ duration: duration }}
          >
            {children}
          </motion.div>
        )}
      </>
    </AnimatePresence>
  );
};
