type QuizCTA = {
  title: string;
  path: string;
  hide: boolean;
};

export const useQuizContent = (
  destinationType: 'hero' | 'topNav'
): QuizCTA | null => {
  // eslint-disable-next-line no-lone-blocks
  {
    const destinations = {
      hero: 'https://insights.parsleyhealth.com/quiz/psi',
      topNav: 'https://insights.parsleyhealth.com/quiz/psi'
    };
    const cta = {
      hero: {
        title: 'Get Symptom Score',
        path: destinations.hero,
        hide: false
      },
      topNav: {
        title: 'Get Symptom Score',
        path: destinations.topNav,
        hide: false
      }
    };

    return cta[destinationType];
  }
};
