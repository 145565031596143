import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { Text, Image } from '../..';
import { ImageType } from '../../atoms/image/types';
import { Module } from '../..';
import useWindowSize from '../../../hooks/use-window-size';
import IconHighlight from '../../../assets/icon-highlight.svg';
import LineStroke from '../../../assets/line-stroke.svg';
import MobileLineStroke from '../../../assets/mobile-line-stroke.svg';
import Carousel from 'react-multi-carousel';
import { breakpoint } from '../../types/index';

import './process-carousel.scss';
import { ImageProps } from 'src/redesign/types';

export type ProcessCarouselProps = {
  slides: ProcessCarouselSlideProps[];
};

interface ProcessCarouselSlideProps {
  title?: string;
  description?: string;
  image?: ImageProps;
  id?: number;
  ref?: HTMLDivElement;
  isCarouselMoving?: boolean;
  firstCard: boolean;
}

const ProcessCarouselSlide = React.forwardRef<
  HTMLDivElement,
  ProcessCarouselSlideProps
>(({ image, title, description, firstCard }, ref) => {
  return (
    <div ref={ref}>
      <div className="process-carousel-image-card">
        {firstCard && (
          <span className="process-carousel-highlight">
            <IconHighlight />
          </span>
        )}
        <Image
          blob
          className="process-carousel-slide-image process-carousel-slide-container"
          type={ImageType.ROUND}
          sizes={[150, 300]}
          {...image}
        />
      </div>
      <div className="process-carousel-text-card">
        <Text
          className="process-carousel-slide-title"
          color="primary"
          variant="h6a"
        >
          {title}
        </Text>
        <Text
          className="process-carousel-slide-copy"
          color="utility-black"
          variant="p3a"
        >
          {description}
        </Text>
      </div>
    </div>
  );
});

const offset = (el: any) => {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

const updatePosition = (firstSlide: HTMLElement) => {
  const imgContainer = firstSlide.getElementsByTagName('div')[0];

  const strokeLine = document.getElementsByClassName(
    'process-carousel-background-line'
  ) as HTMLCollectionOf<HTMLElement>;

  if (firstSlide) {
    const coordinates = offset(firstSlide);

    if (firstSlide instanceof HTMLElement) {
      const right = firstSlide.offsetWidth / 2 + coordinates.left;
      const top = imgContainer.offsetHeight * 0.05;
      strokeLine[0].style.left = right + 'px';
      strokeLine[0].style.top = top + 'px';
      const clipString = `inset(0px ${firstSlide.offsetWidth}px 0px 0px)`;
      strokeLine[0].style.clipPath = clipString;
    }
  }
};

export const ProcessCarousel: FC<ProcessCarouselProps> = ({ slides }) => {
  const [isMoving, setIsMoving] = useState(false);
  const [width, setWidth] = useState(0);
  const [firstSlide, setFirstSlide] = useState(null);
  const carousel = useRef<Carousel | null>(null);
  const windowSize = useWindowSize();

  const firstSlideRef = useCallback(node => {
    if (node) {
      setFirstSlide(node);
      updatePosition(node);
    }
  }, []);

  useEffect(() => {
    const container = carousel?.current?.containerRef.current.firstChild;
    const el = document.createElement('div');
    el.classList.add('process-carousel-background-line');
    // Need to add div into carousel 'ul' so render doesn't overwrite everything
    container.appendChild(el);
    const backgroundDiv = document.getElementsByClassName(
      'process-carousel-background-line'
    )[0];

    const lineStroke =
      windowSize.width <= breakpoint ? <MobileLineStroke /> : <LineStroke />;
    // appendChild only accepts nodes
    ReactDOM.render(lineStroke, backgroundDiv);
  }, [windowSize.width]);

  // Arrow clicks rerender function
  // Checks to make sure window has actually changed width
  if (width !== windowSize.width) {
    if (firstSlide) {
      updatePosition(firstSlide);
    }
    setWidth(windowSize.width);
  }

  const responsive = {
    desktop: {
      breakpoint: { min: 1025, max: 100000 },
      items: 2,
      partialVisibilityGutter: 150
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      partialVisibilityGutter: -100
    },
    device: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      partialVisibilityGutter: 140
    }
  };

  const handleCarouselAfterChange = () => {
    window.heap.track('Carousel Swipe', {
      carouselName: 'Process Carousel'
    });
    setIsMoving(false);
  };

  return (
    <Module>
      <ParsleyCarousel
        responsive={responsive}
        containerClass="process-carousel-container"
        itemClass="process-carousel-slide"
        leftArrowClass="process-carousel-left-button"
        rightArrowClass="process-carousel-right-button"
        ref={carousel}
        beforeChange={() => setIsMoving(true)}
        afterChange={() => handleCarouselAfterChange()}
        partialVisible={true}
      >
        {slides.map((s: ProcessCarouselSlideProps, i) => (
          <ProcessCarouselSlide
            key={`${s.id}-${i}`}
            image={s.image}
            ref={s.id === 1 ? firstSlideRef : null}
            title={s.title}
            description={s.description}
            isCarouselMoving={isMoving}
            firstCard={s.id === 1 ? true : false}
          />
        ))}
      </ParsleyCarousel>
    </Module>
  );
};
