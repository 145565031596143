import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const TwitterIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M13.5502 27.7496C22.6045 27.7496 27.5583 20.2463 27.5583 13.7415C27.5583 13.5306 27.5536 13.3149 27.5442 13.104C28.5079 12.4071 29.3395 11.5439 30 10.5549C29.1025 10.9542 28.1496 11.215 27.1739 11.3284C28.2013 10.7125 28.9705 9.74511 29.3391 8.6054C28.3726 9.17819 27.3156 9.58225 26.2134 9.80024C25.4708 9.01119 24.489 8.48875 23.4197 8.31368C22.3504 8.13862 21.2532 8.32068 20.2977 8.83173C19.3423 9.34278 18.5818 10.1543 18.1338 11.1409C17.6859 12.1276 17.5754 13.2342 17.8195 14.2899C15.8625 14.1917 13.9479 13.6833 12.2 12.7977C10.452 11.9121 8.90969 10.6691 7.67297 9.14915C7.0444 10.2329 6.85206 11.5153 7.13503 12.7357C7.418 13.9562 8.15506 15.0231 9.19641 15.7196C8.41463 15.6948 7.64998 15.4843 6.96563 15.1056V15.1665C6.96492 16.3038 7.3581 17.4062 8.07831 18.2864C8.79852 19.1666 9.80132 19.7702 10.9162 19.9946C10.1921 20.1928 9.43198 20.2216 8.69484 20.079C9.00945 21.0571 9.62157 21.9125 10.4458 22.526C11.27 23.1394 12.2651 23.4802 13.2923 23.5009C11.5484 24.8707 9.39417 25.6138 7.17656 25.6102C6.78329 25.6096 6.3904 25.5855 6 25.5381C8.25286 26.9834 10.8735 27.751 13.5502 27.7496Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};
