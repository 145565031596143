import React, { FC, Children } from 'react';
import clsx from 'clsx';

import './structured-list.scss';

export interface StructuredListProps {
  className?: string;
  children?: any;
}

export const StructuredList: FC<StructuredListProps> = ({
  className,
  children
}) => {
  const classNames = clsx(className, 'structured-list');
  const childrenArray = Children.toArray(children);

  return (
    <div className={classNames}>
      {Children.map(childrenArray, (child, index) => (
        <div
          className="structured-list-item"
          key={`structured-list-item-${index}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
