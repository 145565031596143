import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const PlusIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M7 18L29 18"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M18 29L18 7"
        stroke="currentColor"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </IconSvg>
  );
};
