const getCSSPropertyValue = (propertyName: string) => {
  let propertyValue = null;

  if (typeof window !== 'undefined') {
    propertyValue = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(propertyName);
  }

  return propertyValue;
};

export const useBreakpoints = () => {
  const small = '0px';
  const medium = getCSSPropertyValue('--device-m');
  const large = getCSSPropertyValue('--device-l');
  const breakpoints = [small, medium, large];
  const breakpointsByWidth = breakpoints.map(bp =>
    Number(bp?.replace('px', ''))
  );

  return {
    breakpoints,
    breakpointsByWidth
  };
};
