import React from 'react';

export const ListenPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.6911 15.0609V15.6423V48.3583V48.9398L33.2353 48.5788L19.6179 37.7975H10.9658H10.6846V37.5162V26.4842V26.203H10.9658H19.6181L33.2353 15.4218L33.6911 15.0609ZM33.1286 47.7768L19.9971 37.3802V26.6204L33.1286 16.2238V47.7768ZM11.2471 26.7655V37.235H19.4346V26.7655H11.2471ZM40.3431 29.115C39.9301 28.1224 39.3089 27.2299 38.5214 26.498L38.1384 26.9101C38.867 27.5872 39.4417 28.4128 39.8237 29.3311C40.2057 30.2494 40.3862 31.239 40.3528 32.2331C40.3195 33.2271 40.0731 34.2024 39.6304 35.0931C39.1877 35.9837 38.5589 36.7689 37.7866 37.3957L38.1411 37.8325C38.976 37.155 39.6556 36.3062 40.1342 35.3435C40.6127 34.3807 40.879 33.3265 40.9151 32.2519C40.9511 31.1774 40.7561 30.1077 40.3431 29.115ZM42.505 21.8911C43.9383 23.1804 45.0811 24.7599 45.8575 26.5246C46.6338 28.2893 47.026 30.1989 47.0081 32.1268C46.9901 34.0546 46.5624 35.9566 45.7533 37.7065C44.9442 39.4565 43.7722 41.0143 42.3151 42.2768L41.9467 41.8516C43.3435 40.6414 44.467 39.148 45.2427 37.4705C46.0183 35.7929 46.4283 33.9696 46.4455 32.1215C46.4628 30.2734 46.0868 28.4428 45.3425 26.7511C44.5983 25.0594 43.5028 23.5453 42.1288 22.3093L42.505 21.8911ZM51.5667 23.8463C50.4151 21.2822 48.7333 18.9911 46.6322 17.124L46.2585 17.5445C48.3002 19.3588 49.9345 21.5851 51.0536 24.0767C52.1727 26.5684 52.7513 29.2688 52.7513 32.0002C52.7513 34.7316 52.1727 37.432 51.0536 39.9237C49.9345 42.4153 48.3002 44.6416 46.2585 46.456L46.6322 46.8765C48.7333 45.0093 50.4151 42.7183 51.5667 40.1542C52.7184 37.5901 53.3139 34.8111 53.3139 32.0002C53.3139 29.1893 52.7184 26.4104 51.5667 23.8463Z"
      fill="currentColor"
    />
  );
};
