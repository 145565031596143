import React, { FC } from 'react';
import { Text, Row, Column, TextLockup } from '../../';
import {
  ProviderCard,
  ProviderCardProps
} from '../../molecules/provider-card/provider-card';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import useResponsiveProps from '../../../hooks/use-responsive-props';

type ProviderCarouselProps = {
  providers: ProviderCardProps[];
  headline?: string;
  description?: string;
};

export const ProviderCarousel: FC<ProviderCarouselProps> = props => {
  const { providers, headline, description } = props;
  const { getPropV2 } = useResponsiveProps();
  const responsive = {
    desktop: {
      breakpoint: { min: 758, max: 100000 },
      items: 4
    },
    device: {
      breakpoint: { max: 758, min: 0 },
      items: 2.2
    }
  };

  return (
    <Row gap={0} className="flex-col pb-xl">
      <Column cols={23} offset={1}>
        <TextLockup alignment="center">
          <Text
            variant="h3a"
            className="text-lockup-headline"
            color="dark-green"
          >
            {headline}
          </Text>
          <Text
            variant="p2a"
            className="text-lockup-description"
            color="dark-green"
          >
            {description}
          </Text>
        </TextLockup>
        <ParsleyCarousel
          responsive={responsive}
          containerClass={`pt-tiny ${getPropV2(['md', 'lg'], 'mt')}`}
          itemClass="flex justify-center"
          arrowSpacing="xtiny"
        >
          {providers.map((provider, i) => (
            <ProviderCard {...provider} key={`${provider.id}-${i}`} />
          ))}
        </ParsleyCarousel>
      </Column>
    </Row>
  );
};
