import { useMemo } from 'react';
import algoliasearch from 'algoliasearch/lite';

const useCreateSearchClient = () =>
  useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  );

export default useCreateSearchClient;
