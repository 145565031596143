import { useState, useEffect } from 'react';
import { ResponsiveImageType, ImageType } from 'src/redesign/types';
import { useWindowSize, useBreakpoints } from './';

export const useImageType = (type: ResponsiveImageType): ImageType => {
  const isArray = Array.isArray(type);
  const initialValue = isArray ? type[0] : type;
  const [imageType, setImageType] = useState(initialValue);
  const { width: browserWidth } = useWindowSize();
  const { breakpointsByWidth } = useBreakpoints();

  const setCorrectImageType = () => {
    if (isArray) {
      const validBreakpoints = breakpointsByWidth.slice(0, type.length);
      const currentBreakpointIndex = validBreakpoints.reduceRight(
        (acc, bp, index) => {
          const isInBreakpoint = browserWidth > bp;

          if (acc === -1 && isInBreakpoint) {
            return index;
          }

          return acc;
        },
        -1
      );
      const currentType = type[currentBreakpointIndex];

      if (currentType !== imageType) {
        setImageType(currentType as ImageType);
      }
    }
  };

  useEffect(() => {
    setCorrectImageType();
  }, [browserWidth]);

  setCorrectImageType();

  return imageType as ImageType;
};
