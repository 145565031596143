import React from 'react';

import { Button } from '../../..';
import { useQuizContent } from '../../../../hooks/use-quiz-content';

interface QuizButtonProps {
  ctaTrackingLabel?: string;
  scrolled: boolean;
}

export const QuizButton: React.FC<QuizButtonProps> = ({
  ctaTrackingLabel = 'Take the Quiz button on top desktop navigation'
}) => {
  const quizCtaContent = useQuizContent('topNav');

  if (quizCtaContent.hide) return null;

  return (
    <Button
      color="darkGreen"
      variant="outlined"
      size="small"
      trackingLabel={ctaTrackingLabel}
      trackingCategory="Clicked CTA"
      destination={quizCtaContent.path}
    >
      {quizCtaContent.title}
    </Button>
  );
};
