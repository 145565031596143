import React, { FC } from 'react';
import styles from './carousel-step-indicator.module.css';
import clsx from 'clsx';
import { TColor } from '../../types';

const orientationOptions = {
  vertical: 'carouselStepIndicatorVertical',
  horizontal: 'carouselStepIndicatorHorizontal'
};

export interface CarouselStepIndicatorProps
  extends React.HTMLAttributes<HTMLDivElement> {
  currentStep: number;
  totalSteps: number;
  primaryColor: TColor;
  accentColor: TColor;
  primaryStepClassName?: string;
  accentStepClassName?: string;
  orientation?: keyof typeof orientationOptions;
  connected?: boolean;
}

export const CarouselStepIndicator: FC<CarouselStepIndicatorProps> = ({
  currentStep,
  totalSteps,
  primaryColor,
  accentColor,
  orientation = 'horizontal',
  connected = false,
  className,
  primaryStepClassName = '',
  accentStepClassName = '',
  ...props
}) => {
  const orientationStyle = orientationOptions[orientation];

  const connectedStyle = connected ? 'connected' : 'disconnected';

  return (
    <div
      className={clsx(className, styles[orientationStyle], 'flex')}
      {...props}
    >
      {Array(totalSteps)
        .fill('')
        .map((_, slideIndex) => {
          const isActive = slideIndex === currentStep;

          return (
            <div
              key={slideIndex}
              className={clsx(
                styles.carousel,
                styles[connectedStyle],
                isActive ? primaryStepClassName : accentStepClassName,
                `bg-${isActive ? primaryColor : accentColor}`
              )}
            />
          );
        })}
    </div>
  );
};
