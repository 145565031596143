import { useEffect, useState, useCallback } from 'react';

interface DOMContainer {
  x: number;
  y: number;
  width: number;
  height: number;
  top?: number;
  bottom?: number;
}

function useDOMRect(el: HTMLElement): DOMContainer {
  const [rect, setRect] = useState<DOMContainer>({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });

  const handleResize = useCallback(() => {
    if (el) {
      const clientRect = el.getBoundingClientRect();
      const x = clientRect.left + window.scrollX;
      const y = clientRect.top + window.scrollY;
      setRect({
        x,
        y,
        width: clientRect.width,
        height: clientRect.height,
        top: clientRect.height < 0 ? y + clientRect.height : y,
        bottom: clientRect.height < 0 ? y : clientRect.height + y
      });
    }
  }, [el]);

  useEffect(() => {
    el && handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [el, handleResize]);

  return rect;
}

export default useDOMRect;
