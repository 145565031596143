import React, { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Carousel from 'react-multi-carousel';

import { CarouselStepIndicator, Text, BlockContent } from '../..';
import { useCarouselStep } from '../../../hooks/use-carousel-step';
import styles from './third-party-quote-carousel.module.css';
import {
  FastCompanyIcon,
  GoopIcon,
  VogueIcon,
  WomensHealthIcon,
  TodayIcon,
  WellGoodIcon
} from '../../molecules/icons';
import { IconProps, breakpoint } from '../../types/index';
import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import { useStringItemHeight } from '../../../hooks/use-max-string-item-height';
import defaultData from '../../content/third-party-quote-carousel/third-party-quote-carousel.json';

import useWindowSize from '../../../hooks/use-window-size';
import { SanityQuoteThirdParty } from 'graphql-types';

import { createSimpleSerializer } from '../../../lib/create-serializer';
const h5a = createSimpleSerializer('h5a');

/**
 * Since we are using svg as icons, we need to avoid to get third-party-quotes that doesn't support.
 * All the new quotes should be added here before to add it into sanity
 */
const thirdPartyAvailableIcons = [
  'vogue',
  'womenshealth',
  'today',
  'well+good',
  'goop',
  'fastcompany'
] as const;
type ThirdPartyAvailable = typeof thirdPartyAvailableIcons[number];
type QuoteSourceMapTypes = Record<ThirdPartyAvailable, FC<IconProps>>;

const quoteSourceMap: QuoteSourceMapTypes = {
  fastcompany: FastCompanyIcon,
  goop: GoopIcon,
  vogue: VogueIcon,
  womenshealth: WomensHealthIcon,
  today: TodayIcon,
  'well+good': WellGoodIcon
};

/**
 * This function is used to match the name from sanity with the name from the icon,
 * names from sanity could be in UpperCase, have spaces or have the character ',
 * we are matching to the ThirdPartyAvailable this way we dont need to add an extra value in sanity to match the icon name
 * @param name default string from sanity
 * @returns normalized string as ThirdPartyAvailable
 */
const normalizeThirdPartyName = (name: string) => {
  return name.replace(/\s|'/g, '').toLowerCase() as ThirdPartyAvailable;
};

/**
 * This function is used to filter the quotes that doesn't have support
 * and also sets the default data for the pages that dont get their data from sanity at the moment
 * @param data this data comes from the main component props, which is the data that comes from the sanity
 * @returns filtered data with only the quotes that have support
 */
const getQuotesArray = (data?: SanityQuoteThirdParty[]) => {
  const initial = data ?? (defaultData.quoteArray as SanityQuoteThirdParty[]);
  const filtered = initial.filter(item =>
    thirdPartyAvailableIcons.includes(
      normalizeThirdPartyName(item?.thirdPartyName)
    )
  );
  // this validation is needed to avoid the error when carousel has less than 3 quotes
  if (filtered.length >= 2) {
    return filtered;
  }
  return defaultData.quoteArray as SanityQuoteThirdParty[];
};

const responsive = {
  device: {
    breakpoint: { max: 758, min: 0 },
    items: 1
  }
};

export type ThirdPartyQuoteCarouselProps = {
  data?: SanityQuoteThirdParty[];
  reversed?: boolean;
};

export const ThirdPartyQuoteCarousel: FC<ThirdPartyQuoteCarouselProps> = ({
  data,
  reversed = false
}) => {
  const carousel = useRef<Carousel | null>(null);

  const windowSize = useWindowSize();

  const isDevice = windowSize.width <= breakpoint;

  const quoteArray = getQuotesArray(data);
  const { currentStep, goToStep } = useCarouselStep(quoteArray.length);

  const onClickIcon = (index: number) => () => {
    goToStep(index);
  };

  const handleAfterChange = () => {
    const step =
      carousel?.current?.state?.currentSlide > quoteArray.length
        ? carousel?.current?.state?.currentSlide - quoteArray.length
        : carousel?.current?.state?.currentSlide;

    window.heap.track('Carousel Swipe', {
      carouselName: 'Third Party Quote Carousel',
      currentStep: step
    });
    goToStep(step - 1);
  };

  const iconArray = quoteArray.map((q, index) => {
    const IconComponent =
      quoteSourceMap[normalizeThirdPartyName(q.thirdPartyName)];
    return (
      <div
        id={`third-party-icon-${index}`}
        className={clsx(styles.icons)}
        onClick={isDevice ? null : onClickIcon(index)}
        key={`${q.id}-${index}`}
      >
        <IconComponent
          iconWidth={isDevice ? '135px' : '115px'}
          color={index === currentStep ? 'darkGreen' : 'gray'}
        />
      </div>
    );
  });

  const hideLeftArrow = isDevice && currentStep === 0; // Can't allow users to slide the carousel back before the first icon.

  useEffect(() => {
    // On component mount, make sure that the carousel is never positioned on the empty slide.
    if (isDevice && carousel && carousel?.current?.state?.currentSlide === 0) {
      carousel.current.goToSlide(1);
    }
  }, [goToStep, isDevice]);

  const { maxRef, maxIndex } = useStringItemHeight(quoteArray, '_rawContent');

  return (
    <div
      className={clsx(
        styles.container,
        'flex',
        `${reversed ? 'flex-col-reverse' : 'flex-col'}`,
        'mt-xs',
        'mb-xxl'
      )}
    >
      <div className={clsx(styles.iconArray, { flex: !isDevice })}>
        {isDevice ? (
          <ParsleyCarousel
            ref={carousel}
            afterChange={handleAfterChange}
            responsive={responsive}
            centerMode={true}
            containerClass={styles.carouselContainer}
            leftArrowClass={hideLeftArrow ? styles.leftArrowHide : ''}
          >
            <div></div>
            {/* Had to add an empty first element to the carousel so the first Icon appears in the center when active. */}
            {iconArray}
          </ParsleyCarousel>
        ) : (
          iconArray
        )}
      </div>
      <CarouselStepIndicator
        className={styles.stepIndicator}
        currentStep={currentStep}
        totalSteps={quoteArray.length}
        primaryColor={'clover'}
        accentColor="dark-green"
        connected
      />
      <div className={clsx(styles.quote, 'm-xs', 'mt-lg')}>
        {quoteArray.map(({ _rawContent, id }, index) => {
          const isCurrentStep = index === currentStep;
          return (
            <div
              ref={index === maxIndex ? maxRef : undefined}
              key={`${id}-${index}`}
            >
              <Text
                variant="h5a"
                color="dark-green"
                style={{
                  height: isCurrentStep ? '100%' : 0,
                  visibility: isCurrentStep ? 'visible' : 'hidden'
                }}
              >
                {typeof _rawContent === 'string' ? (
                  <>{_rawContent}</>
                ) : (
                  <BlockContent blocks={_rawContent} serializer={h5a} />
                )}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
};
