import { useContext, useState } from 'react';
import { NavigationThemeContext } from '../context/navigation-theme-context';

export const useTheme = () => {
  const { theme, setTheme } = useContext(NavigationThemeContext);
  const [initialTheme, setInitialTheme] = useState(null);

  return { theme, setTheme, initialTheme, setInitialTheme };
};

export default useTheme;
