import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';

import { ParsleyCarousel } from '../parsley-carousel/parsley-carousel';
import {
  QuoteMeta,
  Quote as QuoteComponent,
  CarouselStepIndicator
} from '../../';
import { QuoteProps, QuoteMetaProps } from '../../types';
import { Row, Column } from '../..';
import styles from './quote-carousel.module.css';
import Carousel from 'react-multi-carousel';

interface Quote extends QuoteProps {
  meta: Omit<QuoteMetaProps, 'active'>;
}

export interface QuoteCarouselProps {
  quotes: Quote[];
}

const topCarousel = {
  all: {
    breakpoint: { max: 10000000, min: 0 },
    items: 1
  }
};

export const QuoteCarousel: FC<QuoteCarouselProps> = ({ quotes }) => {
  const carousel = useRef<Carousel | null>(null);

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const handleAfterChange = () => {
    window.heap.track('Carousel Swipe', {
      carouselName: 'Quote Carousel',
      currentStep: carousel?.current?.state?.currentSlide
    });
    setActiveSlideIndex(carousel?.current?.state?.currentSlide);
  };

  const handleMetaClick = (index: number) => {
    carousel?.current?.goToSlide(index);
  };

  return (
    <div className={styles.container}>
      <div className={styles.carouselWrapper}>
        <ParsleyCarousel
          ref={carousel}
          responsive={topCarousel}
          afterChange={handleAfterChange}
          leftArrowClass={styles.leftButton}
          rightArrowClass={styles.rightButton}
          containerClass={styles.quoteCarouselContainer}
          arrowOutlined
        >
          {quotes.map((quote, i) => {
            return (
              <div
                className={clsx(styles.quoteCarouselItem, 'w-full')}
                key={`${quote.id}-${i}`}
              >
                <QuoteComponent {...quote} />
              </div>
            );
          })}
        </ParsleyCarousel>
      </div>
      <Row
        cols={[23, 7]}
        gap={0}
        bordered="always"
        className={styles.quoteMetaRow}
      >
        {quotes.map(({ meta: { name, dateJoined } }, index) => {
          const isActive = index === activeSlideIndex;
          return (
            <Column
              key={index}
              className={clsx(styles.quoteMeta, 'flex-center')}
              data-active={isActive}
              onClick={() => handleMetaClick(index)}
              role="button"
              offset={index === 0 ? 2 : 0}
            >
              <QuoteMeta
                name={name}
                dateJoined={dateJoined}
                active={isActive}
              />
            </Column>
          );
        })}
      </Row>
      <Row cols={[23, 15]} offset={[1, 5]} className="mt-16">
        <Column>
          <CarouselStepIndicator
            currentStep={activeSlideIndex}
            totalSteps={quotes.length}
            primaryColor="dark-green"
            accentColor="clover"
            className="hidden-desktop flex"
          />
        </Column>
      </Row>
    </div>
  );
};
