import React, { FC } from 'react';
import clsx from 'clsx';

import { Text, HighlightedText } from '../..';
import styles from './quote-meta.module.css';

export interface QuoteMetaProps {
  name: string;
  dateJoined?: string;
  active?: boolean;
}

interface ConditionalHighlightedTextProps {
  condition: boolean;
  children?: any;
}

const ConditionalHighlightedText: React.FC<ConditionalHighlightedTextProps> = ({
  condition,
  children
}) =>
  condition ? (
    <HighlightedText variant="underline2">{children}</HighlightedText>
  ) : (
    <>{children}</>
  );

export const QuoteMeta: FC<QuoteMetaProps> = ({ name, dateJoined, active }) => {
  const color = !active ? 'light-text' : 'utility-black';
  return (
    <div className="flex-center flex-col">
      <Text
        variant="p1a"
        className={clsx('text-center', styles.colorTransition)}
        color={color}
      >
        {name}
      </Text>
      {dateJoined ? (
        <ConditionalHighlightedText condition={active}>
          <Text
            variant="u2"
            className={clsx(styles.highlightText, 'text-center')}
            color={color}
          >
            {dateJoined}
          </Text>
        </ConditionalHighlightedText>
      ) : null}
    </div>
  );
};
