import React from 'react';
import Helmet from 'react-helmet';
import { Layout } from '..';

export const CalendlyEmbedLayout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        ></script>
      </Helmet>
      <Layout>{children}</Layout>
    </>
  );
};

export default CalendlyEmbedLayout;
