import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

interface CloseIconProps extends IconProps {
  variant?: 'solid' | 'outlined';
}

export const CloseIcon: React.FC<CloseIconProps> = ({
  variant = 'outlined',
  ...props
}) => {
  switch (variant) {
    case 'solid':
      return (
        <IconSvg viewBox="0 0 32 32" fill="none" {...props}>
          <path
            d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16Z"
            fill="currentColor"
          />
          <path
            d="m10.666 21.037 10.37-10.37M10.666 10.666l10.37 10.37"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </IconSvg>
      );
    case 'outlined':
      return (
        <IconSvg viewBox="0 0 36 36" fill="none" {...props}>
          <path
            d="M10 25.5564L25.5563 10"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 10L25.5563 25.5563"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </IconSvg>
      );
    default:
      return null;
  }
};
