import React, { FC, ReactElement } from 'react';
import clsx from 'clsx';
import { Row, Column } from '../../atoms/grid';
import { Button } from '../../atoms/button';
import { Text } from '../../atoms/text/text';
import { TextLockup } from '../../atoms/text-lockup';
import './structured.scss';
import { ConditionalWrapper } from '../../atoms/conditional-wrapper';
import { TButtonColor, TButtonVariant } from '../../types';

export interface StructuredColumn {
  id: string;
  heading: string;
  description: string;
  illustration?: React.ReactElement;
}

export type StructuredColumnAlignment = 'center' | 'left' | 'right';
export interface StructuredProps extends React.HTMLAttributes<HTMLDivElement> {
  eyebrow?: string;
  heading?: string;
  description?: string;
  items: Array<StructuredColumn>;
  cta?: {
    label?: string;
    destination?: string;
    ctaTrackingCategory?: string;
    ctaTrackingLabel?: string;
    color?: TButtonColor;
    variant?: TButtonVariant;
  };
  alignment?: StructuredColumnAlignment;
}

export const Structured: FC<StructuredProps> = ({
  eyebrow,
  heading,
  description,
  items,
  cta,
  alignment = 'left',
  className
}) => {
  const classNames = clsx('structured-container', className);

  return (
    <div className={classNames}>
      <div className="structured-intro">
        {eyebrow && (
          <Text className="mb-xxs" color="dark-green" variant="p3b">
            {eyebrow}
          </Text>
        )}
        {heading && (
          <Text
            className={description ? 'mb-sm' : 'mb-lg'}
            color="dark-green"
            variant="h2a"
          >
            {heading}
          </Text>
        )}
        {description && (
          <Text className="mb-lg" color="dark-green" variant="p2a">
            {description}
          </Text>
        )}
      </div>
      <Row cols={[23, 7]} bordered="always">
        {items.map(({ heading, description, illustration, id }, index) => (
          <Column key={`${id}-${index}`} offset={index === 0 ? [1, 2] : [1, 0]}>
            <ConditionalWrapper
              condition={Boolean(cta?.destination)}
              wrapper={(children: ReactElement) => (
                <a href={cta.destination} className="structured-content">
                  {children}
                </a>
              )}
              alternativeWrapper={(children: ReactElement) => (
                <div className="structured-content">{children}</div>
              )}
            >
              <TextLockup alignment={alignment} fullWidth>
                <div className="mb-md">{illustration}</div>
                {heading && (
                  <Text color="dark-green" variant="h6a" className="mb-xxs">
                    {heading}
                  </Text>
                )}
                {description && (
                  <Text color="dark-green" variant="p3a">
                    {description}
                  </Text>
                )}
              </TextLockup>
            </ConditionalWrapper>
          </Column>
        ))}
      </Row>
      {cta && (
        <div className="flex-center mt-lg">
          <Button
            color={cta?.color ?? 'sage'}
            variant={cta?.variant ?? 'filled'}
            size="large"
            destination={cta.destination}
            trackingCategory={cta.ctaTrackingCategory}
            trackingLabel={cta.ctaTrackingLabel}
          >
            {cta.label}
          </Button>
        </div>
      )}
    </div>
  );
};
