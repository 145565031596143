import React from 'react';

import { ArrowIconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const CaretIcon: React.FC<ArrowIconProps> = ({
  direction = 'right',
  ...props
}) => {
  let d: string;

  switch (direction) {
    case 'down':
      d = 'M9 13.5L18 22.5L27 13.5';
      break;
    case 'up':
      d = 'M27 22.5L18 13.5L9 22.5';
      break;
    case 'left':
      d = 'M22.5 9L13.5 18L22.5 27';
      break;
    default:
      d = 'M13.5 27L22.5 18L13.5 9';
  }

  return (
    <IconSvg viewBox="0 0 36 36" fill="none" {...props}>
      <path stroke="currentColor" d={d} />
    </IconSvg>
  );
};
