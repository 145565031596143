import styled from '@emotion/styled';
import { theme } from '@parsleyhealth/cilantro-ui';

const NavItemWrapper = styled.div`
  margin-right: ${theme.space.s}px;
  a {
    color: inherit;
  }
  @media screen and (min-width: ${theme.breakpoints[1]}) {
    margin-right: ${theme.space.l}px;
  }
`;

export default NavItemWrapper;
