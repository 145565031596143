import React, { FC } from 'react';
import { Pie } from 'react-chartjs-2';
import { Text } from '../../atoms/text/text';
import styles from './data-visualization.module.css';

export type PieChartProps = {
  description?: string;
  data?: {
    percentage?: number;
    backgroundColor?: string;
    borderColor?: string;
    diffColor?: string;
    diffBorderColor?: string;
    textPosition?: string;
    archWidth?: number;
  };
};

const getData = (
  percentage: number,
  diffColor = '#FFF',
  bgColor = 'rgba(111,179,159, 0.2)'
) => {
  const diff = 100 - percentage;
  return {
    labels: [] as string[],
    datasets: [
      {
        data: [diff, percentage],
        backgroundColor: [diffColor, bgColor],
        borderWidth: 0
      }
    ]
  };
};

/**
 * Please add here documentation for your component and how to use it
 */
export const PieChart: FC<PieChartProps> = ({ data, description }) => {
  const {
    archWidth,
    percentage,
    diffColor,
    diffBorderColor,
    borderColor,
    backgroundColor,
    textPosition: position = 'bottom'
  } = data || {};
  return (
    <div className={styles.container}>
      <Pie
        data={getData(percentage, diffColor, backgroundColor)}
        options={{
          plugins: {
            tooltip: {
              enabled: false
            },
            legend: {
              display: false
            }
          },
          animation: {
            duration: 200,
            easing: 'easeOutQuart',
            onComplete: function(e: any) {
              this.data.datasets.forEach(dataset => {
                const ctx = e.chart.ctx;
                const metaData = e.chart._metasets[0].data;

                for (let i = 0; i < dataset.data.length; i++) {
                  const arc_width = archWidth ?? 10;
                  const model = metaData[i];
                  const outer_radius = model.outerRadius - arc_width / 2;
                  const start_angle = model.startAngle;
                  const end_angle = model.endAngle;
                  const percentageStrokeColor = borderColor ?? '#6FB39F';
                  const diffStrokeColor = diffBorderColor ?? '#C1E6DB';
                  // draw arc per segment
                  ctx.beginPath();
                  ctx.arc(
                    model.x,
                    model.y,
                    outer_radius,
                    start_angle,
                    end_angle
                  );
                  ctx.lineWidth = arc_width;
                  ctx.strokeStyle =
                    i === 1 ? percentageStrokeColor : diffStrokeColor;
                  ctx.stroke();
                }
              });
            }
          }
        }}
      />
      {position === 'bottom' ? (
        <div className={`pb-xxs ${styles.overlay}`}>
          <Text variant="statLarge" color="dark-green">
            {percentage}%
          </Text>
          <div className={`mt-xxs ${styles.description}`}>
            <Text variant="h7a" color="primary">
              {description}
            </Text>
          </div>
        </div>
      ) : (
        <div className={`pb-xxs ${styles.overlay}`}>
          <div className={`mt-xxs ${styles.description}`}>
            <Text variant="h7a" color="primary">
              {description}
            </Text>
          </div>
          <Text variant="statLarge" color="dark-green">
            {percentage}%
          </Text>
        </div>
      )}
    </div>
  );
};
