import React, { ReactNode } from 'react';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button/button';
import { SanityScheduleCall } from '../../../../graphql-types';
import './schedule-call.scss';

interface ScheduleCallProps
  extends Omit<SanityScheduleCall, 'scheduleCallDescription'> {
  description: ReactNode;
}

export const ScheduleCall = ({
  title,
  description,
  scheduleACallButtonLink
}: ScheduleCallProps) => {
  return (
    <div className="flex align-center flex-col schedule-call-container">
      <div className="flex align-center flex-col schedule-call-wrapper">
        <Text
          variant="widget"
          className="pl-tiny pr-tiny pb-sm schedule-call-title"
        >
          {title}
        </Text>
        <div className="schedule-call-description text-center">
          {typeof description === 'string' ? (
            <Text variant="p2a" className="pb-sm text-center">
              {description}
            </Text>
          ) : (
            <>{description}</>
          )}
        </div>
        <Button
          color="sage"
          trackingLabel="scheduleCallButton"
          destination={scheduleACallButtonLink.destination}
        >
          {scheduleACallButtonLink.text}
        </Button>
      </div>
    </div>
  );
};
