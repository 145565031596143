import React, { FC } from 'react';
import { SanityTextLockup } from 'graphql-types';
import { TextLockup } from '../../atoms/text-lockup';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button';
import { TButtonColor, TButtonSize, TButtonVariant } from '../../types';
import { useSanityTextLockup } from '../../../hooks/use-sanity-text-lockup';

interface TextLockupProps extends SanityTextLockup {}

export const TextLockupModule: FC<TextLockupProps> = props => {
  const {
    eyebrow,
    heading,
    description,
    primaryCta,
    secondaryCta,
    textLockupProps
  } = useSanityTextLockup(props);

  return (
    <TextLockup {...textLockupProps}>
      {eyebrow && (
        <Text
          className="text-lockup-eyebrow"
          color="dark-green"
          variant={eyebrow.variant}
        >
          {eyebrow.content}
        </Text>
      )}
      {heading && (
        <Text
          className="text-lockup-headline"
          color="dark-green"
          variant={heading.variant}
        >
          {heading.content}
        </Text>
      )}
      {description && (
        <Text
          className="text-lockup-description"
          color="dark-green"
          variant={description.variant}
        >
          {description.content}
        </Text>
      )}
      {(primaryCta || secondaryCta) && (
        <div className="text-lockup-buttons-container">
          {primaryCta && (
            <Button
              color={(primaryCta?.color as TButtonColor) || 'darkGreen'}
              variant={(primaryCta?.variant as TButtonVariant) || 'filled'}
              size={primaryCta?.size as TButtonSize}
              destination={primaryCta?.destination}
              trackingLabel={primaryCta?.trackingLabel}
            >
              {primaryCta?.label}
            </Button>
          )}
          {secondaryCta && (
            <Button
              color={(secondaryCta?.color as TButtonColor) || 'white'}
              variant={(secondaryCta?.variant as TButtonVariant) || 'outlined'}
              size={secondaryCta?.size as TButtonSize}
              destination={secondaryCta?.destination}
              trackingLabel={secondaryCta?.trackingLabel}
            >
              {secondaryCta?.label}
            </Button>
          )}
        </div>
      )}
    </TextLockup>
  );
};
