import React from 'react';

export const CookPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.546 9.74207C38.7549 9.28248 40.0524 9.10338 41.3406 9.21829C42.3088 9.30465 43.2528 9.55538 44.1326 9.95749C41.1608 10.3983 38.1174 12.9094 36.3889 16.342L36.3885 16.3419L36.3829 16.3546L27.4528 36.5155L26.9672 36.2236L32.4301 15.1717C32.8163 13.9398 33.4767 12.8115 34.3617 11.8715C35.2482 10.9298 36.337 10.2017 37.546 9.74207ZM27.9387 36.8075L29.6595 37.8419L43.3145 20.4563C45.6429 17.1647 46.2819 13.1398 44.988 10.5253L44.9827 10.5146L44.9829 10.5145C44.9746 10.4956 44.9607 10.4797 44.9431 10.4688C44.9256 10.4579 44.9051 10.4526 44.8845 10.4535L44.8809 10.4537C41.9639 10.5453 38.7084 12.991 36.8944 16.5888L27.9387 36.8075ZM30.1454 38.134L30.6302 38.4254L46.6623 23.7277C47.5689 22.8086 48.2553 21.6959 48.6702 20.4734C49.0858 19.2486 49.2179 17.9455 49.0566 16.6623C48.8954 15.379 48.4449 14.1491 47.7392 13.0653C47.2086 12.2504 46.5441 11.5341 45.7759 10.9454C46.7893 13.7752 45.9929 17.6474 43.7696 20.7871L43.7616 20.7985L43.7613 20.7983L30.1454 38.134ZM47.0477 24.1377L31.133 38.7276L31.1736 38.7521L31.1723 38.7543L32.5443 39.5791L32.2544 40.0613L30.8825 39.2365L21.2494 55.2622L21.1045 55.5033L20.8634 55.3584L16.7203 52.8679L16.4792 52.723L16.6242 52.4819L26.2574 36.4559L24.8824 35.6293L25.1723 35.1471L26.4638 35.9236L31.8873 15.0237L31.8908 15.0103L31.8911 15.0104C32.3025 13.6947 33.0072 12.4895 33.9521 11.4858C34.897 10.4821 36.0575 9.7061 37.3461 9.21623C38.6346 8.72637 40.0175 8.53548 41.3906 8.65796C42.7637 8.78043 44.091 9.21308 45.2725 9.92326L45.234 9.98731L45.2341 9.98733L45.2726 9.9233C46.4539 10.6338 47.4585 11.6032 48.2106 12.7583C48.9628 13.9135 49.4429 15.2244 49.6148 16.5921C49.7867 17.9599 49.6458 19.3488 49.2029 20.6541C48.76 21.9595 48.0265 23.1473 47.0577 24.128L47.0479 24.1379L47.0477 24.1377ZM26.7395 36.7458L17.2512 52.5307L20.9122 54.7313L30.4003 38.9466L26.7395 36.7458Z"
      fill="currentColor"
    />
  );
};
