// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ImageType {
  ROUND = 'round',
  SQUARE = 'square',
  RECTANGLE = 'rectangle',
  RECTANGLE_XL = 'rectangleXL',
  LARGE = 'large',
  CIRCLE = 'circle',
  SVG = 'svg',
  BLOB_BOTTOM = 'blobBottom'
}

export type ResponsiveImageType = ImageType | Array<ImageType>;

export { ImageType };
