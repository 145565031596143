import React, { FC, MouseEventHandler } from 'react';
import { Button, ButtonProps } from './../button/button';

export interface CTAProps extends ButtonProps {
  label: string;
  destination?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  trackingLabel: string;
  offset?: number;
  renderAs?: FC<ButtonProps>;
}

export const CTA = (props: CTAProps) => {
  const { destination, onClick, renderAs, ...rest } = props;
  const isValidCTA = !!destination || !!onClick;

  const renderCta = () => {
    // Verify if CTA has destination or OnClick
    if (!isValidCTA) {
      return (
        <div>
          <pre>Missing On Click or Destination</pre>
        </div>
      );
    }

    // Verify if it needs to be custom rendered
    if (renderAs) {
      return renderAs(props);
    }

    // Return actual CTA
    return (
      <Button
        {...rest}
        onClick={props.onClick}
        destination={props.destination}
        trackingLabel={props.trackingLabel}
        offset={props.offset}
      >
        {props.label}
      </Button>
    );
  };

  return <div className="cta">{renderCta()}</div>;
};
