import { useStaticQuery, graphql } from 'gatsby';
import { SanityHiltonPost } from 'graphql-types';

interface AllHiltonPosts {
  hiltonPosts: SanityHiltonPost[];
}

export const useHiltonPosts = (): AllHiltonPosts => {
  const data = useStaticQuery(
    graphql`
      query allHiltonOnlyPostsQuery {
        allSanityHiltonPost {
          nodes {
            title
            id
            slug {
              current
            }
          }
        }
      }
    `
  );
  return {
    hiltonPosts: data?.allSanityHiltonPost?.nodes
  };
};
