const breakpoints = ['sm', 'md'];

export const useBorder = () => {
  const getBorderSide = (border: string) => {
    switch (border) {
      case 'top':
        return 'border-top';
      case 'right':
        return 'border-right';
      case 'bottom':
        return 'border-bottom';
      case 'left':
        return 'border-left';
      default:
        return '';
    }
  };

  const getBaseClassName = (bordersSplitted: string[]) => {
    return bordersSplitted
      .map(border => {
        return getBorderSide(border);
      })
      .join(' ');
  };

  const getBorderClasses = (borders: string | string[] = '') => {
    if (!Array.isArray(borders)) {
      if (borders.length !== 0) {
        return getBaseClassName(borders.split(' '));
      } else {
        return '';
      }
    }

    return borders.reduce((acc, item, index) => {
      const breakpoint = breakpoints[index];
      const baseClassArray = getBaseClassName(item.split(' ')).split(' ');
      const finalClass = baseClassArray.map(className => {
        return `${className}-${breakpoint}`;
      });
      return `${acc} ${finalClass.join(' ')}`;
    }, '');
  };

  return { getBorderClasses };
};
