import React from 'react';

export const ResultsPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.5383 12.2281L45.5975 12.5031L46.5695 17.0171L46.0196 17.1355L45.1068 12.8965L40.8698 13.8093L40.7513 13.2593L45.2633 12.2873L45.5383 12.2281ZM11.4687 51.4384V13.2944H12.0313V51.1571H53.05V51.7196H11.75H11.4687V51.4384ZM19.9424 37.7399V47.1839H19.3799V37.7399H19.9424ZM28.5987 47.1839V40.0039H28.0361V47.1839H28.5987ZM37.2549 31.6041V47.1841H36.6924V31.6041H37.2549ZM45.9112 47.1839V22.9259H45.3486V47.1839H45.9112ZM44.7671 13.2944C44.519 13.1619 44.519 13.162 44.5189 13.1621L44.5186 13.1626L44.5172 13.1652L44.5114 13.1761L44.4876 13.2198C44.4665 13.2587 44.4346 13.3167 44.3924 13.3925C44.308 13.544 44.1821 13.7667 44.0166 14.0501C43.6857 14.6168 43.197 15.4256 42.567 16.3922C41.3065 18.326 39.4825 20.8876 37.227 23.4026C32.6989 28.4515 26.5069 33.2294 19.6902 32.5206L19.632 33.0801C26.7414 33.8193 33.1023 28.8442 37.6458 23.7782C39.926 21.2356 41.7673 18.6492 43.0383 16.6994C43.674 15.7241 44.1675 14.9073 44.5024 14.3337C44.6699 14.0469 44.7978 13.8209 44.8839 13.6662C44.927 13.5889 44.9596 13.5293 44.9816 13.489L45.0066 13.443L45.013 13.4311L45.0146 13.428L45.0151 13.4271C45.0152 13.4269 45.0153 13.4268 44.7671 13.2944Z"
      fill="currentColor"
    />
  );
};
