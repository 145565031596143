const breakpoints = [null, 'md', 'lg'];

const useResponsiveProps = () => {
  const isBoolean = (value: any): boolean => {
    return typeof value === 'boolean';
  };

  const getBaseClassName = (prop: any, preffix: string) => {
    return isBoolean(prop)
      ? `${preffix}-${Number(prop)}`
      : `${preffix}-${prop}`;
  };

  // This is the function that should be used for the responsive mixin classes
  const getPropV2 = (prop: any, preffix: string) => {
    if (!Array.isArray(prop)) {
      return getBaseClassName(prop, preffix);
    }

    return prop.reduce((acc, item, index) => {
      const breakpoint = breakpoints[index];
      const base = getBaseClassName(item, preffix);
      const className = breakpoint === null ? base : `${base}-${breakpoint}`;
      return `${acc} ${className}`;
    }, '');
  };

  return { getPropV2 };
};

export default useResponsiveProps;
