import React, { FC, ReactElement } from 'react';
import { Link } from 'gatsby';
import { ImageType } from '../../atoms/image/types';
import { Image, Text, Tag } from '../../';
import { useAnimateInView } from '../../../hooks/use-animate-inview';

import './provider-card.scss';
import { PapiProvider } from 'graphql-types';

export interface ProviderCardProps extends Partial<PapiProvider> {}

export const ProviderCard: FC<ProviderCardProps> = ({
  sanity,
  firstName,
  lastName,
  isTakingNewPatients = false
}): ReactElement => {
  const { ref, animated } = useAnimateInView();
  return (
    <Link to={`/team/${sanity?.slug?.current}`}>
      <div className="provider-card">
        <div className="provider-card-image-badge-container" ref={ref}>
          {isTakingNewPatients && (
            <Tag className="provider-card-badge" variant="mint">
              Accepting patients
            </Tag>
          )}
          <Image
            {...sanity?.profileImage}
            blob
            type={ImageType.RECTANGLE_XL}
            className={animated && 'fade-in-image'}
            mask={{ color: 'dark-green', removeOnHover: true }}
          />
        </div>
        <div className="provider-card-content-container">
          <Text variant="u1a">{sanity?.role}</Text>
          <Text
            variant="h7a"
            className="text-center"
          >{`${firstName} ${lastName}`}</Text>
        </div>
      </div>
    </Link>
  );
};
