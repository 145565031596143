import React, { FC, ReactNode } from 'react';
import { Row, Column } from '../../atoms/grid';
import { Text } from '../..';

interface LeftColProps {
  content: ReactNode;
}

interface RightColProps {
  statistics: string;
  content: string;
  quote?: ReactNode;
}

interface StatisticStructuredBoxProps {
  leftCol: LeftColProps;
  rightCol: RightColProps;
}

export const StatisticStructuredBox: FC<StatisticStructuredBoxProps> = ({
  leftCol,
  rightCol
}) => {
  return (
    <Row bordered="always">
      <Column cols={[23, 13]} className="py-xxl pl-xl pr-xl" offset={1}>
        {typeof leftCol.content === 'string' ? (
          <Text className="text-center-sm" variant="h4a">
            {leftCol.content}
          </Text>
        ) : (
          <>{leftCol.content}</>
        )}
      </Column>
      <Column cols={[23, 10]} className="p-lg" offset={[1, 0]}>
        <Text variant="statSmall" className="mb-tiny text-center-sm block">
          {rightCol.statistics}
        </Text>
        <Text variant="p2a" className="mb-tiny text-center-sm">
          {rightCol.content}
        </Text>
        {rightCol.quote && typeof rightCol.quote === 'string' ? (
          <Text variant="u3a" color="light-text" className="text-center-sm">
            {rightCol.quote}
          </Text>
        ) : (
          <>{rightCol.quote}</>
        )}
      </Column>
    </Row>
  );
};
