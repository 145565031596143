import { useState } from 'react';

export const useCarouselStep = (steps: number, initialStep = 0) => {
  if (initialStep < 0) {
    console.warn('The initial step should be 0 or greater');
    initialStep = 0;
  }

  const [carouselStep, setCarouselStep] = useState(initialStep);

  if (steps <= 1) {
    throw new Error('The number of steps should be greater than 1');
  }

  const isFirstStep = carouselStep === 0;
  const isLastStep = carouselStep === steps - 1;

  const nextStep = (): void => {
    if (carouselStep + 1 < steps) {
      setCarouselStep(carouselStep + 1);
    }
  };

  const previousStep = (): void => {
    if (carouselStep - 1 >= 0) {
      setCarouselStep(carouselStep - 1);
    }
  };

  const goToStep = (targetStep: number): void => {
    if (targetStep >= 0 && targetStep < steps) {
      setCarouselStep(targetStep);
    }
  };

  const currentStep = carouselStep;

  return {
    currentStep,
    isFirstStep,
    isLastStep,
    nextStep,
    previousStep,
    goToStep
  };
};

export default useCarouselStep;
