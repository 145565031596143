import React from 'react';

export const DoctorPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3941 14.389H18.6754H22.2354V14.9516H18.9567V15.514V15.514V27.4003C18.9567 30.703 20.2686 33.8704 22.604 36.2057C24.8725 38.4743 27.9263 39.7772 31.1264 39.8498L31.4093 40.4156C31.1144 40.4156 30.8204 40.4056 30.5279 40.3857V48.8163C30.5279 50.5862 31.2309 52.2837 32.4823 53.5354C33.7335 54.787 35.4306 55.4904 37.2004 55.4911C38.9687 55.4885 40.6637 54.7841 41.9131 53.5326C43.0835 52.3602 43.773 50.7974 43.8551 49.1507C42.1518 49.0042 40.815 47.5751 40.815 45.8339C40.815 43.9951 42.3056 42.5046 44.1443 42.5046C45.983 42.5046 47.4736 43.9951 47.4736 45.8339C47.4736 47.5803 46.1288 49.0127 44.4183 49.152C44.3354 50.9474 43.5866 52.6524 42.3112 53.93C40.9565 55.2871 39.1185 56.0508 37.2011 56.0536H37.2006C35.2815 56.0531 33.4412 55.2903 32.0844 53.9331C30.7276 52.5759 29.9654 50.7354 29.9654 48.8163V40.3353C27.0448 40.0093 24.3042 38.7015 22.2062 36.6035C19.7654 34.1627 18.3941 30.8522 18.3941 27.4003V14.6703V14.389ZM31.1265 39.8498L31.1264 39.8498L18.9567 15.514L31.1265 39.8498ZM31.1265 39.8498C31.2206 39.852 31.315 39.853 31.4094 39.853V40.1373L31.4094 39.853C33.0448 39.8533 34.6642 39.5314 36.1752 38.9057C37.6861 38.2799 39.059 37.3627 40.2154 36.2063C41.3718 35.0499 42.289 33.677 42.9147 32.1661C43.5404 30.6552 43.8624 29.0357 43.8621 27.4004V27.4003V14.9516H40.5854V14.389H44.1434H44.4247V14.6703V27.4003V27.4003C44.4249 29.1096 44.0885 30.8021 43.4345 32.3813C42.7805 33.9606 41.8218 35.3955 40.6132 36.6041C39.4045 37.8127 37.9696 38.7714 36.3904 39.4254C34.8112 40.0794 33.1186 40.4159 31.4094 40.4156L31.1265 39.8498ZM41.3776 45.8339C41.3776 44.3058 42.6163 43.0671 44.1443 43.0671C45.6723 43.0671 46.911 44.3058 46.911 45.8339C46.911 47.3619 45.6723 48.6006 44.1443 48.6006C42.6163 48.6006 41.3776 47.3619 41.3776 45.8339Z"
      fill="currentColor"
    />
  );
};
