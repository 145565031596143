import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { HalfAndHalf, HalfAndHalfProps } from '../half-and-half/half-and-half';
import { Button, ContinuedTextList } from '../..';
import { PlusIcon, MinusIcon } from '../../molecules/icons';
import { useExpandFaq } from '../../../hooks/use-expand-faq';
import { Text } from '../../atoms/text/text';
import { Column, Row } from '../../atoms/grid';
import styles from './conditions-collapsible.module.css';
import { TextLockup } from '../../atoms/text-lockup';
import useResponsiveProps from '../../../hooks/use-responsive-props';

export type ConditionItem = {
  id: string;
  name: string;
  description: ReactNode;
  symptoms?: string | string[];
};

type ctaType = {
  label: string;
  destination: string;
  ctaTrackingLabel: string;
};

export type ConditionCollapsibleProps = {
  conditionsArray: ConditionItem[];
  halfAndHalfProps: HalfAndHalfProps;
  conditionsSubtitle?: string;
  initialExpanded?: number;
  showMobileImage?: boolean;
  eyebrow?: string;
  heading?: ReactNode;
  description?: string;
  cta?: ctaType;
};

// `ConditionsCollapsible` needs a refactor to be able to receive a textLockup props from sanity directly
// we can do this when all the care pages are migrated to Sanity, otherwise we have to edit all the static content
// TODO: -new prop to get `SanityTextLockup` instead of heading, eyebrow, description, cta
// TODO: -Use the TextLockupModule from organisms/text-lockup
// TODO: -Remove props in this component that are part of HalfAndHalfProps those are duplicated,
//        i.e. mobileHidden, contentPosition, reversed, ...

export const ConditionsCollapsible: FC<ConditionCollapsibleProps> = ({
  conditionsArray,
  halfAndHalfProps,
  conditionsSubtitle,
  initialExpanded = 0,
  showMobileImage = false,
  eyebrow,
  heading,
  description,
  cta
}) => {
  const { currentExpandedFaq, expandItem } = useExpandFaq(
    conditionsArray.length,
    initialExpanded
  );
  const { getPropV2 } = useResponsiveProps();

  const onClickQuestion = (index: number) => () => {
    expandItem(index);
  };

  const conditionsInfoList = conditionsArray.map((condition, index) => {
    const isExpanded = index === currentExpandedFaq;
    const descriptionElementContainer =
      typeof condition.description === 'string' ? 'p' : 'div';
    return (
      <div key={`${condition.id}-${index}`}>
        <Row
          cols={25}
          bordered="onlyX"
          lastRow={index === conditionsArray.length - 1 ? true : false}
        >
          <Column>
            <div
              className={styles.titleContainer}
              onClick={onClickQuestion(index)}
            >
              <Text
                className={clsx('ml-xtiny', 'mr-sm', 'mt-sm', 'mb-sm')}
                variant="h6a"
              >
                {condition.name}
              </Text>
              {isExpanded ? (
                <MinusIcon
                  className={clsx(styles.icon, 'mr-tiny', 'mt-sm', 'mb-sm')}
                  iconWidth="22px"
                />
              ) : (
                <PlusIcon
                  className={clsx(styles.icon, 'mr-tiny', 'mt-sm', 'mb-sm')}
                  iconWidth="22px"
                />
              )}
            </div>
            {isExpanded && (
              <>
                <Text
                  className={clsx('ml-xtiny', 'mr-sm', 'mb-lg')}
                  variant="p3a"
                  htmlTag={descriptionElementContainer}
                >
                  {condition.description}
                </Text>
                {conditionsSubtitle && (
                  <div className={clsx('ml-xtiny', 'mr-sm', 'mb-tiny')}>
                    <Text variant="u2"> {conditionsSubtitle} </Text>
                  </div>
                )}

                {typeof condition.symptoms === 'string' ? (
                  <Text
                    className={clsx('ml-xtiny', 'mr-sm', 'mb-lg')}
                    variant="h8b"
                  >
                    {condition.symptoms}
                  </Text>
                ) : (
                  condition.symptoms && (
                    <ContinuedTextList
                      items={condition.symptoms}
                      className={clsx('ml-xtiny', 'w-pct-2/3', 'mb-lg')}
                    />
                  )
                )}
              </>
            )}
          </Column>
        </Row>
      </div>
    );
  });

  return (
    <div className={getPropV2(['xl', 'big'], 'py')}>
      <TextLockup alignment="center">
        {eyebrow && (
          <Text variant="p3b" className="text-lockup-eyebrow">
            {eyebrow}
          </Text>
        )}
        {heading && (
          <Text
            variant="h2a"
            color="dark-green"
            className="text-lockup-headline mb-md"
          >
            {heading}
          </Text>
        )}
        {description && (
          <Text
            variant="p2a"
            color="dark-green"
            className="text-lockup-description"
          >
            {description}
          </Text>
        )}
      </TextLockup>
      <HalfAndHalf
        {...halfAndHalfProps}
        mobileHidden={!showMobileImage}
        contentPosition="top"
        paddingY={false}
        className={styles.halfAndHalf}
      >
        <div className={styles.fullList}>{conditionsInfoList}</div>
        {cta && (
          <div className="mt-lg">
            <Button
              color="sage"
              destination={cta.destination}
              variant="filled"
              size="large"
              trackingLabel={cta.ctaTrackingLabel}
            >
              {cta.label}
            </Button>
          </div>
        )}
      </HalfAndHalf>
    </div>
  );
};
