import React, { createContext, useState } from 'react';
import { useScrollController } from '../../hooks/use-scroll-controller';

export type BlurMaskContextState = {
  showMask: boolean;
  setMask: (isMaskOpened: boolean) => void;
};

const initialDefault: BlurMaskContextState | undefined = undefined;

export const MaskContext = createContext<BlurMaskContextState | undefined>(
  initialDefault
);

const MaskProvider = ({ children }) => {
  const { avoidScrolling, enableScrolling } = useScrollController();
  const [showMask, setShowMask] = useState<boolean>(false);

  const setMask = (isMaskOpened: boolean) => {
    if (isMaskOpened) {
      enableScrolling();
    } else {
      avoidScrolling();
    }
    setShowMask(isMaskOpened);
  };

  return (
    <MaskContext.Provider value={{ showMask, setMask }}>
      {children}
    </MaskContext.Provider>
  );
};

export default MaskProvider;
