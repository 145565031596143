import React, { useMemo } from 'react';

import { FooterCtaProps } from '../molecules/footer-cta/footer-cta';
import { Footer } from '../organisms/footer/footer';
import { InsightsFooter } from '../organisms/footer/insights-footer';

export type FooterType = 'MAIN_SITE' | 'BLOG' | 'INSIGHTS' | 'B2B' | 'HILTON';

export interface FooterSwitcherProps extends FooterCtaProps {
  footerType: FooterType;
}

const FooterSwitcher: React.FC<FooterSwitcherProps> = ({
  footerType = 'MAIN_SITE',
  footerContentKey
}) => {
  const footer = useMemo(() => {
    const navbarOptions: Record<FooterType, JSX.Element> = {
      BLOG: <Footer footerContentKey={footerContentKey} />,
      HILTON: <Footer footerContentKey={footerContentKey} />,
      MAIN_SITE: <Footer footerContentKey={footerContentKey} />,
      INSIGHTS: <InsightsFooter />,
      B2B: <Footer footerContentKey={footerContentKey} />
    };
    return navbarOptions[footerType];
  }, [footerType, footerContentKey]);

  return <div>{footer}</div>;
};

export default FooterSwitcher;
