import { GridOrientation, OffsetUnit } from '../redesign/types';

interface ImageContentOffset {
  imageOffsetValue?: OffsetUnit;
  contentOffsetValue?: OffsetUnit;
  inset?: boolean;
  reversed: GridOrientation;
}

export const useImageContentOffset = ({
  imageOffsetValue,
  contentOffsetValue,
  inset,
  reversed
}: ImageContentOffset) => {
  let imageOffset = undefined;
  let contentOffset = undefined;

  if (imageOffsetValue !== undefined) {
    imageOffset = imageOffsetValue;
  } else {
    imageOffset = inset ? (reversed ? 1 : [1, 0]) : [1, 2];
  }

  if (contentOffsetValue !== undefined) {
    contentOffset = contentOffsetValue;
  } else {
    contentOffset = inset ? (reversed ? 0 : 1) : [1, 2];
  }

  return [imageOffset, contentOffset];
};
