import { TButtonColor, TButtonVariant, TButtonSize } from 'src/redesign/types';
import { SanityButtonLinkV2 } from '../../graphql-types';

export const useSanityButtonLink = (buttonLink: SanityButtonLinkV2) => {
  return buttonLink && buttonLink.text
    ? {
        label: buttonLink.text,
        variant: buttonLink.variant as TButtonVariant,
        color: buttonLink.color as TButtonColor,
        size: buttonLink.size as TButtonSize,
        destination: buttonLink.destination,
        trackingLabel: buttonLink.trackingName
      }
    : null;
};
