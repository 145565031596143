import React, { FC, useState, ReactNode } from 'react';
import { H4 } from '@ph-bit/design.ui.typography.h4';
import { P2 } from '@ph-bit/design.ui.typography.p2';
import { Textfield } from '@ph-bit/design.ui.inputs.textfield';
import { Textarea } from '@ph-bit/design.ui.inputs.textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import { useForm } from 'react-hook-form';
import { useCaptureSalesLead } from './../../../hooks';

import styles from './employer-form.module.css';
import { Row, Column, Button } from '../..';

export interface EmployerFormProps {
  name: string;
  title?: string;
  description?: ReactNode;
}

export const EmployerForm: FC<EmployerFormProps> = ({
  name,
  title,
  description
}) => {
  const [messageField, setMessageField] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const { submitted, loading, captureSalesLead } = useCaptureSalesLead();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const submitLabel = submitted
    ? 'Submitted'
    : loading
    ? 'Submitting...'
    : 'Submit';
  const isDisabled = loading || submitted || !isChecked;
  const hasTitle = !!title;
  const hasDescription = !!description;

  const onSubmit = (values: any) => {
    const lead = {
      ...values,
      leadSource: name
    };

    if (messageField.length > 0) {
      lead.message = messageField;
    }

    captureSalesLead(lead);
  };

  return (
    <>
      <Row>
        <Column cols={23} offset={1}>
          <div className="max-w-2xl mx-auto">
            {(hasTitle || hasDescription) && (
              <div className="max-w-xl mx-auto mb-6 text-center">
                {hasTitle && <H4 className="mb-2 mx-auto">{title}</H4>}
                {hasDescription && description}
              </div>
            )}
            <div className="mb-6">
              <form id="form" onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formRow}>
                  <Textfield
                    name="firstName"
                    label="First Name"
                    placeholder="First name"
                    required
                    {...register('firstName')}
                    validationMessage={errors.firstName?.message as string}
                    className="mb-6"
                  />
                  <Textfield
                    name="lastName"
                    label="Last Name"
                    placeholder="Last name"
                    required
                    {...register('lastName')}
                    validationMessage={errors.lastName?.message as string}
                    className="mb-6"
                  />
                </div>
                <div className={styles.formRow}>
                  <Textfield
                    name="company"
                    label="Company Name"
                    required
                    placeholder="Company Name"
                    {...register('company')}
                    validationMessage={errors.company?.message as string}
                    className="mb-6"
                  />
                  <Textfield
                    name="email"
                    label="Email"
                    required
                    placeholder="email@parsley.com"
                    {...register('email')}
                    validationMessage={errors.email?.message as string}
                    className="mb-6"
                  />
                </div>
                <div className={styles.formRow}>
                  <Textfield
                    name="title"
                    label="Job Title"
                    required
                    placeholder="Job Title"
                    {...register('title')}
                    validationMessage={errors.title?.message as string}
                    className="mb-6"
                  />
                  <Textfield
                    name="phone"
                    label="Phone Number"
                    required
                    placeholder="+12345678901"
                    {...register('phone')}
                    validationMessage={errors.phone?.message as string}
                    className="mb-6"
                    title="The phone number should look like this +12345678901"
                  />
                </div>
                <Textarea
                  name="message"
                  label="Message"
                  placeholder="Message"
                  {...register('message')}
                  className="mb-6"
                  value={messageField}
                  onChange={e => setMessageField(e.target.value)}
                />
                <div className="flex-center mb-6 flex-col">
                  <P2 className="mb-4">
                    Parsley Health is committed to protecting and respecting
                    your privacy, and we’ll only use your personal information
                    to administer your account and to provide the products and
                    services you requested from us. From time to time, we would
                    like to contact you about our products and services, as well
                    as other content that may be of interest to you. If you
                    consent to us contacting you for this purpose, please tick
                    below to say how you would like us to contact you:
                  </P2>
                  <div className="mb-4 flex flex-row mr-auto">
                    <input
                      type="checkbox"
                      className="mr-2"
                      checked={isChecked}
                      onClick={() => setIsChecked(!isChecked)}
                    />
                    <P2>
                      I agree to receive other communications from Parsley
                      Health.
                    </P2>
                  </div>

                  <P2 className="mb-4">
                    You can unsubscribe from these communications at any time.
                    For more information on how to unsubscribe, our privacy
                    practices, and how we are committed to protecting and
                    respecting your privacy, please review our Privacy Policy.
                  </P2>
                  <P2 className="mb-4">
                    By clicking submit below, you consent to allow Parsley
                    Health to store and process the personal information
                    submitted above to provide you the content requested.
                  </P2>
                </div>
                <div className="flex-center">
                  <Button type="submit" trackingLabel="" disabled={isDisabled}>
                    {submitLabel}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Column>
      </Row>
    </>
  );
};
