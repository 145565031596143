import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { Image, Text } from '../..';
import { ImageProps } from '../../types';
import { ImageType } from '../../atoms/image/types';
import styles from './quote.module.css';

export interface QuoteProps {
  image?: ImageProps;
  quote: ReactNode;
  id?: number;
}

export const Quote: FC<QuoteProps> = ({ quote, image }) => {
  return (
    <div
      className={clsx(
        styles.quote,
        'flex',
        'flex-col',
        'flex-center',
        'w-full'
      )}
    >
      {image ? (
        <div className={styles.quoteImage}>
          <Image type={ImageType.ROUND} blob {...image} />
        </div>
      ) : null}
      <Text variant="h6a" color="primary" className={styles.quoteText}>
        {quote}
      </Text>
    </div>
  );
};
