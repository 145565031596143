import React from 'react';

export const TestingPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.096 14.808H27.115V21.6892H36.8847V14.808H34.9033V14.2455H36.8847H37.166H48.904H49.1853H51.1653V14.808H49.1853V21.6892H51.166V22.2518H49.1853V44.4008V44.4009C49.1847 46.0316 48.5367 47.5953 47.3836 48.7484C46.2306 49.9015 44.6668 50.5495 43.0361 50.55L43.036 50.2688V50.55C41.405 50.55 39.8407 49.9023 38.6871 48.7491C37.5336 47.596 36.8853 46.0319 36.8847 44.4009L37.1632 44.4008H36.8847V22.2518H27.115V44.4008V44.4009C27.1144 46.0316 26.4664 47.5953 25.3133 48.7484C24.1602 49.9015 22.5965 50.5495 20.9658 50.55L20.9657 50.2688V50.55C19.3346 50.55 17.7704 49.9023 16.6168 48.7491C15.4633 47.596 14.815 46.0319 14.8144 44.4009L15.0929 44.4008H14.8144V22.2518H12.834V21.6892H14.8144V14.808H12.834V14.2455H14.8144H15.0957H26.8337H27.115H29.096V14.808ZM15.377 22.2518V30.9075H26.5524V22.2518H15.377ZM26.5524 21.6892H15.377V14.808H26.5524V21.6892ZM37.4473 22.2518V36.545H48.6227V22.2518H37.4473ZM48.6227 21.6892V14.808H37.4473V21.6892H48.6227ZM15.377 31.47V44.4007V44.4008C15.3775 45.8826 15.9665 47.3036 17.0146 48.3513C18.0626 49.3989 19.4837 49.9875 20.9656 49.9875L15.377 44.4007L20.9657 49.9875C22.4472 49.987 23.8679 49.3982 24.9155 48.3506C25.9631 47.303 26.5519 45.8823 26.5524 44.4008V44.4007V31.47H15.377ZM37.4473 44.4007V37.1076H48.6227V44.4007V44.4008C48.6222 45.8823 48.0334 47.303 46.9858 48.3506C45.9382 49.3982 44.5175 49.987 43.036 49.9875L37.4473 44.4007ZM37.4473 44.4007L43.0359 49.9875C41.5541 49.9875 40.1329 49.3989 39.0849 48.3513C38.0369 47.3036 37.4478 45.8826 37.4473 44.4008V44.4007Z"
      fill="currentColor"
    />
  );
};
