import { useEffect, useMemo, useRef, useState } from 'react';

export const useStringItemHeight = (
  items: Record<string, any>[],
  key: string
) => {
  const [height, setHeight] = useState<number>();
  const maxRef = useRef<HTMLDivElement>(null);

  const itemsObj = useMemo(
    () =>
      items?.reduce(
        (acc, item, index) => {
          const length = item[key]?.length;
          acc[length] = index;
          acc.max = Math.max(acc.max, length);
          return acc;
        },
        { max: 0 }
      ) || [],
    [items, key]
  );

  useEffect(() => {
    if (maxRef.current) {
      setHeight(maxRef.current.getBoundingClientRect().height);
    }
  }, [maxRef]);

  return { maxRef, maxIndex: itemsObj[itemsObj.max], height };
};
