import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const StarIcon: React.FC<IconProps> = ({
  strokeWidth = 3,
  ...props
}) => {
  return (
    <IconSvg viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M14.9652 5.67592C14.8762 5.39253 14.6307 5.19263 14.3462 5.17185L9.83388 4.85156L8.149 0.461798C8.04286 0.183071 7.78572 0 7.49983 0C7.21428 0 6.95749 0.183071 6.85135 0.461798L5.16476 4.85156L0.652446 5.17149C0.368265 5.19263 0.122089 5.39218 0.0340961 5.67556C-0.0538972 5.96038 0.0320418 6.27206 0.250142 6.46445L3.72194 9.49891L2.61843 14.0889C2.54892 14.3791 2.65541 14.6822 2.88583 14.8592C3.11694 15.0351 3.42612 15.0459 3.6699 14.8886L7.49949 12.3711L11.3294 14.8889C11.4445 14.9631 11.5749 15 11.703 15C11.8461 15 11.9909 14.9506 12.1138 14.8596C12.3446 14.6826 12.4511 14.3791 12.3806 14.0893L11.2781 9.49927L14.7485 6.46481C14.968 6.27242 15.0546 5.96074 14.9652 5.67592Z"
        fill="#4F7F71"
      />
    </IconSvg>
  );
};
