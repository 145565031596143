import React, { FC } from 'react';
import { PieChart } from './pie';

export type DataVisualizationProps = {
  type?: 'pie';
  description?: string;
  data: {
    percentage?: number;
    backgroundColor?: string;
    borderColor?: string;
    diffColor?: string;
    diffBorderColor?: string;
    textPosition?: string;
    archWidth?: number;
  };
};

/**
 * Please add here documentation for your component and how to use it
 */
export const DataVisualization: FC<DataVisualizationProps> = ({
  type = 'pie',
  description,
  data
}) => {
  if (type === 'pie') {
    return <PieChart description={description} data={data} />;
  }
  return <></>;
};
