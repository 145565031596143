import React, { FC } from 'react';
import { ImageProps } from '../image/image';
import { ImageType } from '../image/types';

interface ShapeMaskProps extends Pick<ImageProps, 'blob'> {
  type: ImageType;
  svgRefId: string;
}

const getTypeData = (type: string) => {
  const SVGObj = {
    id: type,
    viewBox: '',
    path: ''
  };

  switch (type) {
    case ImageType.ROUND: {
      SVGObj.path =
        'M1,0.501 C1,0.775,0.842,1,0.502,0.998 C0.162,0.97,0.043,0.788,0.004,0.501 C-0.035,0.214,0.207,-0.034,0.502,0.004 C0.772,0.039,1,0.226,1,0.501';
      break;
    }

    case ImageType.SQUARE: {
      SVGObj.path =
        'M0.999,0.43 C0.986,0.782,0.912,1,0.485,0.988 C-0.019,0.903,-0.011,0.676,0.005,0.26 C0.015,0.013,0.22,0.042,0.523,0.007 C0.899,-0.038,1,0.141,0.999,0.43';
      break;
    }

    case ImageType.RECTANGLE_XL: {
      SVGObj.path =
        'M0.999,0.43 C0.986,0.782,0.912,1,0.485,0.988 C-0.019,0.903,-0.011,0.676,0.005,0.26 C0.015,0.013,0.22,0.042,0.523,0.007 C0.899,-0.038,1,0.141,0.999,0.43';
      break;
    }

    case ImageType.RECTANGLE: {
      SVGObj.path =
        'M0.999,0.43 C0.986,0.782,0.912,1,0.485,0.988 C-0.019,0.903,-0.011,0.676,0.005,0.26 C0.015,0.013,0.22,0.042,0.523,0.007 C0.899,-0.038,1,0.141,0.999,0.43';
      break;
    }
    case ImageType.BLOB_BOTTOM: {
      SVGObj.path =
        'M0.999,0.73 C0.986,0.782,0.912,1,0.61 0.988 C-0.119,0.993,-0.011,0.676 0 0 C 0 0 0 0 1 0 C 1 0 1 0 0.999,0.43';
      break;
    }
    default:
      SVGObj.path =
        'M0.999,0.43 C0.986,0.782,0.912,1,0.485,0.988 C-0.019,0.903,-0.011,0.676,0.005,0.26 C0.015,0.013,0.22,0.042,0.523,0.007 C0.899,-0.038,1,0.141,0.999,0.43';
      break;
  }
  return SVGObj;
};

export const ShapeMask: FC<ShapeMaskProps> = ({
  svgRefId,
  blob,
  type = ImageType.RECTANGLE
}) => {
  const isRoundSVG = (type as ImageType) === ImageType.ROUND && !blob;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={0}
      width={0}
    >
      <defs>
        <clipPath id={svgRefId} clipPathUnits="objectBoundingBox">
          {isRoundSVG ? (
            <circle r="0.5" cx="0.5" cy="0.5" />
          ) : blob ? (
            <path
              d={getTypeData(type).path}
              preserveAspectRatio="xMidYMid slice"
            />
          ) : (
            <rect width="1" height="1" />
          )}
        </clipPath>
      </defs>
    </svg>
  );
};
