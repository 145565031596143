/* eslint-disable prettier/prettier */
import React from 'react';
import clsx from 'clsx';
import styles from './parsley-carousel.module.css';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { ArrowIcon } from './../../molecules/icons';

export interface ParsleyCarouselProps extends CarouselProps {
  mobileArrows?: boolean;
  leftArrowClass?: string;
  rightArrowClass?: string;
  arrowSize?: string;
  arrowSpacing?: 'xtiny' | 'md';
  arrowOutlined?: boolean;
}

export const ParsleyCarousel = React.forwardRef<Carousel, ParsleyCarouselProps>(
  (
    {
      mobileArrows = true,
      containerClass,
      children,
      responsive,
      leftArrowClass,
      rightArrowClass,
      arrowSize = '48',
      arrowSpacing = 'md',
      arrowOutlined = false,
      ...props
    },
    ref
  ) => {
    const LeftCustomArrow = (arrowProps: any) => {
      const { carouselState, children, ...restArrowProps } = arrowProps;

      return (
        <button
          className={clsx(
            styles.leftButton,
            leftArrowClass,
            `left-${arrowSpacing}`
          )}
          {...restArrowProps}
        >
          <ArrowIcon
            iconWidth={arrowSize}
            direction="left"
            outlined={arrowOutlined}
            circled
          />
        </button>
      );
    };

    const RightCustomArrow = (arrowProps: any) => {
      const { carouselState, children, ...restArrowProps } = arrowProps;

      return (
        <button
          className={clsx(
            styles.rightButton,
            rightArrowClass,
            `right-${arrowSpacing}`
          )}
          {...restArrowProps}
        >
          <ArrowIcon
            iconWidth={arrowSize}
            direction="right"
            outlined={arrowOutlined}
            circled
          />
        </button>
      );
    };

    return (
      <Carousel
        ref={ref}
        responsive={responsive}
        containerClass={clsx(containerClass, {
          [styles.mobileArrows]: mobileArrows,
        })}
        customRightArrow={<RightCustomArrow />}
        customLeftArrow={<LeftCustomArrow />}
        {...props}
      >
        {children}
      </Carousel>
    );
  }
);
