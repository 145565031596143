import React, { FC } from 'react';

import { Text, Image, Row, Column } from '../../';
import { ImageType } from '../../atoms/image/types';

interface BlockContentImageProps {
  node: {
    source?: string;
  };
}

export const BlockContentImage: FC<BlockContentImageProps> = props => {
  const { node } = props;
  return (
    <Row cols={[19, 11]} offset={[3, 7]}>
      <Column className="mb-xxl">
        <Image type={ImageType.RECTANGLE_XL} {...node} />
        <div className="mt-sm text-center">
          {node.source && (
            <Text variant="u1a">
              Source: <span className="text-clover">{node.source}</span>
            </Text>
          )}
        </div>
      </Column>
    </Row>
  );
};
