import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './insights-footer.module.css';
import { FooterCta } from '../../molecules/footer-cta/footer-cta';
import { FooterCtaProps } from '../../types';

export const InsightsFooter: FC<FooterCtaProps> = ({
  positionStatic = false
}) => {
  return (
    <div
      className={clsx(
        styles.insightsFooterWrapper,
        'bg-dark-green',
        positionStatic && styles.positionStatic
      )}
    >
      <FooterCta footerContentKey="insights" />
    </div>
  );
};
