import React, { FC, ReactNode } from 'react';
import { ImageType } from '../../atoms/image/types';
import { Text, Link, Image } from '../..';

import { ImageProps } from '../../types';

import './press-card.scss';

export type PressCardProps = {
  image: ImageProps;
  heading: string;
  description: ReactNode;
  cta?: {
    destination: string;
    text: string;
    openInNewTab: boolean;
  };
};

export const PressCard: FC<PressCardProps> = ({
  image,
  heading,
  description,
  cta
}) => {
  return (
    <div className="text-center">
      <Image {...image} type={ImageType.SQUARE} className="press-logo" />
      <Text className="press-heading" variant="h6a">
        {heading}
      </Text>
      <Text variant="p3a" className="press-description">
        {description}
      </Text>
      <Link
        variant="p3a"
        color="sage"
        destination={cta.destination}
        underline
        openInNewTab={cta.openInNewTab}
      >
        {cta.text}
      </Link>
    </div>
  );
};
