import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const TodayIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="-18 0 84 36" fill="none" {...props}>
      <path
        d="M8.94845 27.8447H6.0362V35.4194H3.49282V27.8447H0.580566V25.6569H8.94845V27.8447Z"
        fill="currentColor"
      />
      <path
        d="M14.2414 33.4361C15.8819 33.4361 16.5111 31.9872 16.5111 30.5371C16.5111 29.0882 15.8819 27.6382 14.2414 27.6382C12.6008 27.6382 11.9716 29.0882 11.9716 30.5371C11.9716 31.9872 12.6008 33.4361 14.2414 33.4361ZM14.2414 25.4515C17.3176 25.4515 19.0545 27.7068 19.0545 30.5371C19.0545 33.3674 17.3176 35.6239 14.2414 35.6239C11.1652 35.6239 9.42822 33.3674 9.42822 30.5371C9.42822 27.7068 11.1652 25.4515 14.2414 25.4515Z"
        fill="currentColor"
      />
      <path
        d="M22.706 33.2316H23.909C25.8365 33.1906 26.603 32.4385 26.603 30.4833C26.603 28.7054 25.6459 27.8447 23.909 27.8447H22.706V33.2316ZM20.1626 25.6569H24.3598C27.3541 25.6569 29.1453 27.4747 29.1453 30.4966C29.1453 33.6969 27.3275 35.4194 24.3598 35.4194H20.1626V25.6569Z"
        fill="currentColor"
      />
      <path
        d="M33.085 31.7827H35.3004L34.2204 28.3642H34.1927L33.085 31.7827ZM32.921 25.6569H35.5054L39.1565 35.4194H36.5034L35.9019 33.6692H32.4978L31.8819 35.4194H29.271L32.921 25.6569Z"
        fill="currentColor"
      />
      <path
        d="M43.5752 31.7827V35.4194H41.0318V31.7827L37.4771 25.6569H40.3616L42.3035 29.6215L44.2454 25.6569H47.1299L43.5752 31.7827Z"
        fill="currentColor"
      />
      <path
        d="M23.8464 16.6649C19.9372 16.6649 16.7568 19.8452 16.7568 23.7545H21.2941C21.2941 22.3465 22.4396 21.2022 23.8464 21.2022C25.2543 21.2022 26.3986 22.3465 26.3986 23.7545H30.9359C30.9359 19.8452 27.7556 16.6649 23.8464 16.6649Z"
        fill="currentColor"
      />
      <path
        d="M23.8448 11.2772C16.9646 11.2772 11.3672 16.8746 11.3672 23.7548H15.9045C15.9045 19.3759 19.467 15.8145 23.8448 15.8145C28.2237 15.8145 31.7851 19.3759 31.7851 23.7548H36.3224C36.3224 16.8746 30.725 11.2772 23.8448 11.2772Z"
        fill="currentColor"
      />
      <path
        d="M23.8461 5.88947C13.995 5.88947 5.98047 13.904 5.98047 23.7551H10.5178C10.5178 16.4053 16.4974 10.4268 23.8461 10.4268C31.196 10.4268 37.1745 16.4053 37.1745 23.7551H41.7118C41.7118 13.904 33.6973 5.88947 23.8461 5.88947Z"
        fill="currentColor"
      />
      <path
        d="M23.8446 0.500977C11.0224 0.500977 0.59082 10.9326 0.59082 23.7547H5.12813C5.12813 13.4339 13.5248 5.03829 23.8446 5.03829C34.1654 5.03829 42.561 13.4339 42.561 23.7547H47.0983C47.0983 10.9326 36.6667 0.500977 23.8446 0.500977Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};
