import React, { FC, useMemo } from 'react';
import clsx from 'clsx';
import styles from './input-pill.module.css';

export type InputPillProps = {
  inputName: string;
  inputType?: string;
  value?: string;
  pattern?: RegExp;
  placeholder?: string;
  CTA?: string;
  onChangeCB?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  wrapperClassName?: string;
  inputClasName?: string;
  buttonClassName?: string;
  buttonType?: 'submit' | 'reset' | 'button';
  successMessage?: string;
  error?: boolean;
};

export const InputPill: FC<InputPillProps> = props => {
  const {
    inputName,
    inputType = 'text',
    value,
    pattern = '',
    placeholder,
    CTA = 'Submit',
    wrapperClassName = '',
    onChangeCB,
    buttonClassName,
    inputClasName,
    buttonType = 'button',
    successMessage,
    error
  } = props;

  const success = useMemo(() => {
    return successMessage && successMessage.length !== 0;
  }, [successMessage]);

  return (
    <div className={clsx(styles.form, wrapperClassName)}>
      <div
        className={clsx(styles.inputWrapper, error ? styles.errorBorder : '')}
      >
        <input
          type={inputType}
          name={inputName}
          className={clsx(
            styles.input,
            error ? styles.errorColor : '',
            inputClasName
          )}
          placeholder={placeholder || ''}
          pattern={pattern ? pattern.toString().replace(/\//g, '') : null}
          value={value}
          onChange={onChangeCB}
          disabled={success}
        />
        <button
          type={buttonType}
          disabled={success || error}
          className={clsx(
            styles.button,
            'bg-sage',
            'text-white',
            buttonClassName
          )}
        >
          {success ? successMessage : CTA}
        </button>
      </div>
    </div>
  );
};
