import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './footer.module.css';
import { Row, Column } from '../../atoms/grid';
import { SocialLinks } from '../../molecules/social-links/social-links';
import { Text, NewsletterSignUpForm } from '../../';
import { FooterColumn } from '../../molecules/footer-column/footer-column';
import { FooterLegal } from '../../molecules/footer-legal/footer-legal';
import { FooterCta } from '../../molecules/footer-cta/footer-cta';
import { FooterColumnType } from '../../molecules/footer-column/footer-column';
import { FooterCtaProps } from '../../types';
import FooterContent from '../../content/footer/footer.json';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';

export const Footer: FC<FooterCtaProps> = ({
  footerContentKey,
  positionStatic = false
}) => {
  const isMobile = useIsMobileBreakpoint();
  const ctaTrackingLabel = `Newsletter sign up button on ${footerContentKey} page, in footer`;
  const formTrackingLabel = `Newsletter sign up form in footer`;
  const isB2B = footerContentKey === 'B2B';

  return (
    <div
      className={clsx(
        styles.footerWrapper,
        isB2B && styles.b2bFooterWrapper,
        'bg-dark-green',
        positionStatic && styles.positionStatic
      )}
    >
      <FooterCta footerContentKey={footerContentKey} />
      {!isB2B && (
        <div className={clsx(styles.footerContainer)}>
          <Row cols={[21, 4]} offset={1}>
            {FooterContent.columns.map((c: FooterColumnType, index: number) => (
              <FooterColumn
                key={`${c.title}-${index}`}
                title={c.title}
                links={c.links}
                offset={index === 0 ? [1, 2] : 1}
              />
            ))}
            <Column cols={[23, 6]}>
              <div>
                <NewsletterSignUpForm
                  withInterests
                  ctaTrackingLabel={ctaTrackingLabel}
                  formTrackingLabel={formTrackingLabel}
                />
              </div>
            </Column>
          </Row>
          <Row
            offset={[1, 2]}
            cols={15}
            className={styles.footerBottomBar}
            reversed={isMobile}
          >
            <FooterLegal cols={[15, 13]} />
            <Column className={styles.socialContainer} cols={[15, 3]}>
              <Text color="white" variant="p2b" className={styles.socialTitle}>
                {FooterContent.social.copy}
              </Text>
              <SocialLinks socialLinks={FooterContent.social.links} />
            </Column>
          </Row>
        </div>
      )}
    </div>
  );
};
