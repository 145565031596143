import React, { FC, useRef } from 'react';
import styles from './side-item.module.css';
import { Link } from '../../../atoms/link';
import { NavCircle } from '../nav-circle/nav-circle';
import { useIsHover } from '../../../../hooks/use-is-hover';
import clsx from 'clsx';
import { SanityNavigationItem } from 'graphql-types';

interface SideItemProps {
  route: SanityNavigationItem;
  itemNumber: number;
  onNavigate: () => void;
}

export const SideItem: FC<SideItemProps> = props => {
  const { route, itemNumber, onNavigate } = props;
  const ref = useRef<HTMLDivElement>(null);
  const { isHover } = useIsHover({ element: ref });

  return (
    <div
      ref={ref}
      className={styles.item}
      style={{
        animationDelay: `${itemNumber * 170}ms`
      }}
    >
      <div className={styles.circleWrapper}>
        <NavCircle size="md" active={isHover} />
      </div>
      <Link
        color="darkGreen"
        variant="nav"
        aria-label={`Navigate to ${route.title}`}
        destination={(route as SanityNavigationItem)?.path}
        className={clsx(styles.link, isHover && styles.animite)}
        onClick={onNavigate}
      >
        {route.title}
      </Link>
    </div>
  );
};
