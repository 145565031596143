import React, { FC } from 'react';
import { ImageType } from '../../atoms/image/types';
import { Image, Text } from '../../';
import './advisory-board-card.scss';
import { SanityAdvisoryBoard } from 'graphql-types';

export const AdvisoryBoardCard: FC<SanityAdvisoryBoard> = ({
  name,
  title,
  image,
  about
}) => {
  return (
    <div className="advisory-board-card">
      <div className="advisory-board-card-image-container">
        <Image {...image} type={ImageType.RECTANGLE_XL} />
      </div>
      <div className="advisory-board-card-content-container">
        <Text variant="h6a" className="text-center mb-xxs">
          {name}
          <br />
          {title}
        </Text>
        <Text variant="p3a" className="text-center">
          {about}
        </Text>
      </div>
    </div>
  );
};
