import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const FacebookIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M30 18C30 11.3726 24.6274 6 18 6C11.3726 6 6 11.3726 6 18C6 23.9895 10.3882 28.954 16.125 29.8542V21.4687H13.0781V18H16.125V15.3562C16.125 12.3488 17.9166 10.6875 20.6576 10.6875C21.9701 10.6875 23.3438 10.9219 23.3438 10.9219V13.875H21.8306C20.34 13.875 19.875 14.8001 19.875 15.75V18H23.2031L22.6711 21.4687H19.875V29.8542C25.6118 28.954 30 23.9895 30 18Z"
        fill="currentColor"
      />
    </IconSvg>
  );
};
