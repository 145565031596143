import React from 'react';

export const StressPath: React.FC = () => {
  return (
    <>
      <symbol viewBox="0 0 46 46" id="StressPathHandshake">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9062 7.38444C26.2619 7.38444 25.7396 7.90677 25.7396 8.5511C25.7396 9.19544 26.2619 9.71777 26.9062 9.71777C27.5506 9.71777 28.0729 9.19544 28.0729 8.5511C28.0729 7.90677 27.5506 7.38444 26.9062 7.38444ZM25.2396 8.5511C25.2396 7.63063 25.9858 6.88444 26.9062 6.88444C27.8267 6.88444 28.5729 7.63063 28.5729 8.5511C28.5729 9.47158 27.8267 10.2178 26.9062 10.2178C25.9858 10.2178 25.2396 9.47158 25.2396 8.5511Z"
          fill="currentColor"
        />
        <path
          d="M25.0504 12.2975C25.3438 12.2975 25.5816 12.0596 25.5816 11.7662C25.5816 11.4728 25.3438 11.235 25.0504 11.235C24.757 11.235 24.5191 11.4728 24.5191 11.7662C24.5191 12.0596 24.757 12.2975 25.0504 12.2975Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.9708 12.4405C31.3265 12.4405 30.8041 12.9629 30.8041 13.6072C30.8041 14.2515 31.3265 14.7739 31.9708 14.7739C32.6151 14.7739 33.1375 14.2515 33.1375 13.6072C33.1375 12.9629 32.6151 12.4405 31.9708 12.4405ZM30.3041 13.6072C30.3041 12.6867 31.0503 11.9405 31.9708 11.9405C32.8913 11.9405 33.6375 12.6867 33.6375 13.6072C33.6375 14.5277 32.8913 15.2739 31.9708 15.2739C31.0503 15.2739 30.3041 14.5277 30.3041 13.6072Z"
          fill="currentColor"
        />
        <path
          d="M28.7557 15.9943C29.0491 15.9943 29.2869 15.7564 29.2869 15.463C29.2869 15.1696 29.0491 14.9318 28.7557 14.9318C28.4623 14.9318 28.2244 15.1696 28.2244 15.463C28.2244 15.7564 28.4623 15.9943 28.7557 15.9943Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.8125 19.3468C33.1681 19.3468 32.6458 19.8691 32.6458 20.5134C32.6458 21.1578 33.1681 21.6801 33.8125 21.6801C34.4568 21.6801 34.9791 21.1578 34.9791 20.5134C34.9791 19.8691 34.4568 19.3468 33.8125 19.3468ZM32.1458 20.5134C32.1458 19.593 32.892 18.8468 33.8125 18.8468C34.733 18.8468 35.4791 19.593 35.4791 20.5134C35.4791 21.4339 34.733 22.1801 33.8125 22.1801C32.892 22.1801 32.1458 21.4339 32.1458 20.5134Z"
          fill="currentColor"
        />
        <path
          d="M30.1001 21.0447C30.3935 21.0447 30.6314 20.8068 30.6314 20.5134C30.6314 20.22 30.3935 19.9822 30.1001 19.9822C29.8067 19.9822 29.5689 20.22 29.5689 20.5134C29.5689 20.8068 29.8067 21.0447 30.1001 21.0447Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.9623 26.253C31.318 26.253 30.7956 26.7754 30.7956 27.4197C30.7956 28.064 31.318 28.5864 31.9623 28.5864C32.6066 28.5864 33.129 28.064 33.129 27.4197C33.129 26.7754 32.6066 26.253 31.9623 26.253ZM30.2956 27.4197C30.2956 26.4992 31.0418 25.753 31.9623 25.753C32.8828 25.753 33.629 26.4992 33.629 27.4197C33.629 28.3402 32.8828 29.0864 31.9623 29.0864C31.0418 29.0864 30.2956 28.3402 30.2956 27.4197Z"
          fill="currentColor"
        />
        <path
          d="M28.7472 26.0944C29.0406 26.0944 29.2784 25.8565 29.2784 25.5631C29.2784 25.2697 29.0406 25.0319 28.7472 25.0319C28.4538 25.0319 28.2159 25.2697 28.2159 25.5631C28.2159 25.8565 28.4538 26.0944 28.7472 26.0944Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9062 31.3084C26.2619 31.3084 25.7396 31.8307 25.7396 32.4751C25.7396 33.1194 26.2619 33.6417 26.9062 33.6417C27.5506 33.6417 28.0729 33.1194 28.0729 32.4751C28.0729 31.8307 27.5506 31.3084 26.9062 31.3084ZM25.2396 32.4751C25.2396 31.5546 25.9858 30.8084 26.9062 30.8084C27.8267 30.8084 28.5729 31.5546 28.5729 32.4751C28.5729 33.3955 27.8267 34.1417 26.9062 34.1417C25.9858 34.1417 25.2396 33.3955 25.2396 32.4751Z"
          fill="currentColor"
        />
        <path
          d="M25.0504 29.7912C25.3438 29.7912 25.5816 29.5533 25.5816 29.2599C25.5816 28.9665 25.3438 28.7287 25.0504 28.7287C24.757 28.7287 24.5191 28.9665 24.5191 29.2599C24.5191 29.5533 24.757 29.7912 25.0504 29.7912Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 33.1458C19.3556 33.1458 18.8333 33.6681 18.8333 34.3125C18.8333 34.9568 19.3556 35.4791 20 35.4791C20.6443 35.4791 21.1666 34.9568 21.1666 34.3125C21.1666 33.6681 20.6443 33.1458 20 33.1458ZM18.3333 34.3125C18.3333 33.392 19.0795 32.6458 20 32.6458C20.9205 32.6458 21.6666 33.392 21.6666 34.3125C21.6666 35.233 20.9205 35.9791 20 35.9791C19.0795 35.9791 18.3333 35.233 18.3333 34.3125Z"
          fill="currentColor"
        />
        <path
          d="M20 31.1314C20.2934 31.1314 20.5312 30.8935 20.5312 30.6001C20.5312 30.3067 20.2934 30.0689 20 30.0689C19.7066 30.0689 19.4687 30.3067 19.4687 30.6001C19.4687 30.8935 19.7066 31.1314 20 31.1314Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0937 31.3084C12.4494 31.3084 11.9271 31.8307 11.9271 32.4751C11.9271 33.1194 12.4494 33.6417 13.0937 33.6417C13.7381 33.6417 14.2604 33.1194 14.2604 32.4751C14.2604 31.8307 13.7381 31.3084 13.0937 31.3084ZM11.4271 32.4751C11.4271 31.5546 12.1733 30.8084 13.0937 30.8084C14.0142 30.8084 14.7604 31.5546 14.7604 32.4751C14.7604 33.3955 14.0142 34.1417 13.0937 34.1417C12.1733 34.1417 11.4271 33.3955 11.4271 32.4751Z"
          fill="currentColor"
        />
        <path
          d="M14.9496 29.7912C15.243 29.7912 15.4808 29.5533 15.4808 29.2599C15.4808 28.9665 15.243 28.7287 14.9496 28.7287C14.6562 28.7287 14.4183 28.9665 14.4183 29.2599C14.4183 29.5533 14.6562 29.7912 14.9496 29.7912Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.03765 26.253C7.39331 26.253 6.87098 26.7754 6.87098 27.4197C6.87098 28.064 7.39331 28.5864 8.03765 28.5864C8.68198 28.5864 9.20431 28.064 9.20431 27.4197C9.20431 26.7754 8.68198 26.253 8.03765 26.253ZM6.37098 27.4197C6.37098 26.4992 7.11717 25.753 8.03765 25.753C8.95812 25.753 9.70431 26.4992 9.70431 27.4197C9.70431 28.3402 8.95812 29.0864 8.03765 29.0864C7.11717 29.0864 6.37098 28.3402 6.37098 27.4197Z"
          fill="currentColor"
        />
        <path
          d="M11.2528 26.0944C11.5462 26.0944 11.784 25.8565 11.784 25.5631C11.784 25.2697 11.5462 25.0319 11.2528 25.0319C10.9594 25.0319 10.7215 25.2697 10.7215 25.5631C10.7215 25.8565 10.9594 26.0944 11.2528 26.0944Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.18748 19.3468C5.54315 19.3468 5.02081 19.8691 5.02081 20.5134C5.02081 21.1578 5.54315 21.6801 6.18748 21.6801C6.83181 21.6801 7.35415 21.1578 7.35415 20.5134C7.35415 19.8691 6.83181 19.3468 6.18748 19.3468ZM4.52081 20.5134C4.52081 19.593 5.26701 18.8468 6.18748 18.8468C7.10795 18.8468 7.85415 19.593 7.85415 20.5134C7.85415 21.4339 7.10795 22.1801 6.18748 22.1801C5.26701 22.1801 4.52081 21.4339 4.52081 20.5134Z"
          fill="currentColor"
        />
        <path
          d="M9.89985 21.0447C10.1933 21.0447 10.4311 20.8068 10.4311 20.5134C10.4311 20.22 10.1933 19.9822 9.89985 19.9822C9.60645 19.9822 9.3686 20.22 9.3686 20.5134C9.3686 20.8068 9.60645 21.0447 9.89985 21.0447Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.03765 12.4405C7.39331 12.4405 6.87098 12.9629 6.87098 13.6072C6.87098 14.2515 7.39331 14.7739 8.03765 14.7739C8.68198 14.7739 9.20431 14.2515 9.20431 13.6072C9.20431 12.9629 8.68198 12.4405 8.03765 12.4405ZM6.37098 13.6072C6.37098 12.6867 7.11717 11.9405 8.03765 11.9405C8.95812 11.9405 9.70431 12.6867 9.70431 13.6072C9.70431 14.5277 8.95812 15.2739 8.03765 15.2739C7.11717 15.2739 6.37098 14.5277 6.37098 13.6072Z"
          fill="currentColor"
        />
        <path
          d="M11.2528 15.9943C11.5462 15.9943 11.784 15.7564 11.784 15.463C11.784 15.1696 11.5462 14.9318 11.2528 14.9318C10.9594 14.9318 10.7215 15.1696 10.7215 15.463C10.7215 15.7564 10.9594 15.9943 11.2528 15.9943Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0937 7.38444C12.4494 7.38444 11.9271 7.90677 11.9271 8.5511C11.9271 9.19544 12.4494 9.71777 13.0937 9.71777C13.7381 9.71777 14.2604 9.19544 14.2604 8.5511C14.2604 7.90677 13.7381 7.38444 13.0937 7.38444ZM11.4271 8.5511C11.4271 7.63063 12.1733 6.88444 13.0937 6.88444C14.0142 6.88444 14.7604 7.63063 14.7604 8.5511C14.7604 9.47158 14.0142 10.2178 13.0937 10.2178C12.1733 10.2178 11.4271 9.47158 11.4271 8.5511Z"
          fill="currentColor"
        />
        <path
          d="M14.9496 12.2975C15.243 12.2975 15.4808 12.0596 15.4808 11.7662C15.4808 11.4728 15.243 11.235 14.9496 11.235C14.6562 11.235 14.4183 11.4728 14.4183 11.7662C14.4183 12.0596 14.6562 12.2975 14.9496 12.2975Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 5.52081C19.3556 5.52081 18.8333 6.04315 18.8333 6.68748C18.8333 7.33181 19.3556 7.85415 20 7.85415C20.6443 7.85415 21.1666 7.33181 21.1666 6.68748C21.1666 6.04315 20.6443 5.52081 20 5.52081ZM18.3333 6.68748C18.3333 5.76701 19.0795 5.02081 20 5.02081C20.9205 5.02081 21.6666 5.76701 21.6666 6.68748C21.6666 7.60795 20.9205 8.35415 20 8.35415C19.0795 8.35415 18.3333 7.60795 18.3333 6.68748Z"
          fill="currentColor"
        />
        <path
          d="M20 10.9311C20.2934 10.9311 20.5312 10.6933 20.5312 10.3999C20.5312 10.1065 20.2934 9.8686 20 9.8686C19.7066 9.8686 19.4687 10.1065 19.4687 10.3999C19.4687 10.6933 19.7066 10.9311 20 10.9311Z"
          fill="currentColor"
        />
      </symbol>
      <use xlinkHref="#StressPathHandshake" />
    </>
  );
};
