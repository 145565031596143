import { ReactElement } from 'react';

export const ConditionalWrapper = ({
  condition,
  wrapper,
  alternativeWrapper,
  children
}: {
  condition: boolean;
  wrapper: Function;
  alternativeWrapper?: Function;
  children: ReactElement;
}) =>
  condition
    ? wrapper(children)
    : alternativeWrapper
    ? alternativeWrapper(children)
    : children;
