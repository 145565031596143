import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import clsx from 'clsx';
import styles from './link.module.css';
import textStyles from './../text/text.module.css';

const regex = /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const textVariants = {
  u1a: 'textUtility1a',
  u1b: 'textUtility1b',
  u1bLink: 'textUtility1bLink',
  h2a: 'textH2a',
  p2a: 'textP2a',
  p3a: 'textP3a',
  nav: 'textNav'
};

export interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  underline?: boolean;
  underSpace?: boolean;
  color?: 'darkGreen' | 'sage' | 'white' | 'inherit';
  destination: string;
  openInNewTab?: boolean;
  variant?: keyof typeof textVariants;
  ignoreStyles?: boolean;
  activeClassName?: string;
}

export const Link: FC<LinkProps> = ({
  children,
  className,
  color = 'darkGreen',
  openInNewTab,
  destination,
  underline,
  underSpace,
  variant = 'u1bLink',
  ignoreStyles = false,
  activeClassName = '',
  ...props
}) => {
  const variantName = textVariants[variant];

  const linkClassNames = clsx(
    styles.link,
    styles[color],
    underline && 'underline',
    underSpace && 'underspace',
    className,
    textStyles[variantName]
  );

  if (
    destination?.includes('tel:') ||
    regex.test(destination) ||
    destination?.includes('mailto:')
  ) {
    const targetProps = openInNewTab && {
      target: '_blank',
      rel: 'noopener noreferrer'
    };

    return (
      <a
        href={destination}
        {...targetProps}
        className={!ignoreStyles ? linkClassNames : className}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={destination}
      className={!ignoreStyles ? linkClassNames : className}
      activeClassName={activeClassName}
      {...props}
    >
      {children}
    </GatsbyLink>
  );
};
