import React, { FC, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import { NavItem } from '@parsleyhealth/cilantro-ui';
import { Text } from '../../../redesign/atoms/text/text';
import { CaretIcon } from '../../../redesign/molecules/icons';
import NavItemWrapper from '../../../components/HeaderNew/nav-item-wrapper';
import { SanityNavigationItem } from 'graphql-types';

interface HiltonNavItemProps {
  route: SanityNavigationItem;
}

export const HiltonNavItem: FC<HiltonNavItemProps> = props => {
  const [isHover, setIsHover] = useState(false);

  const hasChildrenRoutes = useMemo(() => {
    return props.route.childrenItems?.length > 0;
  }, [props.route]);

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {hasChildrenRoutes ? (
        <NavItemWrapper key={props.route._key}>
          <Text variant="u1a" role="button">
            {props.route.title}
            <CaretIcon
              iconWidth="16"
              direction={isHover ? 'up' : 'down'}
              strokeWidth={2.5}
            />
          </Text>
          {isHover && (
            <div className="dropdown-menu">
              {props.route.childrenItems?.map(childRoute => (
                <Link
                  key={childRoute._key}
                  to={childRoute.path}
                  activeClassName="active"
                >
                  <NavItem variant="darkTheme">{childRoute.title}</NavItem>
                </Link>
              ))}
            </div>
          )}
        </NavItemWrapper>
      ) : (
        <NavItemWrapper key={props.route._key}>
          <Link to={props.route.path} activeClassName="active">
            <NavItem variant="darkTheme">{props.route.title}</NavItem>
          </Link>
        </NavItemWrapper>
      )}
    </div>
  );
};
