import React, { FC } from 'react';
import mainStyles from '../navigation.module.css';
import styles from './mobile-nav.module.css';
import clsx from 'clsx';
import { LayoutTheme } from '../../../types';

import { useScrollPosition } from '../../../../hooks/use-scroll-position';
import { useOnToggleScrolling } from '../../../../hooks/use-on-toggle-scrolling';
import { Logo } from '../logo/logo-nav';
import { Hamburger } from '../hamburger/hamburger';
import { MobileNavItem } from '../mobile-nav-item/mobile-nav-item';
import { JoinButton } from '../join-button/join-button';
import { QuizButton } from '../quiz-button/quiz-button';
import { useCurrentPath } from '../../../../hooks/use-current-path';
import { useQuizContent } from '../../../../hooks/use-quiz-content';
import { useIsMobileBreakpoint } from '../../../../hooks/use-is-mobile-breakpoint';
import useNavigation from '../../../../hooks/use-navigation';
import { SanityNavigationItem } from 'graphql-types';
import { track } from '@parsleyhealth/cilantro-track';

export const MobileNav: FC<{
  theme: LayoutTheme;
  hideQuizCtaButton: boolean;
}> = ({ theme, hideQuizCtaButton }) => {
  const [isOpen, onToggleOpen] = useOnToggleScrolling(false);
  const { mainPrimaryNav, mainSecondaryNav } = useNavigation();
  const scrollPosition = useScrollPosition();
  const quizCtaContent = useQuizContent('topNav');
  const isMobile = useIsMobileBreakpoint();

  const scrolled = (isOpen || scrollPosition.y > 15) && 'Scrolled';

  const path = useCurrentPath();

  const handleMobItemClick = (routeDestination: string, routeName: string) => {
    onToggleOpen();
    track('Clicked CTA', {
      destination: routeDestination,
      label: `Nav Hamburger Item ${routeName} clicked on ${path} page`,
      path
    });
  };

  return (
    <div
      className={clsx(
        styles.container,
        scrolled && mainStyles[`header${scrolled}`]
      )}
    >
      <header className={styles.header}>
        <div className="inline-flex-center">
          <Logo variant="mobile" />
        </div>
        <div className="inline-flex-center gap-xxs">
          <JoinButton theme={!scrolled ? theme : undefined} />
          {!isMobile && !hideQuizCtaButton && (
            <QuizButton scrolled={!!scrolled} />
          )}
          <Hamburger onClick={onToggleOpen} expanded={isOpen} />
        </div>
      </header>
      <div
        className={clsx(
          styles.menu,
          isOpen ? styles.menuOpened : styles.menuHidden
        )}
      >
        <nav className="flex flex-col gap-sm">
          {mainPrimaryNav?.map((r: SanityNavigationItem) => (
            <MobileNavItem
              key={r._key}
              route={r}
              variant="h2a"
              onNavigate={onToggleOpen}
            />
          ))}
          {!quizCtaContent.hide && isMobile && (
            <MobileNavItem route={quizCtaContent} variant="h2a" />
          )}
        </nav>
        <nav className="flex flex-col gap-xxs pb-xxl">
          {mainSecondaryNav?.map((r: SanityNavigationItem) => (
            <MobileNavItem
              key={r.path}
              route={r}
              onNavigate={() => handleMobItemClick(r.path, r.title)}
            />
          ))}
        </nav>
      </div>
    </div>
  );
};
