import { useHasMounted } from './use-has-mounted';
import { HelpScout } from '../lib/HelpScout';

const HELPSCOUT_BUTTON_COLOR =
  process.env.NODE_ENV === `production` ? '#64bcad' : '#527ceb';

interface UseHelpscoutButtonHook {
  onInitHelpScout?: (onReady: () => void) => void;
  color: string;
}

export const useHelpscoutButton = (): UseHelpscoutButtonHook => {
  const isMounted = useHasMounted();
  const initialResult = {
    color: HELPSCOUT_BUTTON_COLOR
  };
  if (isMounted) {
    const onInitHelpScout: (onReady: () => void) => void = onReady => {
      window.HelpScout = new HelpScout();
      window.HelpScout.init({
        beaconId: process.env.GATSBY_HELPSCOUT_BEACON_ID,
        hubspotHelpscoutFormID: process.env.GATSBY_HUBSPOT_HELPSCOUT_FORM_ID,
        hubspotPortalID: process.env.GATSBY_HUBSPOT_PORTAL_ID,
        config: {
          HELPSCOUT_BUTTON_COLOR
        },
        onReady
      });
    };
    return {
      ...initialResult,
      onInitHelpScout
    };
  }
  return initialResult;
};
