import React, { FC } from 'react';
import clsx from 'clsx';

import { Text, Tag, Button, HalfAndHalf } from '../..';
import { ImageProps } from '../../types';
import { ImageType } from '../../atoms/image/types';
import { FadeIn } from '../../atoms/fade-in/fade-in';
import { CaretIcon } from '../../atoms/icons/caret-icon/caret-icon';
import { TextLockup } from '../../atoms/text-lockup';
import styles from './provider-profile-info.module.css';

export type ProviderProfileInfoProps = {
  role: string;
  name: string;
  acceptingPatients: boolean;
  pronouns: string;
  licensedIn: string;
  languageProficiencies: string;
  clinicalExpertise: string;
  bio: string;
  image: ImageProps;
  ctaTrackingLabel: string;
};

type ProfileItemKeys =
  | 'pronouns'
  | 'licensedIn'
  | 'languageProficiencies'
  | 'clinicalExpertise'
  | 'bio';

interface ProfileItemProps {
  label?: string;
  value?: string;
  type?: ProfileItemKeys;
}

const profileItems: { label?: string; key: ProfileItemKeys }[] = [
  {
    label: 'Pronouns',
    key: 'pronouns'
  },
  {
    label: 'Licensed in',
    key: 'licensedIn'
  },
  {
    label: 'Language Proficiencies',
    key: 'languageProficiencies'
  },
  {
    key: 'bio'
  }
];

const ProfileItem: FC<ProfileItemProps> = ({ label, value, type }) => {
  if (!value || type === 'licensedIn') return null;
  return (
    <div
      className={clsx(
        styles.profileItem,
        'flex flex-wrap flex-col pt-xxs pb-xxs'
      )}
    >
      {label && <Text variant="p2b">{label}:</Text>}
      <Text variant="p2a" color="dark-green">
        {value}
      </Text>
    </div>
  );
};

export const ProviderProfileInfo: FC<ProviderProfileInfoProps> = props => {
  const { role, name, image, acceptingPatients, ctaTrackingLabel } = props;
  return (
    <div className="relative">
      <FadeIn>
        <Button
          variant="filled"
          color="sage"
          size="small"
          className={styles.button}
          destination="/providers"
          trackingLabel={ctaTrackingLabel}
        >
          <CaretIcon direction="left" color="white" />
          All Clinicians
        </Button>
        <TextLockup alignment="center" className="hero-lockup">
          {role && (
            <Text variant="p3b" className="text-lockup-eyebrow">
              {role}
            </Text>
          )}
          <Text variant="h1a" className="text-lockup-headline">
            {name}
          </Text>
          {acceptingPatients === true && (
            <Tag variant="mint" className={styles.acceptingTag}>
              Accepting patients
            </Tag>
          )}
        </TextLockup>
      </FadeIn>
      <HalfAndHalf
        image={{ ...image, type: ImageType.RECTANGLE_XL, blob: true }}
        reversed={[false, true]}
        paddingY={false}
        reanimate={false}
        animated
      >
        <FadeIn>
          {profileItems.map(({ key, label }) => {
            return (
              <ProfileItem
                label={label}
                value={props[key]}
                key={key}
                type={key}
              />
            );
          })}
        </FadeIn>
      </HalfAndHalf>
    </div>
  );
};

export default ProviderProfileInfo;
