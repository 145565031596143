import React from 'react';

export const GoalPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2813 20.2322V10.8682H31.7187V20.2322H32.2813ZM32.2325 25.9831L32.0006 25.6452L31.7687 25.9831L13.2427 52.9731L12.9404 53.4136H13.4746H50.5266H51.0608L50.7585 52.9731L32.2325 25.9831ZM14.0088 52.851L32.0006 26.6393L49.9924 52.851H14.0088ZM42.9013 18.2664L36.2153 22.8764L35.896 22.4133L42.582 17.8033L42.9013 18.2664ZM28.3397 22.4107L21.4137 17.8007L21.102 18.269L28.028 22.879L28.3397 22.4107Z"
      fill="currentColor"
    />
  );
};
