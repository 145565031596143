//@ts-nocheck
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Text } from '../..';

import './bullet-list.scss';

export type BulletListItemContent = ReactNode | string;

export interface BulletListItem {
  content: BulletListItemContent;
  subItems?: string[];
}

export interface BulletListProps {
  items: (BulletListItem | BulletListItemContent)[];
  className?: string;
}

export const BulletList = ({ items, className }: BulletListProps) => {
  const classNames = clsx('bullet-list', className);
  return (
    <ul className={classNames}>
      {items.map((item, i) => {
        const bulletItem: BulletListItem = item as BulletListItem;
        return (
          <li key={`bullet-list-${i}-${className}`}>
            <Text variant="p2a">{bulletItem.content ?? item}</Text>
            {bulletItem?.subItems && (
              <ul className="bullet-list sub-list">
                {bulletItem?.subItems.map((subItem, i) => (
                  <li key={`${subItem}-${i}`}>
                    <Text variant="p2a">{subItem}</Text>
                  </li>
                ))}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};
