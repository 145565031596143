import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import styles from './article-card.module.css';

import { Image, Text, Tag, Link } from '../../';
import { useIsHover } from '../../../hooks/use-is-hover';
import useDeviceDetect from '../../../hooks/use-is-device-detect';

import { ImageType } from '../../../redesign/atoms/image/types';
import { SanityBlogPost } from 'graphql-types';

export interface ArticleCardProps extends SanityBlogPost {}

export const ArticleCard: FC<ArticleCardProps> = props => {
  const { title, slug, tags, image } = props;

  const element = useRef<HTMLDivElement>(null);
  const { isHover } = useIsHover({ element });
  const { isMobile } = useDeviceDetect();

  const destination = slug?.current;
  const tag = tags ? tags[0] : undefined;

  return (
    <div
      className={clsx(styles.articleCard, 'flex-center', 'flex-col')}
      ref={element}
    >
      <Link
        destination={destination}
        ignoreStyles={true}
        className={clsx(styles.articleCardImage, 'w-full')}
      >
        <Image
          {...image}
          type={ImageType.RECTANGLE_XL}
          blob={isHover && !isMobile}
        />
      </Link>
      <div
        className={clsx(styles.articleContent, 'w-full flex flex-col mt-md')}
      >
        {tag && (
          <Tag destination={`/blog/${tag.slug?.current}`} {...tag}>
            {tag?.title}
          </Tag>
        )}
        <Link destination={destination} ignoreStyles={true}>
          <Text
            variant="h6a"
            className={styles.articleCardText}
            color="dark-green"
          >
            {title}
          </Text>
        </Link>
      </div>
    </div>
  );
};
