import React from 'react';

export const SleepPath: React.FC = () => {
  return (
    <>
      <symbol viewBox="0 0 46 46" id="SleepPathHandshake">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.0639 28.1271C26.9842 28.1278 24.9369 27.6112 23.1066 26.6237C21.2762 25.6363 19.7202 24.209 18.5788 22.4705C17.4374 20.732 16.7464 18.7368 16.568 16.6648C16.3897 14.5927 16.7295 12.5088 17.5571 10.6008C15.6801 11.4136 14.0305 12.6732 12.7519 14.2697C11.4734 15.8663 10.6048 17.7513 10.2218 19.7605C9.83882 21.7697 9.95309 23.8421 10.5547 25.797C11.1562 27.7519 12.2269 29.53 13.6732 30.9763C15.1195 32.4225 16.8976 33.4931 18.8526 34.0946C20.8075 34.6961 22.8799 34.8103 24.8891 34.4272C26.8983 34.0442 28.7833 33.1755 30.3797 31.8969C31.9762 30.6183 33.2357 28.9686 34.0485 27.0917C32.4756 27.7753 30.7789 28.1278 29.0639 28.1271V28.1271Z"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="currentColor"
          fill="white"
        />
      </symbol>
      <use xlinkHref="#SleepPathHandshake" />
    </>
  );
};
