//@ts-nocheck
import React from 'react';
import { navigate } from 'gatsby';
import { connectSearchBox } from 'react-instantsearch-dom';
import './search.css';

type Props = {
  refine: (e: string) => void;
  currentRefinement: string;
  defaultRefinement?: string;
};

export default connectSearchBox(
  ({ refine, currentRefinement, children }: Props) => {
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      navigate(`/blog/search?q=${currentRefinement}`);
    };

    return (
      <form className="container-search-bar" onSubmit={onSubmit}>
        <input
          className="input-search-bar"
          type="text"
          placeholder="Search for something..."
          name="search"
          onChange={e => refine(e.target.value)}
          value={currentRefinement}
        />
        {children}
      </form>
    );
  }
);
