import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

/**
 * useAnimateInView
 * @param reanimate to animate each time the element is in view
 * @returns an object with the animated indicator and a ref to pass to detect the element in view
 */
export const useAnimateInView = (reanimate = true) => {
  const [ref, inView] = useInView({ triggerOnce: !reanimate });
  const [animated, setAnimated] = useState(true);

  useEffect(() => {
    if (reanimate) {
      setAnimated(inView);
    }
  }, [reanimate, inView]);

  return { ref, animated };
};
