import React, { FC } from 'react';
import clsx from 'clsx';

import { ArticleCard, Text, Row, Column } from '../../';
import styles from './featured-articles.module.css';
import useResponsiveProps from '../../../hooks/use-responsive-props';
import { SanityBlogPost } from 'graphql-types';
import { TTextVariants } from 'src/redesign/types';

export interface FeaturedArticlesProps {
  headline: {
    variant: TTextVariants;
    align: 'left' | 'center';
    text: string;
  };
  articles: SanityBlogPost[];
}

export const FeaturedArticles: FC<FeaturedArticlesProps> = ({
  articles,
  headline: { align, text, variant }
}) => {
  const { getPropV2 } = useResponsiveProps();
  const isCenter = align === 'center';
  const classNames = isCenter
    ? `flex-center mb-xl text-center ${styles.headlineCenter}`
    : `mb-md text-left ${styles.headlineLeft}`;

  return (
    <Row cols={25} className={`flex-col ${getPropV2(['xl', 'big'], 'py')}`}>
      <Column>
        <Text
          variant={variant}
          className={clsx('w-full', classNames)}
          color="dark-green"
        >
          {text}
        </Text>
      </Column>
      <Column
        className={clsx(styles.featuredArticles, 'flex align-start', {
          'justify-center': isCenter
        })}
      >
        {articles.map((article, i) => {
          return (
            <div className={styles.featuredArticle} key={`${article.id}-${i}`}>
              <ArticleCard {...article} />
            </div>
          );
        })}
      </Column>
    </Row>
  );
};

export default FeaturedArticles;
