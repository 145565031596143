import React, { FC } from 'react';
import { Text } from '../../atoms/text/text';
import { TTextColor, TTextVariants } from '../../types';
import styles from './bar-chart.module.css';
import clsx from 'clsx';

type Data = {
  _key?: string;
  title?: string;
  percentage?: number;
};

export type BarChartProps = {
  textVariant?: TTextVariants;
  color?: TTextColor;
  data: Data[];
  name: string;
  title?: string;
};

export const BarChart: FC<BarChartProps> = ({
  title,
  name,
  data,
  textVariant = 'u1a',
  color = 'dark-green'
}) => {
  return (
    <div>
      {title && (
        <Text
          className={clsx('text-center', 'mb-lg', styles.barChartTitle)}
          variant="h6a"
        >
          {title}
        </Text>
      )}
      <div className={styles.container}>
        {name && (
          <div className={styles.barChartNameWrap}>
            <Text
              variant={textVariant}
              color={color}
              className={clsx('mb-xxs', styles.barChartName)}
            >
              {name}
            </Text>
          </div>
        )}
        <div className={`border-left border-bottom ${styles.barChart}`}>
          {data.map(({ title, percentage, _key }, index) => (
            <div key={`${index}-${_key}`} className={styles.barWrap}>
              <div
                className={styles.bar}
                style={{ height: `${percentage}%` }}
              ></div>
              <Text variant="u3a" color={color} className={styles.barTitle}>
                {title}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
