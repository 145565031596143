import React, { useMemo } from 'react';

import { LayoutProps } from '../types';
import { Navigation } from '../../redesign/organisms/navigation';
import BlogHeader from '../../components/Blog/BlogHeader';
import InsightsHeader from '../../components/insights/insights-main-nav';
import HiltonHeader from '../../components/HiltonEducation/HiltonHeader';

export type NavbarType = 'MAIN_SITE' | 'BLOG' | 'INSIGHTS' | 'B2B' | 'HILTON';

export type NavSwitcherType = {
  navbarType: NavbarType;
  slug?: string;
} & LayoutProps;

const NavSwitcher: React.FC<NavSwitcherType> = ({
  navbarType = 'MAIN_SITE',
  theme = 'sherbed',
  hideQuizCtaButton,
  children
}) => {
  const navbar = useMemo(() => {
    const navbarOptions: Record<NavbarType, React.ReactNode> = {
      BLOG: <BlogHeader />,
      HILTON: <HiltonHeader />,
      MAIN_SITE: (
        <Navigation theme={theme} hideQuizCtaButton={hideQuizCtaButton} />
      ),
      INSIGHTS: <InsightsHeader />,
      B2B: children
    };
    return navbarOptions[navbarType];
  }, [navbarType, theme, hideQuizCtaButton, children]);

  return <div>{navbar}</div>;
};

export default NavSwitcher;
