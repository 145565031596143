import React from 'react';
import { Link } from 'gatsby';
import useNavigation from './../../../hooks/use-navigation';
import { Toggler } from '../../../hooks/use-mobile-menu';
import '../../HeaderNew/default-nav-mobile.css';
interface NavItemProps {
  title: string;
  path: string;
  delay: number;
}

const NavItem: React.FC<NavItemProps> = ({ delay, path, title }) => {
  return (
    <div
      className="nav-item-wrapper"
      style={{
        animationDelay: `${delay * 40}ms`
      }}
    >
      <Link to={path} activeClassName="active">
        <span className="heading-3">{title}</span>
      </Link>
    </div>
  );
};

const BlogMainMobile: React.FC = () => {
  const { blogCategoryNav } = useNavigation();
  const { expanded } = Toggler.useContainer();
  let delayDefault = 0;

  if (!expanded) {
    return null;
  }

  return (
    <div className="container-mobile-nav">
      {blogCategoryNav?.map((item: NavItemProps) => (
        <NavItem delay={++delayDefault} path={item.path} title={item.title} />
      ))}
    </div>
  );
};

export default BlogMainMobile;
