import React, { FC, useCallback, useState } from 'react';
import { LayoutTheme } from '../../../types';

import { useScrollPosition } from '../../../../hooks/use-scroll-position';
import { useMask } from '../../../../hooks/use-mask';

import { Logo } from '../logo/logo-nav';
import { SideMenu } from '../side-menu/side-menu';
import { NavSection } from '../nav-section/nav-section';
import { JoinButton } from '../join-button/join-button';
import { QuizButton } from '../quiz-button/quiz-button';
import { NavContainer } from '../nav-container/nav-container';
import useNavigation from '../../../../hooks/use-navigation';
import { SanityNavigationItem } from 'graphql-types';

export const DesktopNav: FC<{
  theme: LayoutTheme;
  hideQuizCtaButton: boolean;
}> = ({ theme, hideQuizCtaButton }) => {
  const [sideMenuRouteOpened, setSideMenuRouteOpened] = useState<
    SanityNavigationItem
  >();
  const { showMask, setMask } = useMask();
  const scrollPosition = useScrollPosition();
  const { mainPrimaryNav, mainSecondaryNav } = useNavigation();
  const scrolled = scrollPosition.y > 15 && 'Scrolled';

  const handleCloseSideMenu = useCallback(() => {
    showMask && setMask(false);
    setSideMenuRouteOpened(undefined);
  }, [showMask, setMask]);

  const handleOpenSideMenu = useCallback(
    (route: SanityNavigationItem) => {
      const isSameRoute = route.title === sideMenuRouteOpened?.title;
      if (isSameRoute) {
        handleCloseSideMenu();
      } else {
        setMask(true);
        setSideMenuRouteOpened(route);
      }
    },
    [sideMenuRouteOpened, handleCloseSideMenu, setMask]
  );

  return (
    <>
      <NavContainer>
        <NavSection
          routes={mainPrimaryNav}
          justify="start"
          handleOpenSideMenu={handleOpenSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
        />
        <div className="inline-flex-center">
          <Logo />
        </div>
        <NavSection
          routes={mainSecondaryNav}
          justify="end"
          handleOpenSideMenu={handleOpenSideMenu}
          handleCloseSideMenu={handleCloseSideMenu}
        >
          <JoinButton theme={!scrolled ? theme : undefined} />
          {!hideQuizCtaButton && <QuizButton scrolled={!!scrolled} />}
        </NavSection>
      </NavContainer>
      <SideMenu
        routeSelected={sideMenuRouteOpened}
        handleCloseSideMenu={handleCloseSideMenu}
        handleOpenSideMenu={handleOpenSideMenu}
      />
    </>
  );
};
