import React, { FC, Key } from 'react';
import clsx from 'clsx';
import useResponsiveProps from '../../../hooks/use-responsive-props';
import { useBorder } from '../../../hooks/use-border';
import { ColumnUnit } from '../../types';

import './grid.scss';

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Amount of space the Column will span.
   */
  cols?: ColumnUnit;
  /**
   * Amount of margin to the right the column
   * will have expressed in columns.
   */
  gap?: ColumnUnit;
  /**
   * The offset that columns will have
   */
  offset?: ColumnUnit;
  /**
   * Where the columns will have borders
   */
  borders?: string | string[];
  /**
   * Optional key value if this component is being used in a loop
   */
  key?: Key;
}

export const Column: FC<ColumnProps> = ({
  children,
  cols,
  gap,
  className,
  offset,
  borders,
  ...props
}) => {
  const { getPropV2 } = useResponsiveProps();
  const { getBorderClasses } = useBorder();
  const classNames = clsx(className, {
    column: true,
    [`${getPropV2(cols, 'col')}`]: cols,
    [`${getPropV2(gap, 'gap')}`]: gap,
    [`${getPropV2(offset, 'offset')}`]: offset,
    [`${getBorderClasses(borders)}`]: borders
  });

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
