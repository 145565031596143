import { useState } from 'react';

const SALESFORCE_MESSAGE_FIELD_NAME = '00N8c00000bioaW';
const SALES_LEAD_ENPDOINT_KEY = process.env.GATSBY_SALESFORCE_API_KEY;
const SALES_LEAD_EANDPOINT =
  'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

export const useCaptureSalesLead = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const captureSalesLead = (lead: any) => {
    const endpointParams: Record<string, string> = {
      company: lead?.company,
      email: lead?.email,
      first_name: lead?.firstName,
      last_name: lead?.lastName,
      lead_source: lead.leadSource,
      oid: SALES_LEAD_ENPDOINT_KEY,
      phone: lead?.phone,
      title: lead?.title
    };
    if (lead?.message)
      endpointParams[SALESFORCE_MESSAGE_FIELD_NAME] = lead?.message;
    const paramsString = new URLSearchParams(endpointParams).toString();
    const salesforceEndpoint = new URL(
      `${SALES_LEAD_EANDPOINT}&${paramsString}`
    );

    setLoading(true);
    fetch(salesforceEndpoint.toString(), {
      method: 'POST'
    })
      .then(() => setSubmitted(true))
      .catch(e => setError(e))
      .finally(() => setLoading(false));
  };

  return {
    captureSalesLead,
    loading,
    error,
    submitted
  };
};
