import React, { FC } from 'react';
import clsx from 'clsx';
import { Dropdown, Button } from './../../';
import { Heading } from './heading';
import './dropdown-selection.scss';

export type DropdownSelectionProps = {
  isOpen: boolean;
  onToggleOpen: () => void;
  activeMouseHoverIteraction?: boolean;
  heading?: string;
  className?: string;
  ctaTrackingLabel: string;
  children?: any;
};

export const DropdownSelection: FC<DropdownSelectionProps> = ({
  isOpen,
  onToggleOpen,
  activeMouseHoverIteraction = false,
  heading,
  children,
  className,
  ctaTrackingLabel
}) => (
  <Dropdown
    heading={<Heading text={heading} isOpen={isOpen} />}
    isOpen={isOpen}
    onToggleOpen={onToggleOpen}
    className={className}
    activeMouseHoverIteraction={activeMouseHoverIteraction}
  >
    <div className="flex flex-col flex-grow overflow-hidden">
      <div
        className={clsx(
          'flex-1 flex-grow overflow-y-auto text-dark-green',
          'dropdown-select-options'
        )}
      >
        {children}
      </div>
      <Button
        color="sage"
        variant="filled"
        size="large"
        onClick={() => onToggleOpen()}
        className="w-full mt-sm mb-sm"
        trackingLabel={ctaTrackingLabel}
      >
        Apply
      </Button>
    </div>
  </Dropdown>
);
