import React, { FC } from 'react';
import clsx from 'clsx';
import { Text } from '../../atoms/text/text';
import { TTextColor, TTextVariants } from '../../types';
import styles from './continued-text-list.module.css';

export interface ContinuedTextListProps
  extends React.HTMLProps<HTMLParagraphElement> {
  items: string[];
  centered?: boolean;
  color?: TTextColor;
  variant?: TTextVariants;
  title?: string;
}

/**
 * Joins an array of strings with a slash (/) and lets you pass variant props
 * @param items Array of strings
 * @param color A color value from our style guide
 * @param variant A variant for the Text component
 */
export const ContinuedTextList: FC<ContinuedTextListProps> = ({
  className,
  color = 'dark-green',
  items = [],
  variant = 'h8b',
  title,
  centered = false
}) => {
  const text = items.join(' / ');
  return (
    <div
      className={clsx(className, {
        'text-center': centered,
        [styles.centered]: centered
      })}
    >
      {title && (
        <div className={clsx('mb-md')}>
          <Text variant="u2">{title}</Text>
        </div>
      )}
      <Text variant={variant} color={color}>
        {text}
      </Text>
    </div>
  );
};
