import React from 'react';
import styles from './hamburger.module.css';
import clsx from 'clsx';

interface HamburgerProps {
  expanded: boolean;
  onClick: () => void;
}

export const Hamburger: React.FC<HamburgerProps> = ({ expanded, onClick }) => {
  return (
    <button
      className={styles.container}
      onClick={() => onClick()}
      aria-label="Navigation Menu"
      aria-expanded={expanded ? 'true' : 'false'}
    >
      <div
        className={clsx(
          styles.innerContainer,
          expanded && styles.innerContainerExpanded
        )}
      >
        <div className={styles.line} />
        <div className={styles.line} />
        <div className={styles.line} />
      </div>
    </button>
  );
};
