import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export const schema = yup.object().shape({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('This field is required'),
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  phone: yup
    .string()
    .test(
      'phone-validation-format',
      'The phone number should be 10 digits and include the + sign',
      value => isPossiblePhoneNumber(value)
    )
    .test(
      'phone-validation-area-code',
      'The phone number should include a valid area code',
      value => isPossiblePhoneNumber(value)
    )
});
