import React, { FC } from 'react';
import clsx from 'clsx';
import { useCarouselStep } from '../../../hooks/use-carousel-step';
import useResponsiveProps from '../../../hooks/use-responsive-props';
import { useIsMobileBreakpoint } from '../../../hooks/use-is-mobile-breakpoint';
import { DataListItem } from '../../molecules/collapsible-data-list/collapsible-data-list';
import {
  TextLockup,
  Text,
  CollapsibleDataList,
  DataVisualization,
  Row,
  Column,
  Statistic
} from '../..';

import styles from './data-list-and-chart.module.css';
import { ImageType } from '../../atoms/image/types';
import { SanityChart } from 'graphql-types';
export interface DataList extends DataListItem {
  chart: SanityChart;
}

interface DataListAndChartProps {
  eyebrow: string;
  headline: string;
  items: DataList[];
}

export const DataListAndChart: FC<DataListAndChartProps> = ({
  eyebrow,
  headline,
  items
}) => {
  const isMobile = useIsMobileBreakpoint();
  const { getPropV2 } = useResponsiveProps();
  const { currentStep, goToStep } = useCarouselStep(items.length, 0);
  const cols = [22, 10];
  const desktopOffset = [1, 2];
  const mobileOffset = 1;
  const { chart } = items[currentStep];

  return (
    <Row
      cols={cols}
      gridLength={24}
      offset={1}
      className={clsx(styles.flex, getPropV2(['xxl', 'xl'], 'py'))}
    >
      <Column
        offset={isMobile ? mobileOffset : desktopOffset}
        cols={isMobile ? 23 : 9}
      >
        <TextLockup alignment={isMobile ? 'center' : 'left'}>
          <Text
            variant="p3b"
            className={clsx(isMobile && 'pb-xs', styles.eyebrow)}
          >
            {eyebrow}
          </Text>
          <Text variant="h2a" className={clsx(styles.heading)}>
            {headline}
          </Text>
          <div className={clsx(styles.carousel)}>
            <CollapsibleDataList
              items={items as DataListItem[]}
              activeItem={currentStep}
              onItemClick={goToStep}
            />
          </div>
        </TextLockup>
      </Column>
      <Column>
        {/^\d+$/.test(chart.value.replace(/[%<>]/g, '')) ? (
          <DataVisualization
            type="pie"
            data={{ ...chart }}
            description={chart.description}
          />
        ) : (
          <div className={styles.statisticsFluid}>
            <div style={{ width: '500px', height: '500px' }}>
              <Statistic
                color="dark-green"
                className="bg-gradient-green"
                type={ImageType.RECTANGLE_XL}
                data={{
                  value: chart.value,
                  description: chart.description
                }}
              />
            </div>
          </div>
        )}
      </Column>
    </Row>
  );
};
