import React from 'react';
import MainNav from '../../redesign/molecules/nav/main-nav';
import { Button } from '../../redesign/atoms/button';
import LogoNav from './insights-nav-logo';
import './insights-nav.css';

const InsightsMainNav: React.FC = () => {
  return (
    <MainNav>
      <LogoNav />
      <Button
        color="darkGreen"
        variant="filled"
        size="small"
        trackingLabel="insights sign in button"
        destination="https://insights.parsleyhealth.com/login"
      >
        Sign in
      </Button>
    </MainNav>
  );
};

export default InsightsMainNav;
