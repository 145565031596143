import React, { FC, useEffect, useRef } from 'react';
import { animate } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

/**
 * Props for component
 * from: number where the counter is gonna start
 * to: limit number
 */
export interface AnimatedCounterProps {
  from: number;
  to: number;
}

/**
 * Component will update content from span node based on the from the initial value to the final one
 */

export const AnimatedCounter: FC<AnimatedCounterProps> = ({ from = 0, to }) => {
  const nodeRef = useRef(undefined);
  const [ref, inView] = useInView();
  useEffect(() => {
    const node = nodeRef.current;
    // type isn't exposed so it has to be set as any
    let controls: any;
    if (inView) {
      controls = animate(from, to, {
        duration: 1,
        onUpdate(value) {
          node.textContent = value.toFixed();
        }
      });
    }

    return () => {
      if (controls) {
        controls.stop();
      }
    };
  }, [from, inView, to]);

  return (
    <span ref={ref}>
      <span ref={nodeRef} />
    </span>
  );
};
