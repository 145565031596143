//@ts-nocheck
import React, { useState } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import useCreateSearchClient from '../../../../src/lib/use-create-search-client';
import BlogSearchBar from './blog-search-bar';
import BlogSearchResult from './blog-search-result';
import Pagination from '../pagination';
import CloseIcon from '../../../assets/icon-close.svg';
import { useCurrentPath } from '../../../hooks/use-current-path';
import './search.css';

const BlogSearch: React.FC<{
  isSearch: boolean;
  onToggleIsSearch: () => void;
}> = ({ isSearch, onToggleIsSearch }) => {
  const [query, setQuery] = useState('');
  const searchClient = useCreateSearchClient();
  const hiltonPath = 'hilton/education';
  const currentPath = useCurrentPath();
  const isHiltonPath = currentPath.includes(hiltonPath);
  const algoliaIndex = isHiltonPath ? 'HiltonEducation' : 'Blog';

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={algoliaIndex}
      onSearchStateChange={({ query }: { query: string }) => setQuery(query)}
    >
      <div
        className={`container-blog-search ${
          isSearch
            ? 'search-is-visible search-fade-in'
            : 'search-is-hidden search-fade-out '
        }`}
      >
        <BlogSearchBar>
          <button
            className="button-close-search"
            onClick={onToggleIsSearch}
            type="button"
          >
            <CloseIcon />
          </button>
        </BlogSearchBar>
        {query && query.length > 0 && isSearch && (
          <div className="container-search-blog-content">
            <BlogSearchResult index={algoliaIndex} />
            <Pagination />
          </div>
        )}
      </div>
    </InstantSearch>
  );
};

export default BlogSearch;
