import { useEffect, useState } from 'react';

interface HoverHook {
  isHover: boolean;
}
interface IsHoverProps {
  element: React.MutableRefObject<HTMLElement>;
}

export const useIsHover = ({ element }: IsHoverProps): HoverHook => {
  const [isHover, setHover] = useState(false);

  useEffect(() => {
    if (element.current) {
      element.current.onmouseenter = () => setHover(true);
      element.current.onmouseleave = () => setHover(false);
    }
  }, [element]);

  return {
    isHover
  };
};
