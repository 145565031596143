import React, { FC } from 'react';
import clsx from 'clsx';
import { Row, Column } from '../../atoms/grid';
import { Image } from '../../atoms/image/image';
import { ImageType } from '../../atoms/image/types';
import {
  GridOrientation,
  TTextColor,
  ImageProps,
  OffsetUnit
} from '../../types';
import { useAnimateInView } from '../../../hooks/use-animate-inview';
import { useImageContentOffset } from '../../../hooks/use-half-and-half-image-and-content-offset';
import styles from './half-and-half.module.css';

export type ContentPosition = 'center' | 'top';
export interface HalfAndHalfProps {
  imgBgColor?: TTextColor;
  bgColor?: TTextColor;
  image?: ImageProps;
  imagePosition?: 'left' | 'right';
  fullImage?: boolean;
  mobileHidden?: boolean;
  inset?: boolean;
  reversed?: GridOrientation;
  contentPosition?: ContentPosition;
  paddingY?: boolean;
  className?: string;
  animated?: boolean;
  reanimate?: boolean;
  contentClassNames?: string;
  imageOffsetValue?: OffsetUnit;
  contentOffsetValue?: OffsetUnit;
  children?: any;
}

export const HalfAndHalf: FC<HalfAndHalfProps> = ({
  children,
  className,
  image,
  imagePosition,
  fullImage,
  imgBgColor = '',
  bgColor = '',
  mobileHidden = false,
  inset,
  reversed,
  contentPosition = 'center',
  paddingY = true,
  contentClassNames = '',
  imageOffsetValue,
  contentOffsetValue,
  animated = false,
  reanimate = false
}) => {
  const { ref, animated: isInView } = useAnimateInView(reanimate);

  const isReversed =
    reversed !== undefined ? reversed : imagePosition === 'right';
  const contentPositionClass =
    contentPosition === 'center' ? 'flex-center' : 'justify-center';

  const cols = inset ? [22, 11] : [22, 9];
  const [imageOffset, contentOffset] = useImageContentOffset({
    imageOffsetValue,
    contentOffsetValue,
    inset,
    reversed
  });

  return (
    <Row
      cols={cols}
      gridLength={24}
      reversed={isReversed}
      className={clsx(
        paddingY && styles.spacing,
        styles.container,
        styles[imagePosition],
        inset && styles.round,
        className
      )}
    >
      <Column
        offset={imageOffset}
        className={clsx(mobileHidden && styles.mobileHidden, {
          'pt-xl pb-xl': imgBgColor,
          [`bg-${imgBgColor || bgColor}`]: imgBgColor || bgColor
        })}
      >
        <div
          ref={ref}
          className={clsx(
            {
              'w-full h-full': fullImage,
              'self-align w-pct-17/20': !fullImage
            },
            styles.imageWrapper
          )}
        >
          <Image
            {...image}
            type={image?.type ?? ImageType.SQUARE}
            className={animated && isInView && 'fade-in-image'}
          />
        </div>
      </Column>
      <Column
        offset={contentOffset}
        className={clsx({
          [`bg-${bgColor}`]: bgColor,
          'pt-sm pb-sm': imgBgColor
        })}
      >
        <div
          className={clsx(
            'h-full pt-sm pb-sm',
            contentClassNames,
            contentPositionClass
          )}
        >
          {children}
        </div>
      </Column>
    </Row>
  );
};
