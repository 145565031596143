import React from 'react';
import clsx from 'clsx';
import { ContinuedTextList, Text } from '../..';
import { ContinuedTextListProps } from '../../types';
import styles from './continued-text-list-module.module.css';
import useResponsiveProps from '../../../hooks/use-responsive-props';

interface ContinuedTextListModuleProps {
  heading?: string;
  items: ContinuedTextListProps['items'];
}

export const ContinuedTextListModule: React.FC<ContinuedTextListModuleProps> = ({
  heading,
  items
}) => {
  const { getPropV2 } = useResponsiveProps();
  return (
    <div
      className={`flex flex-col align-center ${getPropV2(['xl', 'xxl'], 'py')}`}
    >
      {heading && (
        <Text variant="u2" className={clsx('mb-md m-auto', 'text-center')}>
          {heading}
        </Text>
      )}
      <ContinuedTextList
        variant="h6b"
        className={clsx('mb-md', 'w-pct-2/3', 'text-center', styles.container)}
        items={items}
        centered
      />
    </div>
  );
};
