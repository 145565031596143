import { ImageType } from 'src/redesign/types';
import { SanityBasicImage } from '../../graphql-types';

export const useSanityImage = (image: SanityBasicImage) => {
  return image
    ? {
        ...image,
        type: image?.shape as ImageType
      }
    : null;
};
