import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const SearchIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 19 18" fill="none" {...props}>
      <path
        d="M17.979 17 14.2 13.216 17.978 17Zm-1.685-8.842a7.158 7.158 0 1 1-14.315 0 7.158 7.158 0 0 1 14.315 0v0Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </IconSvg>
  );
};
