import { TTextVariants } from 'src/redesign/types';
import { SanityTextVariant } from '../../graphql-types';

export const useSanityTextVariant = (
  textVariant: SanityTextVariant,
  defaultVariant?: TTextVariants
) => {
  return textVariant && textVariant.isEnabled
    ? {
        content: textVariant.text,
        variant: (textVariant.variantStyle as TTextVariants) ?? defaultVariant
      }
    : null;
};
