import React from 'react';

import { IconProps } from '../../../types/index';
import { IconSvg } from '../icon-svg/IconSvg';

export const WellGoodIcon: React.FC<IconProps> = ({ ...props }) => {
  return (
    <IconSvg viewBox="0 0 100 8" fill="none" {...props}>
      <g clipPath="url(#clip0_4576:280)">
        <path
          d="M16.1144 6.36868C16.1144 5.66304 16.1144 4.9862 16.1144 4.28415C17.1913 4.28415 18.2583 4.28415 19.3286 4.28415C19.3286 3.95294 19.3286 3.64332 19.3286 3.3121C18.2516 3.3121 17.1847 3.3121 16.1144 3.3121C16.1144 2.57045 16.1144 1.85401 16.1144 1.11956C17.3808 1.11956 18.6339 1.11956 19.8936 1.11956C19.8936 0.781145 19.8936 0.460725 19.8936 0.140306C18.3148 0.140306 16.7492 0.140306 15.187 0.140306C15.187 2.55245 15.187 4.95019 15.187 7.34434C16.7659 7.34434 18.3314 7.34434 19.8936 7.34434C19.8936 7.01312 19.8936 6.6999 19.8936 6.37228C18.6339 6.36868 17.3841 6.36868 16.1144 6.36868ZM53.8904 3.26169C52.9797 3.26169 52.0789 3.26169 51.1648 3.26169C51.1648 2.18883 51.1648 1.14117 51.1648 0.0827026C50.8524 0.0827026 50.5566 0.0827026 50.2441 0.0827026C50.2441 1.14837 50.2441 2.20323 50.2441 3.28329C49.3267 3.28329 48.4259 3.28329 47.5218 3.28329C47.5218 3.61811 47.5218 3.92773 47.5218 4.26255C47.5983 4.26255 47.6714 4.26255 47.7412 4.26255C48.5124 4.26255 49.2868 4.26615 50.058 4.25895C50.2042 4.25895 50.2508 4.30216 50.2508 4.46417C50.2441 5.37862 50.2474 6.29308 50.2474 7.20753C50.2474 7.27593 50.2541 7.34434 50.2574 7.41634C50.5665 7.41634 50.8557 7.41634 51.1715 7.41634C51.1715 6.36148 51.1715 5.31382 51.1715 4.24815C52.0922 4.24815 52.9863 4.24815 53.8904 4.24815C53.8904 3.91333 53.8904 3.60011 53.8904 3.26169ZM36.7923 0.133106C36.7923 2.54525 36.7923 4.94659 36.7923 7.34434C38.3014 7.34434 39.7938 7.34434 41.2929 7.34434C41.2929 7.01672 41.2929 6.6999 41.2929 6.36148C40.0963 6.36148 38.9096 6.36148 37.7031 6.36148C37.7031 4.27335 37.7031 2.20683 37.7031 0.133106C37.3939 0.133106 37.0981 0.133106 36.7923 0.133106ZM30.7196 6.35788C29.5196 6.35788 28.3264 6.35788 27.1165 6.35788C27.1165 4.26615 27.1165 2.20323 27.1165 0.136706C26.804 0.136706 26.5082 0.136706 26.2157 0.136706C26.2157 2.54885 26.2157 4.94659 26.2157 7.34434C27.7214 7.34434 29.2172 7.34434 30.7196 7.34434C30.7196 7.02032 30.7196 6.7035 30.7196 6.35788Z"
          fill="currentColor"
        />
        <path
          d="M0.000488281 0.114682C0.253104 0.118282 0.50572 0.136283 0.755012 0.114682C0.94115 0.100281 1.00763 0.179486 1.06081 0.363097C1.5328 2.0012 2.01477 3.63569 2.49673 5.27019C2.52 5.3458 2.54659 5.425 2.58315 5.54021C2.63966 5.3638 2.68619 5.22699 2.7294 5.08658C3.21469 3.49889 3.69666 1.91479 4.1753 0.327095C4.22516 0.165085 4.29163 0.0966809 4.45118 0.114682C4.58746 0.132683 4.72706 0.129083 4.86334 0.114682C4.99962 0.100281 5.04616 0.165085 5.08604 0.294693C5.59127 1.96879 6.10648 3.63929 6.61503 5.30979C6.63498 5.371 6.65492 5.4286 6.69148 5.54021C6.7779 5.25579 6.85103 5.01458 6.92083 4.77336C7.35959 3.28647 7.79834 1.79958 8.23045 0.312694C8.27366 0.165085 8.33016 0.103881 8.47974 0.114682C8.73568 0.129083 8.99494 0.118282 9.27747 0.118282C9.16114 0.514306 9.05145 0.892329 8.93843 1.27395C8.35675 3.23247 7.77175 5.19099 7.19671 7.1531C7.15018 7.31151 7.08703 7.37272 6.93745 7.35832C6.78788 7.34392 6.6383 7.34032 6.48873 7.35832C6.32253 7.37632 6.26603 7.30071 6.21949 7.1387C5.72423 5.497 5.219 3.85531 4.71709 2.21361C4.70047 2.1524 4.6772 2.0948 4.64064 1.99039C4.60408 2.1056 4.57749 2.17761 4.55422 2.25321C4.05564 3.88771 3.55705 5.52221 3.06179 7.1603C3.01526 7.31151 2.95875 7.36912 2.8125 7.35472C2.63966 7.34032 2.46349 7.34392 2.29065 7.35472C2.18096 7.36192 2.13775 7.31871 2.10451 7.20351C1.66576 5.72742 1.227 4.25133 0.788251 2.77164C0.542283 1.94719 0.296315 1.11914 0.0470228 0.294693C0.0370511 0.258691 0.0171077 0.226289 0.000488281 0.193887C0.000488281 0.165085 0.000488281 0.139884 0.000488281 0.114682Z"
          fill="currentColor"
        />
        <path
          d="M63.4456 -0.00012207C63.6018 0.0394803 63.758 0.0718823 63.9109 0.118685C64.7818 0.3671 65.4565 0.910733 65.9717 1.72798C65.7225 1.91519 65.4798 2.1024 65.2205 2.29682C65.1408 2.18881 65.0643 2.0844 64.9879 1.9836C64.3464 1.14835 63.2794 0.799126 62.3254 1.11594C61.3382 1.44356 60.6302 2.36882 60.5272 3.45609C60.4109 4.68376 61.0457 5.82503 62.1061 6.29306C63.1464 6.75388 64.3264 6.40826 65.0311 5.44701C65.1308 5.3102 65.194 5.16979 65.1807 4.98618C65.1674 4.78097 65.1773 4.57575 65.1773 4.34534C64.4128 4.34534 63.6683 4.34534 62.9104 4.34534C62.9104 4.02852 62.9104 3.7333 62.9104 3.42368C63.9575 3.42368 65.0012 3.42368 66.0582 3.42368C66.0582 4.72696 66.0582 6.02664 66.0582 7.34432C65.7856 7.34432 65.5097 7.34432 65.2106 7.34432C65.2106 7.12471 65.2106 6.89429 65.2106 6.63148C65.1441 6.67828 65.1042 6.69988 65.071 6.73228C64.6355 7.11031 64.1469 7.34792 63.5985 7.44513C63.5719 7.44873 63.5453 7.47033 63.5187 7.48113C63.2063 7.48113 62.8971 7.48113 62.5847 7.48113C62.2224 7.35512 61.8435 7.26871 61.5044 7.0923C60.1749 6.39746 59.3937 4.85657 59.6198 3.22567C59.8558 1.53717 61.0291 0.280695 62.5448 0.0430805C62.5847 0.0358801 62.6213 0.017879 62.6578 0.00347815C62.9171 -0.00012207 63.1797 -0.00012207 63.4456 -0.00012207Z"
          fill="currentColor"
        />
        <path
          d="M99.7506 4.35972C99.7174 4.51453 99.6808 4.66934 99.6476 4.82415C99.3185 6.35064 98.2449 7.3155 96.8023 7.3515C95.8916 7.3731 94.9808 7.3551 94.0734 7.3551C94.0502 7.3551 94.0269 7.3479 93.9937 7.3443C93.9937 4.93936 93.9937 2.53801 93.9937 0.118667C94.1299 0.118667 94.2562 0.118667 94.3792 0.118667C95.2401 0.125867 96.1043 0.0934654 96.9652 0.151069C98.3878 0.248275 99.4083 1.27434 99.6775 2.79363C99.6974 2.91243 99.7274 3.03124 99.7506 3.15005C99.7506 3.55327 99.7506 3.9565 99.7506 4.35972ZM94.9144 6.34704C95.5991 6.34704 96.2639 6.38304 96.9253 6.33624C97.7031 6.28224 98.2682 5.82861 98.5972 5.05816C98.8498 4.46413 98.903 3.83409 98.8066 3.19325C98.6039 1.84317 97.8128 1.11953 96.5564 1.11953C96.0777 1.11953 95.5991 1.11953 95.1171 1.11953C95.054 1.11953 94.9875 1.11953 94.9144 1.11953C94.9144 2.88003 94.9144 4.61534 94.9144 6.34704Z"
          fill="currentColor"
        />
        <path
          d="M74.5517 -0.00012207C74.7811 0.0646818 75.0138 0.111485 75.2398 0.190689C76.7787 0.737922 77.7294 2.31122 77.6064 4.10052C77.4934 5.74942 76.2802 7.15351 74.7246 7.43792C74.6913 7.44513 74.6581 7.46313 74.6249 7.47393C74.3124 7.47393 74.0033 7.47393 73.6909 7.47393C73.3784 7.37312 73.0527 7.30112 72.7535 7.16431C71.3209 6.49467 70.5032 4.89617 70.7126 3.21847C70.9154 1.58397 72.1353 0.269894 73.6643 0.0358801C73.6975 0.0322799 73.7274 0.0106786 73.7607 -0.00012207C74.0266 -0.00012207 74.2891 -0.00012207 74.5517 -0.00012207ZM76.689 3.7477C76.6923 2.24641 75.5423 0.997138 74.1529 0.993538C72.7668 0.989937 71.6101 2.23921 71.6068 3.7405C71.6068 5.24179 72.7535 6.48747 74.1462 6.49467C75.5323 6.50187 76.6857 5.25259 76.689 3.7477Z"
          fill="currentColor"
        />
        <path
          d="M85.1181 7.47393C84.7359 7.33712 84.3337 7.24351 83.9747 7.0527C82.6651 6.35786 81.9272 4.77376 82.1432 3.18967C82.3526 1.64518 83.4761 0.3671 84.8855 0.0754825C84.9885 0.0538812 85.0915 0.0250794 85.1946 -0.00012207C85.4572 -0.00012207 85.7231 -0.00012207 85.9857 -0.00012207C86.122 0.0358801 86.2582 0.068282 86.3978 0.104284C88.0033 0.52551 89.1035 2.0916 89.047 3.86651C88.9905 5.65582 87.7939 7.13191 86.1552 7.43432C86.122 7.44152 86.0887 7.45953 86.0588 7.47033C85.743 7.47393 85.4306 7.47393 85.1181 7.47393ZM88.1163 3.7549C88.1296 2.26081 86.9895 1.01514 85.5868 0.993538C84.2207 0.971936 83.044 2.22841 83.034 3.7117C83.0241 5.22379 84.1675 6.48027 85.5669 6.49467C86.9529 6.50547 88.1063 5.26699 88.1163 3.7549Z"
          fill="currentColor"
        />
        <path
          d="M16.1135 6.3682C17.3832 6.3682 18.633 6.3682 19.8961 6.3682C19.8961 6.69942 19.8961 7.01264 19.8961 7.34026C18.3305 7.34026 16.765 7.34026 15.1895 7.34026C15.1895 4.94612 15.1895 2.54838 15.1895 0.13623C16.755 0.13623 18.3172 0.13623 19.8961 0.13623C19.8961 0.45665 19.8961 0.777069 19.8961 1.11549C18.633 1.11549 17.3799 1.11549 16.1168 1.11549C16.1168 1.85353 16.1168 2.56998 16.1168 3.30802C17.1871 3.30802 18.2541 3.30802 19.331 3.30802C19.331 3.63924 19.331 3.94886 19.331 4.28008C18.2607 4.28008 17.1938 4.28008 16.1168 4.28008C16.1135 4.98572 16.1135 5.66256 16.1135 6.3682Z"
          fill="currentColor"
        />
        <path
          d="M53.89 3.26133C53.89 3.59975 53.89 3.91297 53.89 4.25139C52.9859 4.25139 52.0918 4.25139 51.1711 4.25139C51.1711 5.31705 51.1711 6.36471 51.1711 7.41958C50.8553 7.41958 50.5662 7.41958 50.257 7.41958C50.2537 7.34757 50.2471 7.27917 50.2471 7.21077C50.2471 6.29631 50.2437 5.38185 50.2504 4.4674C50.2504 4.30539 50.2072 4.25859 50.0576 4.26219C49.2865 4.26939 48.512 4.26579 47.7409 4.26579C47.6711 4.26579 47.6013 4.26579 47.5215 4.26579C47.5215 3.93097 47.5215 3.62135 47.5215 3.28653C48.4256 3.28653 49.323 3.28653 50.2437 3.28653C50.2437 2.20646 50.2437 1.1516 50.2437 0.0859375C50.5595 0.0859375 50.8553 0.0859375 51.1645 0.0859375C51.1645 1.1408 51.1645 2.19206 51.1645 3.26493C52.0818 3.26133 52.9793 3.26133 53.89 3.26133Z"
          fill="currentColor"
        />
        <path
          d="M36.7939 0.132996C37.0964 0.132996 37.3922 0.132996 37.708 0.132996C37.708 2.20672 37.708 4.27324 37.708 6.36137C38.9146 6.36137 40.1012 6.36137 41.2978 6.36137C41.2978 6.69979 41.2978 7.01661 41.2978 7.34423C39.7987 7.34423 38.303 7.34423 36.7973 7.34423C36.7939 4.94648 36.7939 2.54514 36.7939 0.132996Z"
          fill="currentColor"
        />
        <path
          d="M30.7212 6.3574C30.7212 6.70303 30.7212 7.01984 30.7212 7.34386C29.2188 7.34386 27.723 7.34386 26.2173 7.34386C26.2173 4.94612 26.2173 2.54838 26.2173 0.13623C26.5098 0.13623 26.8056 0.13623 27.1181 0.13623C27.1181 2.20275 27.1181 4.26568 27.1181 6.3574C28.328 6.3574 29.5212 6.3574 30.7212 6.3574Z"
          fill="currentColor"
        />
      </g>
    </IconSvg>
  );
};
