import React, { FC } from 'react';
import clsx from 'clsx';
import './social-links.scss';
import {
  InstagramIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon
} from '../../molecules/icons';

export interface SocialLinkProp {
  title: string;
  path: string;
}

export interface SocialLinksProps {
  socialLinks: SocialLinkProp[];
  className?: string;
}

export const getIcon = (title: string) => {
  switch (title.toLowerCase()) {
    case 'instagram':
      return <InstagramIcon color="white" iconWidth={36} />;
    case 'facebook':
      return <FacebookIcon color="white" iconWidth={36} />;
    case 'twitter':
      return <TwitterIcon color="white" iconWidth={36} />;
    case 'pinterest':
      return <PinterestIcon color="white" iconWidth={36} />;
    default:
      return null;
  }
};

export const SocialLinks: FC<SocialLinksProps> = ({
  className,
  socialLinks
}) => {
  return (
    <div className="flex social-links">
      {socialLinks?.map(socialLink => (
        <div
          className={clsx('social-links-icon-box flex-center', className)}
          key={socialLink.title}
        >
          <a href={socialLink.path}>{getIcon(socialLink.title)}</a>
        </div>
      ))}
    </div>
  );
};
