type BaseSanityNode = {
  id: any;
  children: any;
  internal: any;
};
export const baseSanityNode: BaseSanityNode = {
  id: null,
  children: null,
  internal: null
};
