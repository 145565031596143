import { useCallback } from 'react';
import { useHasMounted } from './use-has-mounted';

interface ScrollController {
  avoidScrolling: () => void;
  enableScrolling: () => void;
}

export const useScrollController = (): ScrollController => {
  const hasMounted = useHasMounted();

  const avoidScrolling = useCallback(() => {
    if (hasMounted) {
      document.body.classList.remove('noscroll');
      document.querySelector('html').classList.remove('noscroll');
    }
  }, [hasMounted]);

  const enableScrolling = useCallback(() => {
    if (hasMounted) {
      document.body.classList.add('noscroll');
      document.querySelector('html').classList.add('noscroll');
    }
  }, [hasMounted]);

  return { avoidScrolling, enableScrolling };
};
