import React from 'react';

export const WatchPath: React.FC = () => {
  return (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5092 50.8846C21.0779 50.4948 12.9376 41.7226 13.3274 31.2913C13.7172 20.86 22.4895 12.7197 32.9208 13.1095C43.3521 13.4993 51.4923 22.2715 51.1025 32.7028C50.7127 43.1341 41.9405 51.2744 31.5092 50.8846ZM12.7653 31.2703C12.3639 42.012 20.7464 51.0454 31.4882 51.4468C42.23 51.8481 51.2633 43.4656 51.6647 32.7238C52.0661 21.9821 43.6835 12.9487 32.9418 12.5473C22.2 12.146 13.1667 20.5285 12.7653 31.2703ZM28.7495 25.7934L28.3252 25.5426V26.0355V37.9635V38.4565L28.7495 38.2057L38.8415 32.2417L39.2513 31.9995L38.8415 31.7574L28.7495 25.7934ZM38.1456 31.9995L28.8877 37.4706V26.5285L38.1456 31.9995Z"
      fill="currentColor"
    />
  );
};
