import React, { FC } from 'react';
import { DesktopNav } from './desktop-nav/desktop-nav';
import { MobileNav } from './mobile-nav/mobile-nav';
import { LayoutProps } from '../../types';

export const Navigation: FC<Pick<
  LayoutProps,
  'theme' | 'hideQuizCtaButton'
>> = ({ theme = 'sherbed', hideQuizCtaButton }) => {
  return (
    <>
      <DesktopNav theme={theme} hideQuizCtaButton={hideQuizCtaButton} />
      <MobileNav theme={theme} hideQuizCtaButton={hideQuizCtaButton} />
    </>
  );
};
