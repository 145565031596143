import { useLayoutEffect, useState } from 'react';

interface Position {
  x: number;
  y: number;
}

const isBrowser = typeof window !== `undefined`;
const zeroPosition = { x: 0, y: 0 };

export const useScrollPosition = (): Position => {
  const [currentPosition, setCurrentPosition] = useState<Position>(
    zeroPosition
  );

  useLayoutEffect(() => {
    if (!isBrowser) {
      return undefined;
    }

    const handleScroll = () => {
      const currPos = { x: window.scrollX, y: window.scrollY };
      setCurrentPosition(currPos);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return currentPosition;
};
